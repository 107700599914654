import React, { useEffect, useContext, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ACTIONS } from '@rewards/contexts';
import { useUserInfo } from '@rewards/data-provider';

import { useKnives } from '../../util/hooks';
import { KnivesContext } from '../../contexts/KnivesContext';
import { getAddressListPath, getAddressFormPath } from '../../util/paths';
import { KNIFE_INFO } from '../../util/constants';

import './UserAddress.scss';

const UserAddress = () => {
  const { getShippingAddresses, getKnifeInfo } = useContext(KnivesContext);
  const { data: userInfo } = useUserInfo();
  const navigate = useNavigate();
  const { addresses, knifeInfo } = useKnives();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    UserAddress.showBackBtn = true;
  }, []);
  useEffect(() => {
    if (userInfo?.data) {
      getShippingAddresses(userInfo?.data?._id).then(() => {
        setIsLoading(false);
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (!knifeInfo) {
      getKnifeInfo(localStorage.getItem(KNIFE_INFO));
    }
  }, [knifeInfo]);

  useEffect(() => {
    if (addresses?.status === ACTIONS.STATUS.RESOLVED && !isLoading) {
      if (addresses?.data?.length) {
        navigate(getAddressListPath());
      } else {
        navigate(getAddressFormPath());
      }
    }
  }, [addresses, isLoading]);

  if (isLoading) {
    return (
      <div className="text-center spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return null;
};

export default UserAddress;
