import React, { useContext, useState } from 'react';
import { Col, Row, Nav } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '@rewards/contexts';

import Icon from '../../assets/svg';
import { ConfirmationShareModal } from '@rewards/daily-surprises';

import { EVENTS, EVENT_LOCATION, PAGES } from '../../analytics/events';

const { AppContext: AppContexts } = AppContext;
import { getRedirectingPath } from '../../util/paths';
import UserProfileContext from '../../contexts/UserProfileContext';

import './charitable.scss';
import '../../styes/donationSuccess.css';
import './DailySurprisesConfirmation.scss';
import { useResponsive } from '../../util/customHooks';

function GetTitle(props) {
  return (
    <div className="text-left">
      <Icon
        icon={props.faIcon}
        width={props?.width || '17'}
        height={props.height || '17'}
      />
      <span className="ml-3 nav-item-text">{props.title}</span>
      <span className="float-right">
        <Icon
          icon="angle-right"
          width="7"
          height="13"
          className="float-right"
        />
      </span>
    </div>
  );
}

const ConfirmationDetail = ({ isDesktop, points, value }) => {
  const profile = useContext(UserProfileContext);

  return <></>;
};

const ConfirmationBody = ({ isDesktop, value, charityName }) => {
  const navigate = useNavigate();
  const { track } = useContext(AppContexts);

  const handleSelectLink = (navItem = '') => {
    track(`${PAGES.CHARITY_DETAILS} ${navItem} Clicked`, {
      location: EVENT_LOCATION.DONATE_CONFIRM_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    navigate(getRedirectingPath(navItem));
  };

  return (
    <>
      <div>
        <div className="p-0 w-100 text-left confirmation-title pt-2 pb-2 mx-auto">
          👋 <span className="text-bold">Prize Awarded!</span>
        </div>
        <div className="confirm-details-text text-center p-0 w-100 pt-3 pb-3">
          You won {'$'}
          {value} to be donated on your behalf.
        </div>
        <Row
          className={`justify-content-between text-white font-weight-bold green py-2 points-used w-100 ${
            isDesktop ? 'mx-auto' : ' mx-0'
          }`}
        >
          <Col className="align-self-start text-left col-7">Donated:</Col>
          <Col className="text-right flex align-items-center justify-content-end">
            {/* <Icon icon="fuel" width="12" height="16" /> */}
            {'$'}
            {value}
          </Col>
        </Row>
        <div
          className={`msg-box text-center py-4 px-3 ${
            isDesktop ? 'w-75 mx-auto' : 'w-100'
          }`}
        >
          🙏 This will go to <br />
          <span className="charity-name-bold">{charityName}</span>
          <br /> one of the many charities <br />
          Price Chopper / Market 32 Supports.
        </div>

        <Nav
          className={`flex flex-column ${isDesktop && 'w-75 mx-auto'}`}
          onSelect={handleSelectLink}
        >
          <img
            src="https://cdn.spinwheel.io/images/pricechopper/World_Central_Kitchen_Logo.png"
            className="rounded-top border-bottom-0 charity-logo mr-2 ml-3 mt-2"
          ></img>
        </Nav>
      </div>
    </>
  );
};

const ConfirmationFooter = ({
  isDesktop,
  showSocialShareCall,
  handleClose,
}) => (
  <>
    <Row className="mt-2 mb-1 w-100 mx-auto flex flex-row row">
      <div className="text-center ml-auto mr-4">
        <Button
          onClick={handleClose}
          block
          // className={`community-home-button mt-1  mx-auto-lg btn-green-lg`}
        >
          HOME
        </Button>
      </div>

      <div className="text-center mr-auto">
        <Button
          onClick={showSocialShareCall}
          block
          // className={`community-share-button mt-1  mx-auto-lg btn-green-lg`}
        >
          SHARE
        </Button>
      </div>
    </Row>
  </>
);

const DailySurpriseConfirmation = ({
  show,
  handleClose,
  points,
  value,
  charityName,
}) => {
  const { track } = useContext(AppContexts);
  const { isDesktop } = useResponsive();
  const [showSocialShare, setShowSocialShare] = useState(false);

  const showSocialShareCall = () => {
    track(EVENTS.DAILY_SURPRISES_SHARE_BUTTON_CLICKED, {
      location: EVENT_LOCATION.DONATE_DAILY_SURPRISE_CONFIRM_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    setShowSocialShare(true);
  };

  return (
    <ConfirmationShareModal
      show={show}
      isDesktop={isDesktop}
      handleClose={handleClose}
      detail={
        <ConfirmationDetail
          isDesktop={isDesktop}
          points={points}
          value={value}
        />
      }
      body={
        <ConfirmationBody
          isDesktop={isDesktop}
          points={points}
          value={value}
          charityName={charityName}
        />
      }
      footer={
        <ConfirmationFooter
          isDesktop={isDesktop}
          handleClose={handleClose}
          showSocialShareCall={showSocialShareCall}
        />
      }
      showSocialShare={showSocialShare}
    />
  );
};

export default DailySurpriseConfirmation;
