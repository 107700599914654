import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import App from './App';
import SurveyEnd from './components/Survey/SurveyEnd';
import 'bootstrap/dist/css/bootstrap.css';
// import "bootstrap-icons/font/bootstrap-icons.css";
import { Provider } from 'jotai';
import { store } from './store';
//@ts-ignore
if (window.analytics) {
  //@ts-ignore
  window.analytics.load(import.meta.env.REACT_APP_SEGMENT_WRITE_KEY);
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>
);
