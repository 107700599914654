import { Button } from '../../../../ui/src';
import { Modal } from '@rewards/ui';
import { Trigger } from '@radix-ui/react-dialog';

export const TermsAndCondition = ({ className }) => {
  return (
    <Modal.Root>
      <Trigger asChild>
        <Button
          intent={'link'}
          size={'wrapper'}
          style={{
            fontSize: '0.5rem',
          }}
          className={className}
        >
          Terms and Conditions
        </Button>
      </Trigger>
      <Modal.Content
        style={{
          maxWidth: '800px',
        }}
        className="max-h-[90%]"
      >
        <Modal.CloseButton color="white" />
        <Modal.Title className="text-h2  mb-4 ">
          AdvantEdge Rewards Points Multiplier - Terms and Conditions
        </Modal.Title>
        <section className="text-left overflow-auto">
          <p>
            Registration for the AdvantEdge Rewards points multiplier promotion
            begins on October 2, 2024 at{' '}
            <a href="https://rewards.pricechopper.com">
              https://rewards.pricechopper.com
            </a>
            . Upon activation of the multiplier for your AdvantEdge Card when
            logged into your online account, your personalized points multiplier
            will be revealed on screen. If you do not have an online account,
            you can create one for your AdvantEdge Card at{' '}
            <a
              href="www.pricechopper.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.pricechopper.com
            </a>
            . Beginning on October 6, 2024, dollars spent<sup>1</sup> using an
            activated AdvantEdge Card will earn AdvantEdge Rewards points at the
            rate of your points multiplier<sup>2</sup> during the promotion
            period.
          </p>
          <p>
            All AdvantEdge cardholders are eligible to earn at least 2x their
            regular AdvantEdge Rewards points. Check your printed receipt or
            online at{' '}
            <a href="https://rewards.pricechopper.com">
              https://rewards.pricechopper.com
            </a>
            &nbsp;for your promotion information. Promotion ends December 24,
            2024.
          </p>
          <p>
            AdvantEdge Card and pre-registration for the points multiplier
            promotion are required. If you do not have an AdvantEdge Card, you
            can apply free of charge at Guest Services at any Price Chopper,
            Market 32 or Market Bistro location or online at{' '}
            <a
              href="www.pricechopper.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.pricechopper.com
            </a>
            .
          </p>
          <br />
          <p className="mt-8">
            <sup>1</sup>After the deduction of AdvantEdge savings and coupons.
            <strong>
              {' '}
              Excludes alcoholic beverages, tobacco products, gift cards,
              prescriptions, stamps, lottery tickets, money orders, cash back,
              tickets, tax, bottle deposits and all telecom products and
              services.
            </strong>{' '}
            Other exclusions may apply. Accumulated dollars spent and AdvantEdge
            Rewards points earned are non-transferable.{' '}
          </p>
          <p className="mt-4">
            <sup>2</sup> Points multiplier includes savings earned through the
            regular AdvantEdge Rewards promotion offer of one point per
            qualifying dollar spent and is subject to AdvantEdge Rewards
            promotion terms and conditions, available at{' '}
            <a href="https://www.pricechopper.com/rewards">
              https://www.pricechopper.com/rewards
            </a>
            . You can earn AdvantEdge Rewards points at any Price Chopper,
            Market 32 or Market Bistro location in New York, Connecticut,
            Vermont, Massachusetts, New Hampshire and Pennsylvania.
          </p>
        </section>
        <section className="flex flex justify-content-end justify-end">
          <Modal.CloseButtonWrapper asChild>
            <Button>Close</Button>
          </Modal.CloseButtonWrapper>
        </section>
      </Modal.Content>
    </Modal.Root>
  );
};
