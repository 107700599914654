import { Title, Description } from '@radix-ui/react-dialog';

import { LoginButton, Button } from '@rewards/ui';
import MultiplierCircle from '../MultiplierCircle';

const BonusPointsPopupContent = ({
  isAuthenticated,
  activateMutationTrigger,
  onLogin,
}) => {
  return (
    <>
      <Title className={`text-h2 mt-2 pt-1 text-white text-center font-bold`}>
        Multiply your Points!
      </Title>
      <div className="flex my-6 justify-center ">
        <MultiplierCircle className={'font-medium translate-y-[10%]'}>
          2X
        </MultiplierCircle>
        <MultiplierCircle className={'font-medium -translate-y-[10%] '}>
          3X
        </MultiplierCircle>
        <MultiplierCircle className={'font-medium translate-y-[10%]'}>
          4X
        </MultiplierCircle>
      </div>
      <Description className={`text-h5 text-white text-center mb-8`}>
        Earn even more when you activate your bonus points this holiday!
      </Description>

      {isAuthenticated ? (
        <Button
          className={`!py-1 !px-4 md:!px-6 text-small sm:text-base font-normal md:font-medium `}
          onClick={activateMutationTrigger}
        >
          Activate
        </Button>
      ) : (
        <LoginButton onClick={onLogin} className={'!py-1 !px-4 md:!px-6'} />
      )}
    </>
  );
};

export default BonusPointsPopupContent;
