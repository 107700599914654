import React, { useState, useEffect } from 'react';
import { Route, Navigate, BrowserRouter, Routes } from 'react-router-dom';

import { UtilityOffersContextState } from './contexts/UtilityOffersContext';
import { FEATURES_FLAGS } from './util/constants';
import ComingSoon from './components/ComingSoon';
import LandingPage from './components/LandingPage';
import { AuthContext } from '@rewards/contexts';

// import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import './styles.css';
const { useAuth } = AuthContext;

const App = () => {
  const [activateCommingSoonPage, setActivateComingSoonPage] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { featureFlags } = useAuth();
  const [isMAActive, setActiveMALink] = useState(false);
  const [isNYActive, setActiveNYLink] = useState(false);
  useEffect(() => {
    if (
      featureFlags.find(
        (ff) =>
          ff.name === FEATURES_FLAGS.ENABLE_UTILITY_OFFERS &&
          ff.enable_comming_soon
      )
    ) {
      setActivateComingSoonPage(true);
    }

    if (
      featureFlags.find(
        (ff) =>
          ff.name === FEATURES_FLAGS.ENABLE_COMMUNITY_SOLAR_MA && ff.isActive
      )
    ) {
      setActiveMALink(true);
    }

    if (
      featureFlags.find(
        (ff) =>
          ff.name === FEATURES_FLAGS.ENABLE_COMMUNITY_SOLAR_NY && ff.isActive
      )
    ) {
      setActiveNYLink(true);
    }

    setLoaded(true);
  }, [featureFlags]);

  return (
    <UtilityOffersContextState>
      {loaded && (
        <>
          {activateCommingSoonPage ? (
            <ComingSoon />
          ) : (
            <LandingPage isMAActive={isMAActive} isNYActive={isNYActive} />
          )}
        </>
      )}
    </UtilityOffersContextState>
  );
};

export default App;
