import React, { useContext, useEffect } from 'react';
import { Modal, Col } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '@rewards/contexts';
import { queryClient } from '@rewards/data-provider';

import { KnivesContext } from '../../../../contexts/KnivesContext';
import { useResponsive, useKnives } from '../../../../util/hooks';
import { getLandingPath } from '../../../../util/paths';
import { EVENTS, EVENT_LOCATION, PAGES } from '../../../../analytics/events';

import './KnifeSuccessModal.scss';
const KnifeSuccessModal = ({ show, handleSuccessModal }) => {
  const { source, track } = useContext(AppContext.AppContext);
  const { getKnives } = useContext(KnivesContext);
  const { isDesktop } = useResponsive();
  const { knifeInfo } = useKnives();
  const navigate = useNavigate();

  const knifeTxns = queryClient.getQueryData('moneyTransferTxns');

  useEffect(() => {
    if (show) {
      source(PAGES.VIEWED_KNIFE_SUCCESS_MODAL, {
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [show]);

  const handleDone = () => {
    track(EVENTS.KNIFE_SUCCESS_DONE_CLICKED, {
      location: EVENT_LOCATION.KNIFE_SUCCESS_MODAL,
      confirmation_number: knifeTxns?.txnDetails?.confirmationNumber,
      ui_version: import.meta.env.APP_VERSION,
    });
    if (knifeInfo?.numberOfProductsAvailable === 1) {
      getKnives();
    }
    handleSuccessModal();
    navigate(getLandingPath());
  };
  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      centered={true}
      className="ms-modal"
      dialogClassName={`rounded' ${
        isDesktop ? '' : 'ms-mobile-dialog mx-auto'
      }`}
      contentClassName="text-center"
    >
      <Modal.Header className="border-0 pt-5">
        <Col className="lc-knife-success-header">Success!</Col>
      </Modal.Header>
      <Modal.Body className="border-0 pt-5">
        <Col className="lc-knife-success-confirm-info">
          Here’s your confirmation number.
        </Col>
        <Col className="lc-knife-success-confirm-num">
          {knifeTxns?.txnDetails?.confirmationNumber}
        </Col>
        <Col className="lc-knife-success-confirm-info my-2">
          This number is also available to you on the Ship to Home page.
        </Col>
        <Col className="lc-knife-success-confirm-info">
          You can expect to receive your knife within 5-7 business days.
        </Col>
      </Modal.Body>
      <Modal.Footer className="border-0 bg-white justify-content-center">
        <Button
          // className='lc-knife-success-btn w-50 ml-1 py-3 rounded border-0'
          onClick={handleDone}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default KnifeSuccessModal;
