import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Nav, Card, Spinner } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { AppContext, AuthContext, ACTIONS } from '@rewards/contexts';
import { useResponsive } from '../../util/customHooks';
import CommunityRootContext from '../../contexts/CommunityRootContext';
import { getCharityPath } from '../../util/paths';
import { EVENT_LOCATION, EVENTS, PAGES } from '../../analytics/events';

import BackNav from '../BackNav';

import './CharityList.scss';

function CharityList() {
  const { track, landingPath, source } = useContext(AppContext.AppContext);
  const { unAuthToken } = AuthContext.useAuth();
  const { charities } = useContext(CommunityRootContext);
  // const { handleShowUnAuthenticatedUserModal } = useContext(AuthContext.AuthContext)
  const { instance } = useMsal();
  const { isDesktop } = useResponsive();

  const queryString = new URL(document.location).searchParams;
  const reservationId = queryString.get('reservationId');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (reservationId && charities) {
      const [charityId] = Object.entries(charities).find(
        ([_, charity]) => charity.name === 'World Central Kitchen'
      );

      navigate(getCharityPath(charityId) + `?reservationId=${reservationId}`);
    }
  }, [reservationId, charities]);

  useEffect(() => {
    source(PAGES.CHARITY_LIST, { ui_version: import.meta.env.APP_VERSION });
    CharityList.EVENT_LOCATION = EVENT_LOCATION.CHARITY_LIST_PAGE;
    CharityList.landingPath = landingPath;
    CharityList.hideTopNav = true;
  }, []);

  const handleLoginRegister = () => {
    setIsLoading(true);
    track(EVENTS.LOG_IN_REGISTER_CLICKED, {
      location: EVENT_LOCATION.CHARITY_LIST_PAGE,
      ui_version: import.meta.env.APP_VERSION,
    });

    instance.config.auth.authority = instance.config.auth.loginAuthority;
    instance.loginRedirect({
      ...instance.config.auth.loginRequest,
      extraQueryParameters: {
        anonymousId: window?.analytics?.user?.()?.anonymousId?.(),
      },
    });
  };

  return (
    <Container>
      {/* <Col md={12} className="my-3">
                <Card.Img src='https://cdn.spinwheel.io/images/pricechopper/modules/banner-donate.png' />
            </Col> */}
      {!isDesktop && unAuthToken?.status === ACTIONS.STATUS.RESOLVED && (
        <Col className="text-center py-3 lcc-link-card-text-unauth">
          Link your AdvantEdge Card to start redeeming your points towards
          donations to charity!
        </Col>
      )}
      <Col className="heading-text text-center p-0">
        These are some of the many charities
      </Col>
      <Col className="heading-text text-center p-0">
        Price Chopper / Market 32 supports each year.
      </Col>

      {unAuthToken?.status === ACTIONS.STATUS.RESOLVED ? (
        <>
          {isDesktop && (
            <Col className="text-center py-3 lcc-link-card-text-unauth">
              Link your AdvantEdge Card to start redeeming your points towards
              donations to charity!
            </Col>
          )}
          <Col
            className={`text-center flex justify-center ${
              isDesktop ? '' : 'py-4 fixed-bottom bg-white'
            }`}
          >
            <Button
              // className={`lcc-signup-un-auth rounded h-100 border-top-0 ${isDesktop ? 'px-5' : 'w-100 '}`}
              onClick={handleLoginRegister}
            >
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="secondary"
                  className="align-middle mr-2"
                />
              )}
              Log In / Register
            </Button>
          </Col>
        </>
      ) : (
        <>
          <Col className="p-0 text-center lcc-intro-text mt-3">
            Use your Rewards points to donate to one of the featured charities
            below.
          </Col>
          <Col className="p-0 text-center lcc-intro-text">
            100 Rewards points = $1 to the charity you choose. Donate to just
            one or all four, it’s your choice!
          </Col>
        </>
      )}

      <Nav>
        {Object.keys(charities)?.map((charityId) => (
          <Col
            className="nav-item-wrap p-0"
            xs={6}
            md={4}
            sm={3}
            lg={3}
            key={charityId}
          >
            <Nav.Item className="p-2 h-100">
              <CharityCard
                charity={charities[charityId]}
                reservationId={reservationId}
              />
            </Nav.Item>
          </Col>
        ))}
      </Nav>
    </Container>
  );
}

export default BackNav(CharityList);

const CharityCard = ({ charity, reservationId = '' }) => {
  const { track } = useContext(AppContext.AppContext);
  const { tokenStatus, unAuthToken } = AuthContext.useAuth();
  const navigate = useNavigate();
  const handleCharity = () => {
    if (unAuthToken?.status === ACTIONS.STATUS.RESOLVED) {
      return;
    }
    track(EVENTS.DONATE_CLICKED, {
      id: charity?._id,
      name: charity?.name,
      ui_version: import.meta.env.APP_VERSION,
    });

    navigate(
      getCharityPath(charity?._id) +
        `${reservationId ? `?reservationId=${reservationId}` : ''}`
    );
  };
  return (
    <Card className="text-left h-100 border-0 mw-100 lcc-active-card bg-white">
      <Card.Header
        as="span"
        className="lcc-active-card-header-img bg-white p-2"
        onClick={handleCharity}
      >
        <Card className="rounded-0 border-0">
          <div className="position-relative lcc-active-card-img-wrapper">
            <Card.Img src={charity?.image} alt="Card image" />
          </div>
        </Card>
      </Card.Header>

      <Card.Body className="text-center p-2 border-0">
        <Card.Text className="mb-0 lcc-active-card-body-title">
          {charity?.name}
        </Card.Text>
      </Card.Body>
      <Card.Footer className="lcc-active-card-footer p-0 border-0">
        {tokenStatus === ACTIONS.STATUS.RESOLVED && (
          <Button
            width="full"
            className="m-0"
            //  className='lcc-active-card-footer-btn border-0 p-3'
            block
            onClick={handleCharity}
          >
            Donate
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};
