import React, { useState, useContext, useEffect, Fragment } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
  useMatch,
} from 'react-router-dom';
import { Spinner, Nav, Row, Col, Container } from 'react-bootstrap';
import { AppContext, ACTIONS, AuthContext } from '@rewards/contexts';

import SignUp from '../SignUp';
import UserProfile from '../UserProfile';

import {
  getLandingPath,
  getCategoryPath,
  getSignUpPath,
  getProfilePath,
} from '../../util/paths';
import useLocalStorage from '../../util/useLocalStorage';
import { useLoyaltyConnect } from '../../util/hooks';
import { formatPath } from '../../util/formatters';
import {
  APP_TABS,
  FEATURES_FLAGS,
  LOYALTY_CONNECT_MODULES_DETAILS,
} from '../../util/constants';
import { LoyaltyConnectContext } from '../../contexts/LoyaltyConnectContext';
import Icon from '../../assets/svg';
import { events, findEvents } from '../../analytics/events';

import './LandingPage.scss';

import BannerWrapper from '../BannerWrapper';
import { BonusPointsBanner, useBonusPointsModule } from '@rewards/bonus-points';
import CarouselBanner from '../CarouselBanner';
import CarouselModules from '../CarouselModules';
import { Button } from '@rewards/ui';
import { emitGVariables } from '@rewards/events';

const { useAuth } = AuthContext;
const GetBadge = (props) => (
  <div
    className={`w-full relative ${
      props.isLandingPage ? ` ${props.isDesktop ? 'mt-1' : ''}` : ''
    } mt-1 h-25`}
  >
    {(props.isNew || props.overlayText) && (
      <div
        className={`isNew-badge w-max text-center py-1 px-2 absolute inset-x-1/2 -translate-y-1/2 -translate-x-1/2 rounded ${
          props.isLandingPage ? '' : 'small'
        }`}
      >
        {props.overlayText || 'New!'}
      </div>
    )}
  </div>
);

function GetTitle(props) {
  const { isLandingPage, isDesktop } = props;
  return (
    <div
      className={`flex ${
        isLandingPage ? 'flex-column h-100' : 'align-items-center'
      }`}
    >
      {/* <GetBadge {...props} /> */}
      <div
        className={`text-center ${
          isLandingPage ? `w-100 ${isDesktop ? 'm-auto' : ''}` : 'ml-1 mt-1'
        }`}
      ></div>
      <div className={`${isLandingPage ? 'h-25 block' : 'flex '}`}>
        <div
          className={`nav-item-text w-100 droping-module-title-text ${
            isLandingPage
              ? `mt-3 text-center ${isDesktop ? 'm-auto' : ''}`
              : 'ml-3 text-left'
          }`}
        >
          {props.alterTitle ? props.alterTitle : props.title}
          {props.tmLabel ? <span>&trade;</span> : ''}
        </div>
        {/* {isDesktop && <GetBadge {...props} />} */}
      </div>
    </div>
  );
}

function LandingPage() {
  const { isUnAuthorizsedFlow, unAuthToken } = useContext(
    AuthContext.AuthContext
  );
  const { track } = useContext(AppContext.AppContext);
  const { isDesktop, isRedeemBalancePage } = useContext(LoyaltyConnectContext);
  const location = useLocation();
  const {
    code,
    featureFlags,
    sourceUserName,
    loanServicerId,
    isToolsForSchoolsInviteFriends,
  } = useAuth();
  const { registry, registryStatus } = useLoyaltyConnect();
  const bonusPointsModule = useBonusPointsModule();
  const productsModules = ['In Store Products', 'Ship To Home'];

  // const [key, setKey] = useState('');
  const isEarn = useMatch('/earn');
  const pathname = location.pathname;
  const splitPathName = isEarn
    ? pathname.split('/')?.[1]
    : pathname.split('/')?.[2];
  const navigate = useNavigate();
  const key = isEarn ? 'earn' : 'redeem';
  const [isFirstTimeSharedBorrower, setisFirstTimeSharedBorrower] =
    useState(false);

  const [isLoaded, setIsLoaded] = useLocalStorage('isLoaded', false);
  const [isStudentLoanLoaded, setIsStudentLoanLoaded] = useLocalStorage(
    'isStudentLoanLoaded',
    false
  );
  // const [enableComingSoonUtilityOffers, setEnableComingSoonUtilityOffers] =
  //   useState(false);
  const enableComingSoonUtilityOffers = featureFlags.find(
    (ff) =>
      ff.name === FEATURES_FLAGS.ENABLE_COMING_SOON_UTILITY_OFFERS &&
      !ff.isActive
  );
  const enableComingSoonProducts = featureFlags.find(
    (ff) =>
      ff.name === FEATURES_FLAGS.ENABLE_COMING_SOON_PRODUCTS && !ff.isActive
  );
  const isMAsubmoduleActive = featureFlags.find(
    (ff) => ff.name === FEATURES_FLAGS.ENABLE_COMMUNITY_SOLAR_MA && ff.isActive
  );
  const enableToolsForSchool = featureFlags.find(
    (ff) => ff.name === FEATURES_FLAGS.ENABLE_TOOLS_FOR_SCHOOLS && ff.isActive
  );
  const today = new Date().valueOf();

  useEffect(() => {
    if (
      (code || loanServicerId) &&
      sourceUserName &&
      !isToolsForSchoolsInviteFriends &&
      isUnAuthorizsedFlow
    ) {
      setisFirstTimeSharedBorrower(true);
    } else {
      setisFirstTimeSharedBorrower(false);
    }
  }, [
    code,
    loanServicerId,
    sourceUserName,
    isToolsForSchoolsInviteFriends,
    isUnAuthorizsedFlow,
  ]);

  // function updateCurrentKey(path) {
  //   let updateKey = path.split('/')[1];
  //   if (!registry[updateKey]) {
  //     updateKey = Object.keys(registry)[0];
  //   }
  //   setKey(updateKey);
  // }

  useEffect(() => {
    setIsLoaded(false);
    setIsStudentLoanLoaded(false);
  }, []);

  // useEffect(() => {
  //   if (registry) {
  //     const { pathname } = location;
  //     updateCurrentKey(pathname);
  //   }
  // }, [registry, location]);

  if (registryStatus !== ACTIONS.STATUS.RESOLVED)
    return (
      <div id="spinner-icon">
        <div className="text-center spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    );

  const handleSelect = async (k, e) => {
    const { key: appModule, path, havePath, name } = JSON.parse(k);

    track(findEvents(events(k, k)), {
      ui_version: import.meta.env.APP_VERSION,
    });
    switch (key) {
      case APP_TABS.REDEEM: {
        emitGVariables({ redeem_option: name });
        navigate(getLandingPath(appModule));
        break;
      }
      default: {
        emitGVariables({ earn_option: name });

        if (!havePath || appModule === 'survey') {
          navigate(getCategoryPath(appModule, key));
        } else if (appModule === 'question-of-the-month') {
          navigate(getCategoryPath('survey', key));
        } else {
          window.open(path, '_blank').focus();
        }
      }
    }
  };

  return (
    <Fragment>
      <Container fluid className="p-0">
        <BonusPointsBanner
          className={`mx-auto mb-2 ${isDesktop ? 'w-75' : ''} text-center`}
        />
        <>
          {isDesktop &&
            unAuthToken?.status === ACTIONS.STATUS.RESOLVED &&
            !isEarn &&
            !bonusPointsModule?.isActive && (
              <Col
                className="mx-auto p-0 text-center m-5 m"
                lg={isDesktop ? '9' : '12'}
              >
                <h1 className="text-h1 text-cobalt font-medium m-2">
                  Sign up or log in and start redeeming points today!
                </h1>
                <span className="landing-sub-text-para">
                  Use your card in-store or online to earn points
                  <br />
                  when you buy groceries at Price Chopper, Market 32, and Market
                  Bistro.
                </span>
                <Row className="m-3 flex justify-center">
                  <div className="w-50 flex items-center justify-end">
                    {' '}
                    <Button
                      onClick={() => handleLoginRegister()}
                      // className={`btn-button-custom-green btn-rounded ${(isDesktop) ? 'lg-link' : ''}`}
                    >
                      {isLoadingLogin && (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="secondary"
                            className="align-middle"
                          />{' '}
                        </>
                      )}
                      Log In / Register
                    </Button>
                  </div>
                  <div className="w-50 flex items-center justify-start">
                    <Button
                      as="a"
                      target="_blanck"
                      href="https://www.pricechopper.com/rewards/"
                      // onClick={() => goTo('https://www.pricechopper.com/rewards/')}
                      // className={`btn-button-custom-green btn-rounded ${(isDesktop) ? 'lg-link' : ''}`}
                    >
                      Learn More
                    </Button>
                  </div>
                </Row>
              </Col>
            )}

          <Col
            className="m-auto p-0 droping-modules-bg-gray m"
            lg={isDesktop ? '9' : '12'}
          >
            <CarouselBanner
              section={key}
              isDesktop={isDesktop}
              isLandingPage={true}
              mainTab={key}
            />

            {!isEarn ? (
              <Col
                className="text-center m-auto new-ways-to-redeem py-4"
                lg={9}
              >
                <span className={`title-${isDesktop ? 'size-30' : 'size-20'}`}>
                  Check out all the ways to redeem your points!
                </span>
              </Col>
            ) : (
              <Col
                className="text-center m-auto new-ways-to-redeem py-4"
                lg={9}
              >
                <span className={`title-${isDesktop ? 'size-30' : 'size-20'}`}>
                  Check out these ways to earn more AdvantEdge Rewards points!
                </span>
              </Col>
            )}

            <CarouselModules
              isDesktop={isDesktop}
              desktopShow={4}
              mobileShow={2}
            >
              {registry[key].tabs
                .filter((category, i) => {
                  if (
                    category.name === 'Tools For Schools' &&
                    !enableToolsForSchool
                  ) {
                    return false;
                  }
                  return true;
                })
                .map((category, i) => {
                  const ff = featureFlags.find(
                    ({ name }) => name === category.FF
                  );
                  let hasStarted =
                    ff?.otherDetails?.campaignActive != undefined
                      ? ff?.otherDetails?.campaignActive
                      : true;
                  const comingSoon = ff?.otherDetails?.commingSoon;
                  const showEndDate = ff?.otherDetails?.showEndDate;
                  const endText = ff?.otherDetails?.endText;
                  if (category.name === 'Question of the Month') {
                    hasStarted =
                      ff?.timeFlag?.find(
                        (survey) =>
                          survey.startTs <= today && survey.endTs >= today
                      ) && !ff?.otherDetails?.campaignEnded;
                  }

                  return (
                    <Col
                      key={i}
                      className="nav-item-wrap p-0"
                      xs={6}
                      sm={3}
                      lg={3}
                    >
                      <Nav.Item className={`p-${isDesktop ? 3 : 2}`}>
                        <div
                          className={` ${
                            isDesktop
                              ? 'nav-desktop-item-menu'
                              : 'nav-desktop-item-menu'
                          } m-auto overflow-hidden  ${
                            splitPathName === formatPath(category.label)
                              ? 'active'
                              : ''
                          }`}
                        >
                          <div className="droping-module-header flex !justify-center items-center flex-col pt-4 pb-4">
                            <Icon
                              icon={category.label.toLowerCase()}
                              width={isDesktop ? '130' : '120'}
                              height={isDesktop ? '130' : '120'}
                              stroke={
                                splitPathName === formatPath(category.label)
                                  ? 'white'
                                  : null
                              }
                            />
                            {category.name === 'Community Solar' && (
                              <Col
                                md={12}
                                className="Community-solar-card-module-name text-center !pt-3"
                              >
                                Community Solar
                              </Col>
                            )}

                            <GetBadge {...category} />
                          </div>
                          <div
                            className={`droping-module-body ${
                              isDesktop ? 'droping-module-body-space' : ''
                            } text-center p-${isDesktop ? 3 : 2}`}
                          >
                            <span className="droping-module-name pt-2 pb-2">
                              <GetTitle
                                title={category.name}
                                alterTitle={category.alterLabel}
                                tmLabel={category.tmLabel}
                                isNew={category.isNew}
                                isLandingPage={true}
                                overlayText={category.overlayText}
                                isDesktop={isDesktop}
                              />
                            </span>
                            {isDesktop && (
                              <div className="text-center droping-module-name-separator mt-1"></div>
                            )}
                            {isDesktop &&
                              (enableComingSoonProducts &&
                              productsModules.includes(category.name) ? (
                                <div
                                  className="text-cobalt p-2"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      LOYALTY_CONNECT_MODULES_DETAILS[
                                        category.name
                                      ].description_coming_soon,
                                  }}
                                ></div>
                              ) : (
                                <>
                                  <div
                                    className="text-cobalt p-2"
                                    dangerouslySetInnerHTML={{
                                      __html: hasStarted
                                        ? LOYALTY_CONNECT_MODULES_DETAILS[
                                            category.name
                                          ].description
                                        : LOYALTY_CONNECT_MODULES_DETAILS[
                                            category.name
                                          ].description_ended,
                                    }}
                                  ></div>
                                  {hasStarted && showEndDate && endText && (
                                    <div className="text-cobalt p-2">
                                      {endText}
                                    </div>
                                  )}
                                </>
                              ))}
                          </div>
                          <div className="droping-module-footer flex text-center pb-4 pt-4 pl-2 pr-2">
                            {enableComingSoonProducts &&
                            productsModules.includes(category.name) ? (
                              <div
                                className="text-white p-2"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    LOYALTY_CONNECT_MODULES_DETAILS[
                                      category.name
                                    ].rule_coming_soon,
                                }}
                              />
                            ) : (
                              !(
                                category.name === 'Community Solar' &&
                                isMAsubmoduleActive
                              ) &&
                              LOYALTY_CONNECT_MODULES_DETAILS[category.name]
                                .rule &&
                              LOYALTY_CONNECT_MODULES_DETAILS[category.name]
                                .rule.length > 0 && (
                                <div
                                  className="text-white p-2"
                                  dangerouslySetInnerHTML={{
                                    __html: hasStarted
                                      ? LOYALTY_CONNECT_MODULES_DETAILS[
                                          category.name
                                        ].rule
                                      : LOYALTY_CONNECT_MODULES_DETAILS[
                                          category.name
                                        ].rule_ended,
                                  }}
                                />
                              )
                            )}

                            {(category.name === 'Community Solar' &&
                              isMAsubmoduleActive &&
                              category.secondPath) ||
                            (category.secondPath &&
                              category.name !== 'Community Solar') ? (
                              <Nav.Link
                                eventKey={JSON.stringify({
                                  key: formatPath(category.label),
                                  path: category?.path,
                                  name: category?.name,
                                  havePath: category?.havePath,
                                })}
                                className={`p-0 pt-2 text-dark h-100 droping-module-two-buttons `}
                              >
                                <div className="h-50">
                                  {category.labelButton && (
                                    <div className="mb-2 text-white">
                                      {category.labelButton}
                                    </div>
                                  )}
                                  <Button
                                    width="full"
                                    className="!m-0"
                                    onClick={() =>
                                      handleSelect(
                                        JSON.stringify({
                                          key: formatPath(category.label),
                                          path: category?.path,
                                          name: category?.name,
                                          havePath: category?.havePath,
                                        })
                                      )
                                    }
                                    // className={`btn-button-custom-green btn-rounded h-50 w-75 mb-4 ${(isDesktop) ? '' : ''}`}
                                  >
                                    {!isEarn
                                      ? 'Redeem'
                                      : formatPath(category.label) ===
                                        'student-loan-refinance'
                                      ? 'Shop Now'
                                      : 'Earn'}
                                  </Button>
                                </div>

                                <div className="h-50 droping-module-second-button-align">
                                  {category.secondLabelButton && (
                                    <div className="mb-2 text-white">
                                      {category.secondLabelButton}
                                    </div>
                                  )}
                                  <Button
                                    width="full"
                                    className="!m-0"
                                    onClick={() =>
                                      handleSelect(
                                        JSON.stringify({
                                          key: formatPath(category.label),
                                          path: category?.secondPath,
                                          name: category?.name,
                                          havePath: category?.havePath,
                                        })
                                      )
                                    }
                                    // className={`btn-button-custom-green btn-rounded h-50 w-75 ${(isDesktop) ? '' : ''}`}
                                  >
                                    {!isEarn
                                      ? 'Redeem'
                                      : formatPath(category.label) ===
                                        'student-loan-refinance'
                                      ? 'Shop Now'
                                      : 'Earn'}
                                  </Button>
                                </div>
                              </Nav.Link>
                            ) : (
                              <Nav.Link
                                eventKey={JSON.stringify({
                                  key: formatPath(category.label),
                                  path: category?.path,
                                  name: category?.name,
                                  havePath: category?.havePath,
                                })}
                                className={`p-0 text-dark h-100 link-to-droping-module `}
                              >
                                <Button
                                  width="full"
                                  className="!m-0"
                                  onClick={() =>
                                    handleSelect(
                                      JSON.stringify({
                                        key: formatPath(category.label),
                                        path: category?.path,
                                        name: category?.name,
                                        havePath: category?.havePath,
                                      })
                                    )
                                  }
                                  // className={`btn-button-custom-green btn-rounded w-75 ${(isDesktop) ? '' : ''}`}
                                >
                                  {!isEarn
                                    ? 'Redeem'
                                    : formatPath(category.label) ===
                                      'student-loan-refinance'
                                    ? 'Shop Now'
                                    : 'Earn'}
                                </Button>
                              </Nav.Link>
                            )}
                          </div>
                        </div>
                      </Nav.Item>
                    </Col>
                  );
                })}
            </CarouselModules>
          </Col>
        </>
      </Container>
      <Routes>
        {/* <Route
          path={getClaimBonusPagePath()}
          exact
          component={BonusPointsPage}
        /> */}
        <Route path={getSignUpPath()} exact component={SignUp} />
        <Route path={getProfilePath()} exact component={UserProfile} />
        {/* <Route path={getSurveyPath()} exact component={Survey} /> */}

        <Route path="*" render={() => <Navigate to={getLandingPath()} />} />
      </Routes>
      <BannerWrapper />
    </Fragment>
  );
}

LandingPage.displayName = 'Landing Page';

export default LandingPage;
