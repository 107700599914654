import React, { useEffect, useContext } from 'react';
import { Container, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '@rewards/contexts';

import SelectSchoolModals from '../SelectSchoolModals';
import BackNav from '../BackNav';

import { useResponsive } from '../../util/hooks';
import { BACK_NAV_HEADING } from '../../util/constants';
import { SchoolContext } from '../../contexts/SchoolContexts';
import { PAGES, EVENT_LOCATION } from '../../analytics/events';

import './LandingDashboard.scss';

const LandingDashboard = () => {
  const { isDesktop } = useResponsive();
  const navigate = useNavigate();

  const { landingPath, source } = useContext(AppContext.AppContext);
  const { selectedSchool } = useContext(SchoolContext);

  useEffect(() => {
    LandingDashboard.heading = BACK_NAV_HEADING.TOOLS_FOR_SCHOOLS;
    LandingDashboard.landingPath = landingPath;
    LandingDashboard.LOCATION =
      EVENT_LOCATION.TOOLS_FOR_SCHOOLS_DETAILS_DASHBOARD;

    source(PAGES.TOOLS_FOR_SCHOOLS_DETAILS_DASHBOARD, {
      ui_version: import.meta.env.APP_VERSION,
    });
  }, [landingPath]);

  useEffect(() => {
    if (selectedSchool) {
      navigate(landingPath);
    }
  }, [selectedSchool]);

  return (
    <Container>
      <Col
        md={isDesktop ? 9 : 12}
        className={`bg-transparent mx-auto mt-3 ${
          isDesktop ? 'px-0 py-3 bg-white' : 'p-0'
        }`}
      >
        {/* {isDesktop && <LandingDashboardTitle isDesktop={isDesktop} />} */}
        <Card
          className={`bg-transparent text-center rounded-2 borderless tfs-landing-card ${
            isDesktop ? 'mb-5' : 'mb-3'
          }`}
        >
          <Card.Body className="border-0 p-0 flex flex-col items-center">
            {/* {!isDesktop &&
              <Card.Title className='m-0 mb-3'>
                <LandingDashboardTitle />
              </Card.Title>
            } */}
            <Card.Img
              className={`${isDesktop ? 'w-75 rounded' : ''} px-2`}
              variant="bottom"
              src="https://cdn.spinwheel.io/images/tcc/tools-for-school.png"
            />
            {isDesktop && <LandingDashboardInfo />}
          </Card.Body>
          {isDesktop && (
            <Col lg={6} className="mx-auto flex justify-center">
              <SelectSchoolModals />
            </Col>
          )}
        </Card>

        {!isDesktop && (
          <>
            <LandingDashboardInfo />
            <Col className="tfs-landing-slot p-0"></Col>
            <Col className="fixed-bottom flex flex-row p-3 justify-center tfs-landing-btns">
              <SelectSchoolModals />
            </Col>
          </>
        )}
      </Col>
    </Container>
  );
};

export default LandingDashboard;

const LandingDashboardTitle = ({ isDesktop = false }) => (
  <Col
    className={`tfs-landing-${isDesktop ? 'desktop' : 'mobile'}-title ${
      isDesktop ? '' : 'p-0'
    } text-center mb-3`}
  >
    Use <span className="strong">AdvantEdge Rewards</span> points to support
    your local school
  </Col>
);
const LandingDashboardInfo = () => {
  const { isDesktop } = useResponsive();
  return (
    <>
      <Col
        className={`tfc-landing-info p-0 text-start ${
          isDesktop ? 'py-4 mt-3' : ''
        }`}
      >
        <span className="strong">Price Chopper / Market 32</span> is proud to
        support schools and the communities in which we operate. The Tools for
        Schools program has provided over $14 million to local schools over the
        past 20 years.
        <br />
        <br />
        <span className="strong">Earn stars every time you shop!</span> Select
        your school below to have your shopping count toward school stars. Every
        star adds to your school’s balance toward new supplies and equipment.
        Earn even more stars by inviting your friends!
        <br />
        <br />
        <span className="strong">You can donate your Rewards points.</span>{' '}
        After registering, you can choose to donate your Rewards points to your
        school for them to receive catalog points.
      </Col>
    </>
  );
};
