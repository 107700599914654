import { App } from './lib/module';

export * as SurveyModuleAtoms from './lib/atoms';
export const register = () => {
  return {
    parent: 'earn',
    label: 'Question of the Month',
    alterLabel: 'Question of the Month',
    content: App,
  };
};
export { App as SurveyModule };
