import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthContext } from '@rewards/contexts';
import { KnivesContextState } from './contexts/KnivesContext';
import {
  fetchKnives,
  fetchShippingAddress,
  createShippingAddress,
  fetchPurchasedTxns,
} from './services/knivesService';
import {
  getLandingPath,
  getKnifeInfoPath,
  getUserAddressPath,
  getAddressListPath,
  getAddressFormPath,
} from './util/paths';
import { FEATURES_FLAGS } from './util/constants';

import ComingSoon from './components/ComingSoon';
import LandingPage from './components/LandingPage';
import KnifeInfo from './components/KnifeInfo';
import UserAddress from './components/UserAddress';
import AddressList from './components/UserAddress/components/AddressList';
import AddressForm from './components/UserAddress/components/AddressForm';

// import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import './styles.css';

const { useAuth } = AuthContext;

const App = () => {
  const { featureFlags } = useAuth();
  const [activateCommingSoonPage, setActivateComingSoonPage] = useState(false);

  useEffect(() => {
    if (
      featureFlags.find(
        (ff) =>
          ff.name === FEATURES_FLAGS.ENABLE_COMING_SOON_PRODUCTS && !ff.isActive
      )
    ) {
      setActivateComingSoonPage(true);
    }
  }, [featureFlags]);

  return (
    <KnivesContextState
      fetchKnives={fetchKnives}
      fetchShippingAddress={fetchShippingAddress}
      createShippingAddress={createShippingAddress}
      fetchPurchasedTxns={fetchPurchasedTxns}
    >
      {activateCommingSoonPage ? (
        <ComingSoon />
      ) : (
        <Routes>
          <Route exact path={getLandingPath()} component={LandingPage} />
          <Route exact path={getAddressListPath()} component={AddressList} />
          <Route exact path={getAddressFormPath()} component={AddressForm} />

          <Route exact path={getUserAddressPath()} component={UserAddress} />
          <Route exact path={getKnifeInfoPath(':id')} component={KnifeInfo} />
          <Route path="*" render={() => <Navigate to={getLandingPath()} />} />
        </Routes>
      )}
    </KnivesContextState>
  );
};

export default App;
