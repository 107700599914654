import React, { useContext, useEffect, useState } from 'react';
// import { Button } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { AppContext, AuthContext } from '@rewards/contexts';
import * as Separator from '@radix-ui/react-separator';

import BackNav from '../BackNav';

import { EVENTS, PAGES } from '../../analytics/events';
import Icon from '../../assets/svg';

import './LandingPage.scss';

const LandingPageUpdated = ({ isMAActive, isNYActive }) => {
  const { track } = useContext(AppContext.AppContext);
  const { envInfo } = useContext(AuthContext.AuthContext);

  const handleClick = (url) => {
    track(EVENTS.UTILITY_OFFERS_APPLY_BUTTON_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
      url: url,
    });
    window.open(url, '_blank').focus();
  };

  useEffect(() => {
    track(PAGES.VIEWED_UTILITY_OFFERS_PAGE, {
      ui_version: import.meta.env.APP_VERSION,
    });
  }, []);

  return (
    <>
      <div
        className={`d-flex flex flex-column position-relative flex-lg-row px-0 py-2 justify-content-around w-75 mx-auto`}
      >
        <div
          className={`pt-0 pb-2 flex-column  justify-content-between h-100 `}
        >
          <div className="px-5 align-items-center text-center">
            <span className={`text-center`}>
              <span className="title-text-utility-offers">
                Routes to the
                <br />
                <span className="strong-text">
                  Polution Free Wind with <br className="d-none d-lg-block" />{' '}
                  AdvantEdge Rewards Energy Plan!
                </span>
              </span>
              <br /> Earn 10,000 Rewards points ($100 value)
              <br />
              when you sign up for a<br />
              Green Mountain Energy 100% clean energy plan!
            </span>
            <p className="pt-4 mb-1 text-center">
              <span className="text-muted">
                Plus earn 1,000 points ($10 value) every 6 months!
              </span>
              <br />
              <span className="text-muted pt-2">
                Open to PA & MA Residents in National Grid.
              </span>
            </p>
          </div>
          <div className={`d-flex flex justify-content-around mt-4`}>
            <div className="text-center bg-white card-updated-utility-offers mb-4 p-0">
              <div className="flex justify-center items-center mt-4 mb-4">
                <Icon
                  className="v-center"
                  icon="utility offers"
                  width={'125'}
                  height={'125'}
                />
              </div>
              <hr />
              <span className="text-center pt-4 pb-3">
                PA and MA <br />
                Residents
              </span>
              <Button
                className={`!m-0 btn-button-custom-green-utility-offers utility-offers-shadow-box w-100 !mt-3 lg-link !rounded-t-none`}
                onClick={() => handleClick(envInfo?.greenMountainPath)}
              >
                APPLY
              </Button>
            </div>
          </div>
        </div>

        {(isNYActive || isMAActive) && (
          <>
            <Separator.Root orientation="vertical" className="separator" />
            <div
              className={`pt-2 pb-2 d-flex flex flex-column  justify-content-between`}
            >
              <div className="px-5 pb-lg-5 align-items-center text-center">
                <span className={`text-center`}>
                  <span className="strong-text">Support a solar tomorrow.</span>
                  <br />
                  Earn 6,000 Rewards points ($60 value)
                  <br /> for helping the planet when you join the
                  <br />{' '}
                  <span className="strong-text">
                    Green Mountain Energy
                    <br />
                    Community Solar Program!
                  </span>
                </span>

                <p className="pt-3">
                  <span className="text-muted">
                    Open to select states where offers are available in National
                    Grid.
                  </span>
                  <br />
                </p>
              </div>
              <div className={`d-flex flex justify-content-around`}>
                {isNYActive && (
                  <div className="text-center bg-white card-updated-utility-offers mb-4 p-0 lg-link ">
                    <div className="d-flex flex flex-column align-items-center mt-4">
                      <Icon
                        className="v-center"
                        icon="community-solar-icon"
                        width={'75'}
                        height={'76'}
                      />
                      <span className="utility-offers-text-primary mt-4">
                        Community Solar
                      </span>
                    </div>
                    <hr />
                    <span className="text-center pt-4 pb-3">
                      New York
                      <br />
                      Residents
                    </span>
                    <Button
                      className={`!m-0 btn-button-custom-green-utility-offers utility-offers-shadow-box w-100 !mt-3 lg-link !rounded-t-none`}
                      onClick={() => handleClick(envInfo?.communitySolarNYPath)}
                    >
                      APPLY
                    </Button>
                  </div>
                )}

                {isMAActive && (
                  <div
                    className={`ml-1 text-center bg-white card-updated-utility-offers mb-4 p-0`}
                  >
                    <div className="d-flex flex flex-column align-items-center mt-4">
                      <Icon
                        className="v-center"
                        icon="community-solar-icon"
                        width={'75'}
                        height={'76'}
                      />
                      <span className="utility-offers-text-primary mt-4">
                        Community Solar
                      </span>
                    </div>
                    <hr />
                    <span className="text-center pt-4 pb-3">
                      Massachusetts
                      <br />
                      Residents
                    </span>
                    <Button
                      className={`!m-0 btn-button-custom-green-utility-offers utility-offers-shadow-box w-100 !mt-3 lg-link  !rounded-t-none`}
                      onClick={() => handleClick(envInfo?.communitySolarMAPath)}
                    >
                      APPLY
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BackNav(LandingPageUpdated);
