import { Button } from '../../../../ui/src';
import { Modal } from '@rewards/ui';
import { Trigger } from '@radix-ui/react-dialog';

export const FAQs = ({ className }) => {
  return (
    <Modal.Root type="document">
      <Trigger asChild>
        <Button
          intent={'link'}
          size={'wrapper'}
          style={{
            fontSize: '0.5rem',
          }}
          className={className}
        >
          FAQs
        </Button>
      </Trigger>
      <Modal.Content>
        <Modal.Header>
          <Modal.CloseButton color="white" />
          <Modal.Title className="text-h2">
            AdvantEdge Rewards Points Multiplier - Frequently Asked Questions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-6">
          <h3 className="h6">What is this multiplier promotion?</h3>
          <p>
            Activate your AdvantEdge Card online starting October 2, 2024 to
            earn additional AdvantEdge Rewards points from October 6, 2024
            through December 24, 2024. You must have an online account to
            activate your AdvantEdge Card.{' '}
          </p>
          <br />
          <h3 className="h6">How do I activate my AdvantEdge Card? </h3>
          <p>
            Visit{' '}
            <a
              className="text-blue-600 underline"
              href="https://rewards.pricechopper.com"
            >
              https://rewards.pricechopper.com
            </a>
            &nbsp;and log in to your AdvantEdge Card online account. If you
            don’t have an online account, you can create one for your AdvantEdge
            Card. If you do not have an AdvantEdge Card, you can request one
            online at pricechopper.com or in any Price Chopper, Market 32 or
            Market Bistro store at Guest Services.{' '}
          </p>
          <br />
          <h3 className="h6">
            When can I start earning my additional AdvantEdge Rewards points?{' '}
          </h3>
          <p>
            Once you’ve activated your AdvantEdge Card, all future purchases
            (exclusions apply) during the promotion period (October 6, 2024
            through December 24, 2024) will earn additional points!
          </p>
          <br />
          <h3 className="h6">Why do I have to activate my AdvantEdge Card? </h3>
          <p>
            This limited time promotion is in addition to our regular AdvantEdge
            Rewards promotions so we want to make sure you activate your
            AdvantEdge Card to earn your extra savings!
          </p>
          <br />
          <h3 className="h6">How much will my extra savings be? </h3>
          <p>
            Once you activate your AdvantEdge Card at{' '}
            <a
              className="text-blue-600 underline"
              href="https://rewards.pricechopper.com"
            >
              https://rewards.pricechopper.com
            </a>
            , your points multiplier will be revealed to you. You could earn up
            to 4x!
          </p>
          <br />
          <h3 className="h6">
            Why is my discount different from my neighbor's discount?
          </h3>
          <p>
            The AdvantEdge Rewards points multiplier promotion is a special,
            limited time promotion where everyone is eligible to earn at least
            2x their regular AdvantEdge Rewards points. Some lucky folks may get
            3x or even 4x.
          </p>
          <br />
          <h3 className="h6">How does the multiplier promotion work?</h3>
          <p>
            All dollars spent (exclusions apply) will earn AdvantEdge Rewards
            points at the rate of your points multiplier. For example, if your
            points multiplier is 2x, you will earn twice as many points on your
            qualifying purchases during the promotion period.
          </p>
          <p>
            Only dollars spent will be multiplied, not AdvantEdge Rewards points
            earned. This promotion does not factor in or multiply standalone
            bonus or manufacturer points offers.
          </p>
          <br />
          <h3 className="h6">
            Do I earn additional points on everything I purchase?
          </h3>
          <p>
            Eligible points multiplier spend is calculated after the deduction
            of AdvantEdge Card savings and coupons.{' '}
            <strong>
              The following items are excluded: alcoholic beverages, tobacco
              products, gift cards, prescriptions, stamps, lottery tickets,
              money orders, cash back, tickets, tax, bottle deposits and all
              telecom products and services
            </strong>
            . Other exclusions may apply.
          </p>
          <br />
          <h3 className="h6">
            What if I didn’t activate my AdvantEdge Card right at the beginning?
          </h3>
          <p>
            No problem! You can activate your AdvantEdge Card at any time. Just
            remember, you will not earn your multiplier bonus points until you
            have activated online. Once activated, prior purchases do not earn
            bonus points.
          </p>
          <br />
          <h3 className="h6">How do I keep track of my savings?</h3>
          <p>
            Check your register receipt each time you shop for up-to-date
            tracking of your bonus AdvantEdge Rewards points earned and current
            points balance.
          </p>
          <p>
            You can always check your AdvantEdge Rewards balance{' '}
            <a
              className="text-blue-600 underline"
              href="https://rewards.pricechopper.com"
            >
              here
            </a>
            .
          </p>
          <br />
          <h3 className="h6">
            What if I forgot to use my AdvantEdge Card when I shopped?
          </h3>
          <p>
            Bring your AdvantEdge Card and your receipt to Guest Services the
            next time you shop and they’ll be happy to add your points!
          </p>
          <br />
          <h3 className="h6">
            How long do I have to earn and redeem my additional points?
          </h3>
          <p>
            You can multiply your earnings from October 6, 2024 through December
            24, 2024 after you activate your points multiplier. Your points will
            expire at the same rate as always – on a rolling schedule, two
            months from the last day of the month in which your AdvantEdge
            Rewards points were earned.
          </p>
          <br />
          <h3 className="h6">
            Do I need to do anything special in store or at the gas pump?
          </h3>
          <p>
            Nope! Just activate your AdvantEdge Card and all your purchases
            (exclusions apply) using your AdvantEdge Card will automatically
            earn you additional AdvantEdge Rewards points. Redemption steps are
            no different during the promotion period.
          </p>
          <br />
          <h3 className="h6">Can I share my rewards with someone else?</h3>
          <p>
            AdvantEdge Rewards points earned are unique to each AdvantEdge Card
            and may not be shared or transferred between AdvantEdge Cards.
          </p>
          <br />

          <h3 className="h6">
            Where can I learn more about the AdvantEdge Rewards promotion?
          </h3>
          <p>
            Check out our{' '}
            <a
              className="text-blue-600 underline"
              href="https://www.pricechopper.com/rewards/"
            >
              AdvantEdge Rewards Page
            </a>{' '}
            for all details.
          </p>
          <br />
        </Modal.Body>
        {/* <section className="text-left overflow-auto">
          
        </section> */}
        <Modal.Footer>
          <Modal.CloseButtonWrapper asChild>
            <Button>Close</Button>
          </Modal.CloseButtonWrapper>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
