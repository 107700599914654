import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext, AuthContext } from '@rewards/contexts';
import { Col, Container, Card } from 'react-bootstrap';

import { useResponsive, useSchool } from '../../util/hooks';

import BackNav from '../BackNav';

import './ComingSoonDashboard.scss';
import { StarsEarnedInfo } from '../SelectedSchoolDasboard/SelectedSchoolInfo';
import { FamilySupportInfo } from '../SelectedSchoolDasboard/FamilySupportInfo';

const ComingSoonDashboard = () => {
  const { isDesktop } = useResponsive();
  const navigate = useNavigate();

  const { landingPath } = useContext(AppContext.AppContext);
  const { school } = useSchool();
  const { token } = AuthContext.useAuth();

  useEffect(() => {
    ComingSoonDashboard.landingPath = landingPath;
  }, [landingPath]);

  // useEffect(() => {
  //   history.listen(() => {
  //     if (history.action === 'POP') {
  //       history.replace(landingPath);
  //     }
  //   });
  // }, [history]);

  return (
    <Container className="flex flex-col lg:flex-row ">
      <Col className={``}>
        <Card className="rounded-2 borderless ">
          <Col className="text-center pt-4 mt-2 t4s-coming-soon-header">
            This year's program has ended.
          </Col>
          <Card.Body className="flex flex-row">
            <Col className="p-0">
              <Card.Img
                variant="bottom"
                className="h-100 rounded"
                src="https://cdn.spinwheel.io/images/tcc/tools-for-school.png"
              />
            </Col>
          </Card.Body>
          <Col className="text-center mt-3 mb-4 t4s-coming-soon-body-text">
            Thank you for your support in helping your school earn its share of
            over $200,000 in educational equipment for schools in the
            communities we serve.
          </Col>
        </Card>
      </Col>
      {token && (
        <div className="flex flex-col lg:flex-col-reverse justify-start lg:justify-end">
          <StarsEarnedInfo isDesktop={isDesktop} />
          <FamilySupportInfo />
        </div>
      )}
    </Container>
  );
};

export default ComingSoonDashboard;
