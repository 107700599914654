import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { TravelsContextState } from './contexts/TravelsContext';
import {
  fetchTravels,
  fetchTravelsUnAuthorized,
} from './services/TravelsService';
import { getLandingPath, getTravelsListPath } from './util/paths';

import Travels from './components/Travels';
import TravelsList from './components/TravelsList';

// import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import './styles.css';

const App = () => {
  return (
    <TravelsContextState
      fetchTravels={fetchTravels}
      fetchTravelsUnAuthorized={fetchTravelsUnAuthorized}
    >
      <Routes>
        <Route exact path={getLandingPath()} component={Travels} />
        <Route
          exact
          path={getTravelsListPath(':code')}
          component={TravelsList}
        />
        <Route path="*" render={() => <Navigate to={getLandingPath()} />} />
      </Routes>
    </TravelsContextState>
  );
};

export default App;
