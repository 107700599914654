import { Col, Nav, Row } from 'react-bootstrap';
import { useLoyaltyConnect, useResponsive } from '../util/hooks';
import {
  Outlet,
  useMatch,
  useNavigate,
  useLocation,
  Link,
  Route,
  Routes,
} from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { emitGVariables } from '@rewards/events';
import TopNav from '../components/TopNav';
import { CookiePolicyBanner } from '../components/CookiesBanner';
import AuthenticateUser from '../components/AuthenticateUser';
import { formatPath } from '../util/formatters';
import Footer from '../components/Footer';
import {
  getCategoryPath,
  getLandingPath,
  getProfilePath,
  getSignUpPath,
} from '../util/paths';
import Icon from '../assets/svg';
import styles from '../util/styleConsts';
import AuthenticaUserSSO from '../components/AuthenticaUserSSO';
import SignUp from '../components/SignUp';
import UserProfile from '../components/UserProfile';
import { APP_TABS } from '../util/constants';

const Layout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  const { registry, registryStatus } = useLoyaltyConnect();
  const isEarn = useMatch('earn/*');
  console.log('🚀 ~ Layout ~ isEarn:', isEarn);
  const key = isEarn ? 'earn' : 'redeem';

  const registryContent = registry[isEarn ? 'earn' : 'redeem'];
  const splitPathName = isEarn
    ? pathname.split('/')[2]
    : pathname.split('/')[1];
  const isLandingPage = true;
  const handleSelect = (k) => {
    console.log('🚀 ~ handleSelect ~ k:', k);
    const { key: appModule, path, havePath, name } = JSON.parse(k);

    switch (key) {
      case APP_TABS.REDEEM: {
        emitGVariables({ redeem_option: name });
        navigate(getLandingPath(appModule));
        break;
      }
      default: {
        ({ earn_option: name });
        console.log(
          '🚀 ~ handleSelect ~ getCategoryPath(appModule, registryContent):',
          getCategoryPath(appModule, key),
          appModule,
          registryContent
        );

        if (!havePath || appModule === 'survey') {
          navigate(getCategoryPath(appModule, key));
        } else if (appModule === 'question-of-the-month') {
          navigate(getCategoryPath('survey', key));
        } else {
          window.open(path, '_blank').focus();
        }
      }
    }
  };

  const match = useMatch('/');

  return (
    <>
      <TopNav />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          zIndex: 0,
          flex: '1 0',
          paddingBottom: '2.5rem',
          background: '#F9F9F9',
        }}
      >
        {!!registryContent && (
          <>
            <Row className="registry-tabs align-items-center m-0">
              {isDesktop && (
                <Col md="2" className="text-left">
                  <Link to={getLandingPath()}>
                    <Icon
                      icon="advantEdge-rewards"
                      height="30"
                      width="100"
                      color={styles.cobalt}
                    />
                  </Link>
                </Col>
              )}
              <Col
                className={`tabs rounded-pill align-self-start ${
                  isDesktop ? '' : 'mb-3'
                }`}
                md={isDesktop ? 3 : null}
              >
                <Nav
                  variant="pills"
                  className="justify-content-around text-center nav-fill"
                  activeKey={isEarn ? '/earn' : '/'}
                  // defaultActiveKey={key}
                >
                  <Nav.Item className="text-capitalize" id={`redeem-nav-item`}>
                    <LinkContainer to={'/'} key={'/'}>
                      <Nav.Link
                        href={'/'}
                        className="rounded-pill text-capitalize l-nav-link"
                      >
                        Redeem
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                  <Nav.Item className="text-capitalize" id={`earn-nav-item`}>
                    <LinkContainer to={'/earn'} key={'/earn'}>
                      <Nav.Link
                        href={'/earn'}
                        className="rounded-pill text-capitalize l-nav-link"
                      >
                        Earn
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                </Nav>
              </Col>
              {isDesktop && (
                <Col className="">
                  <AuthenticaUserSSO />
                </Col>
              )}
            </Row>
            <nav className="hide lg:flex navbar navbar-expand-lg navbar-light bg-light navbar-bottom-line">
              <div className="navbar-collapse flex justify-content-center">
                <Nav onSelect={handleSelect} className="navbar-nav">
                  {registryContent.tabs.map(
                    (category, index) =>
                      category?.name !== 'Community Solar' && (
                        <Nav.Item
                          key={category.name}
                          className={`m-2 nav-item`}
                          // onClick={() =>
                          //   handleSelect(category?.name, registryContent)
                          // }
                        >
                          <Nav.Link
                            eventKey={JSON.stringify({
                              key: formatPath(category.label),
                              path: category?.path,
                              name: category?.name,
                              havePath:
                                category?.havePath &&
                                !category?.navPathInternal,
                            })}
                            className={`p-0 h-100 ${
                              isLandingPage ? '' : 'flex'
                            } ${
                              splitPathName === formatPath(category.label)
                                ? 'menu-active'
                                : ''
                            }`}
                          >
                            {category.alterLabel
                              ? category.alterLabel
                              : category.name}
                          </Nav.Link>
                        </Nav.Item>
                      )
                  )}
                </Nav>
              </div>
            </nav>
          </>
        )}
        <Outlet />

        <CookiePolicyBanner />
      </div>
      <Footer />
      <AuthenticateUser />
    </>
  );
};

Layout.displayName = 'Layout';

export { Layout };
