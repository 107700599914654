import React from 'react';
import App from './App';
import Icon from './assets/svg';
import styleConsts from './util/styleConsts';
const register = () => {
  return {
    parent: 'redeem',
    label: 'Donate',
    icon: (
      <Icon icon="donate" width="32" height="32" color={styleConsts.cobalt} />
    ),
    content: function Content() {
      return <App />;
    },
  };
};

export default { register };
export { App as CharityApp };
