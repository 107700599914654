import React, { useEffect } from 'react';
import { Container, Col, Button, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Icon from '../../assets/svg';
import useResponsive from '../../util/hooks/useResponsive';
import styleConsts from '../../util/styleConsts';

import './BackNav.scss';

export default (WrappedComponent) => {
  const BackNav = (props) => {
    const navigate = useNavigate();

    const { isDesktop } = useResponsive();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const handleBackPropagation = () => {
      WrappedComponent.track();
      navigate(WrappedComponent.landingPath);
    };
    return (
      <>
        {isDesktop ? (
          <div className="flex justify-center">
            <Col md={WrappedComponent?.showBackBtn ? '10' : '12'}>
              <WrappedComponent {...props} />
            </Col>
          </div>
        ) : (
          <>
            <Container fluid className="back-nav">
              <Row>
                <Col xs="1" sm="1" md="1" className="back-nav-heading">
                  <span onClick={handleBackPropagation} className="hover">
                    <Icon icon="angle-left" width="9" height="17" />
                  </span>
                </Col>
                <Col
                  xs="10"
                  sm="10"
                  md="10"
                  className="back-nav-heading text-center"
                >
                  <span className="">Sweepstakes</span>
                </Col>
              </Row>
            </Container>
            <WrappedComponent {...props} />
          </>
        )}
      </>
    );
  };

  return BackNav;
};
