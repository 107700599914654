import React, { useEffect, useState, useContext } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';
import { useAsync, ACTIONS, AuthContext, AppContext } from '@rewards/contexts';
import { fetchCampaigns } from '../../services/campaignService';
import { dateString, formatPath } from '../../util/formatters';
import './CarouselBanner.scss';
import { Col, Spinner, Row } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { APP_TABS, FEATURES_FLAGS } from '../../util/constants';
import styles from '../../util/styleConsts';
import {
  getLandingPath,
  getCategoryPath,
  getPriceChopperShopPath,
  getSurveyPath,
} from '../../util/paths';
import { useUserInfo } from '@rewards/data-provider';
import { EVENTS } from '../../analytics/events';

import Icon from '../../assets/svg';
import { DailySurpriseCarouselItemContent } from './DailySurpriseCarouselItemContent';
const { useAuth } = AuthContext;

export default function CarouselBanner(
  { section = '', isDesktop = true, isLandingPage = true, mainTab },
  props
) {
  const { token, unAuthToken, checkValidToken, featureFlags } = useAuth();
  const { track } = useContext(AppContext.AppContext);
  const navigate = useNavigate();
  const { envInfo } = useContext(AuthContext.AuthContext);
  const asyncCampaignsData = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { campaigns: null },
  });
  const {
    data: campaignsData,
    run: runAsyncCampaignsData,
    status: campaignsStatus,
  } = asyncCampaignsData;
  const [slidesData, setSlidesData] = useState([]);
  const [intervalTime, setIntervalTime] = useState(7000);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isCamppaingLoaded, setIsCampaingLoaded] = useState(false);
  const [redeemSlides, setRedeemSlides] = useState([]);
  const [loadingSlider, setLoadingSlider] = useState(true);
  const { data: userInfo } = useUserInfo() || {};
  const user = userInfo?.data;
  const today = new Date().valueOf();

  useEffect(() => {
    if (
      (token || unAuthToken?.status === ACTIONS.STATUS.RESOLVED) &&
      campaignsStatus === ACTIONS.STATUS.IDLE &&
      section === APP_TABS.REDEEM
    ) {
      checkValidToken().then(() => {
        runAsyncCampaignsData(
          fetchCampaigns().then((data) => ({
            campaigns: data,
          }))
        );
      });
    }
  }, [token, unAuthToken, section]);

  useEffect(() => {
    if (
      campaignsData?.campaigns &&
      section === APP_TABS.REDEEM &&
      !isCamppaingLoaded
    ) {
      setLoadingSlider(true);

      const today = new Date();
      const [activeCampaigns] = campaignsData?.campaigns.reduce(
        (result, campaign) => {
          if (campaign.status === 'ACTIVE') {
            if (
              new Date(campaign.startTime) <= today &&
              new Date(campaign.endTime) > today
            ) {
              result[0].push(campaign);
            }
          }
          return result;
        },
        [[]]
      );

      let campaigns = [];
      activeCampaigns?.map(({ drawings, entriesCloseTime, _id }) => {
        let campaign = { endDate: dateString(entriesCloseTime) };
        drawings.map(({ status, prizes }) => {
          if (status === 'PENDING') {
            // const titleDesktop = (prizes?.[0]?.title.length > 43) ? `${prizes?.[0]?.title.substring(0, 43)}...` : prizes?.[0]?.title
            // const titleMobile = (prizes?.[0]?.title.length > 43) ? `${prizes?.[0]?.title.substring(0, 43)}...` : prizes?.[0]?.title

            campaign = {
              ...campaign,
              description: `<p class="p-description">${prizes?.[0]?.title.replace(
                '\\n',
                ''
              )}</p> <span class="d-lg-block d-xl-block carousel-subtitle-sweeps">Use Your Points to Enter for a Chance to Win<br/>100 points per 1 entry</span>`,
              descriptionMobile: `<p style="font-size : 0.8rem">${prizes?.[0]?.title.replace(
                '\\n',
                ''
              )}</p>`,
              image: prizes?.[0]?.images?.[1]?.url,
              isSweepstake: true,
              link: `/enter-sweepstakes/${_id}`,
              module: 'Sweepstakes',
              button: 'Enter Now',
            };
            campaigns.push(campaign);
          }
        });
      });

      if (slidesData.length > 0) {
        let restOfSlides = slidesData.filter((r, k) => k != 0);
        setSlidesData([slidesData[0], ...campaigns, ...restOfSlides]);
        setIsCampaingLoaded(true);

        setRedeemSlides([slidesData[0], ...campaigns, ...restOfSlides]);
        setLoadingSlider(false);
      }
    }
  }, [section, campaignsData]);

  const dailySurpriseSlide = {
    endDate: '',
    icon: 'frozen daily surprise',
    description: '<p>Play Daily for a Chance to Win!</p>',
    descriptionMobile:
      '<p class="daily-carousel-mobile-text mb-1">Play Daily for a Chance to Win<br/><span class="daily-carousel-big-text">FROZEN FOOD<br/>FRENZY!</span></p>',
    imageComingSoon:
      'https://cdn.spinwheel.io/images/daily-surprises/coming_soon.png',
    image:
      'https://cdn.spinwheel.io/images/pricechopper/carousel/daily-surprise-freezer-02-after-coming-soon.png',
    isSweepstake: false,
    module: 'daily surprise',
    button: 'Play Now',
  };

  const dailySurpriseFilter = (banner) => {
    if (banner.module === 'daily surprise') {
      const module = featureFlags.find(
        (flag) => flag.name === FEATURES_FLAGS.ENABLE_DAILY_SURPRISES
      );
      return (
        module?.otherDetails?.campaignActive ||
        module?.otherDetails?.commingSoon
      );
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (section === APP_TABS.REDEEM) {
      // {
      //   endDate: '',
      //   description: '<p>Daily SurPRIZE!</p>',
      //   descriptionMobile: '<p>Daily SurPRIZE!</p>',
      //   image: 'https://cdn.spinwheel.io/images/pricechopper/carousel/daily-surprise.png',
      //   isSweepstake: false,
      //   module: 'daily surprise',
      //   button: 'Play Now',
      //   link: 'earn/daily-surprise'
      // },
      // {
      //   endDate: '',
      //   description: '<p>Redeem your<br/>AdvantEdge Rewards points<br/>for MasterChef Knives!</p>',
      //   descriptionMobile: '<p>Redeem your<br/>AdvantEdge Rewards points<br/>for MasterChef Knives!</p>',
      //   title: ' ',
      //   isSweepstake: false,
      //   module: 'products',
      //   image: 'https://cdn.spinwheel.io/images/pricechopper/carousel/products.png',
      //   button: 'Learn More'
      // },
      let _redeemSlides = [
        dailySurpriseSlide,
        {
          endDate: '',
          description:
            '<p>How AdvantEdge Rewards Works<br/><span>$1 spent*= 1 point earned<span><br/>You choose how to use your points!</p>',
          descriptionMobile: '<p>How AdvantEdge <br/>Rewards Works</p>',
          image:
            'https://cdn.spinwheel.io/images/pricechopper/carousel/rewards.png',
          isSweepstake: false,
          module: 'none',
          button: 'Learn More',
          link: 'https://www.pricechopper.com/rewards/',
          external: true,
          note: '*Restrictions apply. Click on Learn More for details.',
        },
        {
          endDate: '',
          description:
            'Link your AdvantEdge Card<br/>to start redeeming your points<br/>for MasterChef knives!',
          descriptionMobile:
            'Link your AdvantEdge Card<br/>to start redeeming your points<br/>for MasterChef knives!',
          title: ' ',
          isSweepstake: false,
          module: 'ship to home',
          image:
            'https://cdn.spinwheel.io/images/pricechopper/carousel/products.png',
        },
        {
          endDate: '',
          description:
            '<p>Sign up now for our 2024-2025 Tools for Schools program!</p>',
          descriptionMobile: '<p>Sign up to support your school</p>',
          title: ' ',
          isSweepstake: false,
          module: 'tools for schools',
          button: 'Sign Up',
        },
      ]
        .filter((banner) => {
          if (banner.module === 'ship to home') {
            const module = featureFlags.find(
              (flag) => flag.name === FEATURES_FLAGS.ENABLE_COMING_SOON_PRODUCTS
            );
            return module?.isActive;
          } else {
            return true;
          }
        })
        .filter((banner) => {
          if (banner.module === 'tools for schools') {
            const module = featureFlags.find(
              (flag) => flag.name === FEATURES_FLAGS.ENABLE_TOOLS_FOR_SCHOOLS
            );
            return module?.isActive;
          } else {
            return true;
          }
        })
        .filter(dailySurpriseFilter);

      if (isCamppaingLoaded) {
        _redeemSlides = redeemSlides;
      }

      setSlidesData(_redeemSlides);
    } else if (section === APP_TABS.EARN) {
      setLoadingSlider(true);
      const _earnSlides = [
        dailySurpriseSlide,
        {
          endDate: '',
          description:
            '<p>How AdvantEdge Rewards Works<br/><span>$1 spent*= 1 point earned<span><br/>You choose how to use your points!</p>',
          descriptionMobile: '<p>How AdvantEdge <br/>Rewards Works</p>',
          image:
            'https://cdn.spinwheel.io/images/pricechopper/carousel/rewards.png',
          isSweepstake: false,
          module: 'none',
          button: 'Learn More',
          link: 'https://www.pricechopper.com/rewards/',
          external: true,
          note: '*Restrictions apply. Click on Learn More for details.',
        },
        {
          endDate: '',
          description:
            '<p>Answer Monthly Questions<br/>and earn Rewards points!</p>',
          descriptionMobile:
            '<p>Answer Monthly Questions<br/>and earn Rewards points!</p>',
          isSweepstake: false,
          module: 'question of the month',
          button: 'Earn',
          image:
            'https://cdn.spinwheel.io/images/pricechopper/carousel/survey-banner.png',
        },
        {
          endDate: '',
          description: '<p>Buy gift cards and earn Rewards points!</p>',
          descriptionMobile: '<p>Buy gift cards and earn Rewards points!</p>',
          image:
            'https://cdn.spinwheel.io/images/pricechopper/carousel/gift-card.png',
          isSweepstake: false,
          module: 'gift cards',
          external: true,
          link: envInfo?.giftCardsPath,
          button: 'Shop Now',
        },
        // {
        //   endDate: '',
        //   description:
        //     '<p>Earn 10,000 Rewards points<br/>when you refinance your student loans!</p>',
        //   descriptionMobile: '<p>Earn 10,000<br/> Rewards points</p>',
        //   isSweepstake: false,
        //   module: 'refinance student loans',
        //   button: 'Get Started',
        // },
        {
          endDate: '',
          description:
            '<span class="cb-scale"><p><span class="bolder-carousel-text">Support a solar tomorrow.</span><br/>Earn 6,000 Rewards points ($60 value) for helping the planet when you join the<br/> <span class="bolder-carousel-text">Green Mountain Energy Community Solar Program!</span></p><p class="carousel-note-text">Currently Open to NY and MA Residents in National Grid.</p></span>',
          descriptionMobile:
            '<p>Join the Green Mountain Energy<br/> Community Solar Program!</p>',
          isSweepstake: false,
          module: 'community solar',
          labelButton: 'NY Residents',
          button: isDesktop ? 'EARN' : 'NY',
          labelSecondButton: 'MA Residents',
          secondButton: isDesktop ? 'EARN' : 'MA',
          external: true,
          link: envInfo?.communitySolarNYPath,
          secondLink: envInfo?.communitySolarMAPath,
        },
        {
          endDate: '',
          description:
            'Power up with clean energy to earn 10,000<br/>Rewards points ($100 value)',
          descriptionMobile:
            '<p>Power up with Clean Energy to earn 10,000 Rewards points!</p>',
          isSweepstake: false,
          module: 'utility offers',
          button: 'Get Started',
          external: true,
          link: envInfo?.greenMountainPath,
        },

        // {
        //   endDate: '',
        //   description: '<p>Shop Online</p>',
        //   descriptionMobile: '<p>Shop Online</p>',
        //   isSweepstake: false,
        //   module: 'shop online',
        //   link: getPriceChopperShopPath(),
        //   button: 'Shop'
        // },
        // {
        //   endDate: '',
        //   description: '<p style="font-size : 2rem">Shop in Store</p>Look for these tags in store to<br/>pile up the points even faster!',
        //   image: 'https://cdn.spinwheel.io/images/carousel-images/shop-in-store.png',
        //   isSweepstake: false,
        //   module: 'shop in store',
        // }
      ]
        .filter((banner) => {
          if (banner.module === 'utility offers') {
            const module = featureFlags.find(
              (flag) => flag.name === FEATURES_FLAGS.ENABLE_UTILITY_OFFERS
            );
            return module?.isActive;
          } else {
            return true;
          }
        })
        .filter((banner) => {
          if (banner.module === 'community solar') {
            const module = featureFlags.find(
              (flag) => flag.name === FEATURES_FLAGS.ENABLE_COMMUNITY_SOLAR
            );
            const submoduleMA = featureFlags.find(
              (flag) => flag.name === FEATURES_FLAGS.ENABLE_COMMUNITY_SOLAR_MA
            );

            if (!submoduleMA?.isActive) {
              banner.description =
                '<span class="cb-scale"><p><span class="bolder-carousel-text">Support a solar tomorrow.</span><br/>Earn 6,000 Rewards points ($60 value) for helping the planet when you join the<br/> <span class="bolder-carousel-text">Green Mountain Energy Community Solar Program!</span></p><p class="carousel-note-text">Currently Open to NY Residents in National Grid.</p></span>';
              banner.labelSecondButton = '';
              banner.secondButton = '';
              banner.secondLink = '';
            }
            return module?.isActive;
          } else {
            return true;
          }
        })
        .filter(dailySurpriseFilter)
        .filter((banner) => {
          if (banner.module === 'question of the month') {
            const module = featureFlags.find(
              (flag) => flag.name === FEATURES_FLAGS.SURVEY_MODULE
            );
            let status = module?.isActive;
            if (status) {
              status = module?.timeFlag?.find(
                (survey) => survey.startTs <= today && survey.endTs >= today
              );
            }
            return !module?.otherDetails?.campaignEnded || status;
          } else {
            return true;
          }
        });
      setSlidesData(_earnSlides);
      setLoadingSlider(false);
    }

    setActiveIndex(0);
  }, [section, isDesktop]);

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  const handleClick = (k, secondLinkClicked = false) => {
    let { key } = JSON.parse(
      `{"key":"${formatPath(k.module)}","name":"${k.module}"}`
    );

    let finalLink = getCategoryPath(key, mainTab);

    if (k.link && !secondLinkClicked) {
      finalLink = k.link;
    } else if (secondLinkClicked) {
      finalLink = k.secondLink;
    }

    track(EVENTS.BANNER_BUTTON_CLICKED, {
      id: user?._id,
      tab: mainTab,
      module: k.module,
      external: k.external || false,
      link: finalLink,
      viewMode: isDesktop ? 'Desktop' : 'Mobile',
      ui_version: import.meta.env.APP_VERSION,
    });

    switch (mainTab) {
      case APP_TABS.REDEEM: {
        if (k.isSweepstake) {
          navigate(k.link);
        } else if (k.link && k.external) {
          window.open(k.link, '_blank').focus();
        } else {
          if (key === 'daily-surprise') {
            navigate(getCategoryPath(key, 'earn'));
          } else {
            navigate(getLandingPath(key));
          }
        }

        break;
      }
      default: {
        if (k.link) {
          window
            .open(secondLinkClicked ? k.secondLink : k.link, '_blank')
            .focus();
        } else if (key === 'question-of-the-month') {
          navigate(getCategoryPath('survey', mainTab));
        } else {
          navigate(getCategoryPath(key, mainTab));
        }
      }
    }
  };

  // TODO: Inject Styles to replace carousel-banner-btns
  return (
    <Col className="m-auto p-0" lg={'12'}>
      {loadingSlider ? (
        <div className="text-center py-5">
          <Spinner
            as="span"
            animation="border"
            size="lg"
            role="status"
            aria-hidden="true"
            variant="secondary"
            className="align-middle"
          />
        </div>
      ) : (
        <Carousel
          className={`carousel-${isDesktop ? 'desktop' : 'mobile'}`}
          id="carousel-banner"
          onSelect={handleSelect}
          controls={isDesktop}
          indicators={isDesktop}
          activeIndex={activeIndex}
          nextIcon={
            <span aria-hidden="true" className="carousel-control-next-icon" />
          }
          prevIcon={
            <span aria-hidden="true" className="carousel-control-prev-icon" />
          }
        >
          {slidesData?.map((item, index) => {
            return (
              <Carousel.Item
                className="carousel-item-primary"
                interval={intervalTime}
                key={index}
              >
                {item.module === 'daily surprise' ? (
                  <DailySurpriseCarouselItemContent
                    item={item}
                    isDesktop={isDesktop}
                    isLandingPage={isLandingPage}
                    handleClick={handleClick}
                  />
                ) : (
                  <GenericCarouselItemContent
                    item={item}
                    isDesktop={isDesktop}
                    isLandingPage={isLandingPage}
                    handleClick={handleClick}
                  />
                )}
              </Carousel.Item>
            );
          })}
        </Carousel>
      )}
    </Col>
  );
}

export function GenericCarouselItemContent({
  item,
  isDesktop,
  isLandingPage,
  handleClick,
  isDailyGameItem = false,
}) {
  let height = '37';
  let width = '90';
  if (isDesktop && !isDailyGameItem) {
    height = '80';
    width = '150';
  } else if (isDailyGameItem && isDesktop) {
    height = '160';
    width = '300';
  }

  return (
    <>
      <Carousel.Caption>
        {item.icon && isDesktop && (
          <div className="mb-0 flex justify-center">
            <Icon
              icon={item.icon}
              height={height}
              width={width}
              color={styles.white}
            />
          </div>
        )}
        {/* <h2 className={`carousel-title mb-3 ${isDesktop ? '' : 'carousel-title-mobile'}`}>{item.title}</h2> */}
        {typeof item.description === 'object' && item.description ? (
          <>
            {item.description.map((desc, index) => (
              <p
                className={`carousel-description ${
                  isDesktop ? 'mb-3' : 'carousel-description-mobile mb-1'
                }`}
                key={index}
              >
                {desc}
              </p>
            ))}
          </>
        ) : (
          item.description && (
            <span
              className={`carousel-description ${
                isDesktop ? '' : 'carousel-description-mobile'
              }`}
              dangerouslySetInnerHTML={{
                __html: isDesktop ? item.description : item.descriptionMobile,
              }}
            ></span>
          )
        )}
        {/* <p>{item.description}</p> */}
        {/* {item.isSweepstake && (
    <>
      <h4>Ends on {item.endDate}</h4>
      <button>Enter Now</button><br/>
      <a>Sweepstake Official Rules</a>
    </>
  )} */}
        {item.button && !item.secondButton && (
          <Col
            md="12"
            className={`flex justify-center ${isDesktop ? 'mt-3' : ''}`}
          >
            <Button
              onClick={() => handleClick(item)}
              className={'carousel-banner-btns'}

              // style={{ fontSize: (isDesktop) ? '1rem' : '0.8rem' }}
              // className={`btn-button-custom-green btn-rounded slide-btn ${(isDesktop) ? 'lg-link' : ''}`}
            >
              {item.button}
            </Button>
          </Col>
        )}

        {item.button && item.secondButton && (
          <Col md="12" className={` ${isDesktop ? 'mt-3' : ''}`}>
            <Row>
              <Col
                xs="6"
                sm="6"
                md="6"
                lg="6"
                className="text-center flex flex-col items-center"
              >
                {isDesktop && item.labelButton && (
                  <Col md="12" className={`cb-label-buttons !max-h-10 `}>
                    {item.labelButton && item.labelButton}
                  </Col>
                )}
                <Button
                  className={'carousel-banner-btns max-w-min '}
                  onClick={() => handleClick(item)}
                  // style={{ fontSize: (isDesktop) ? '1rem' : '0.8rem' }}
                  // className={`btn-button-custom-green btn-rounded slide-btn w-75 ${(isDesktop) ? 'lg-link' : ''}`}
                >
                  {item.button}
                </Button>
              </Col>
              <Col
                xs="6"
                sm="6"
                md="6"
                lg="6"
                className="text-center flex flex-col items-center"
              >
                {isDesktop && item.labelSecondButton && (
                  <Col md="12" className={`cb-label-buttons !max-h-10 `}>
                    {item.labelSecondButton && item.labelSecondButton}
                  </Col>
                )}
                <Button
                  onClick={() => handleClick(item, true)}
                  className={'carousel-banner-btns max-w-min'}

                  // style={{ fontSize: (isDesktop) ? '1rem' : '0.8rem' }}
                  // className={`btn-button-custom-green btn-rounded slide-btn w-75 ${(isDesktop) ? 'lg-link' : ''}`}
                >
                  {item.secondButton}
                </Button>
              </Col>
            </Row>
          </Col>
        )}

        {item.note && (
          <p className={`carousel-note-text mt-${isDesktop ? 4 : 2}`}>
            {item.note}
          </p>
        )}
      </Carousel.Caption>
      <div className="carousel-image-section">
        {item.image ? (
          <img className="carousel-image" src={item.image} alt={item.title} />
        ) : (
          <div className="banner-module-bg">
            <div
              className={`flex justify-center slide-icon-center-${
                isDesktop ? 'desktop' : 'mobile'
              }`}
            >
              <Icon
                icon={item.module}
                width={isDesktop && isLandingPage ? '260' : '120'}
                height={isDesktop && isLandingPage ? '260' : '120'}
                // stroke={ }
              />
              {item.module === 'community solar' && (
                <div className="carousel-after-icon-community-solar-text">
                  Community Solar
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
