import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  useAsync,
  ACTIONS,
  AuthContext,
  AppContext,
  PartnerContext,
} from '@rewards/contexts';
import { useUserInfo } from '@rewards/data-provider';

import KnivesContext from './KnivesContext';
import { KNIFE_STATUS } from '../../util/constants';
import { getLandingPath } from '../../util/paths';

const { useAuth } = AuthContext;
const { useApp } = AppContext;
const { usePartner } = PartnerContext;
const KnivesContextState = (
  {
    children,
    fetchKnives,
    fetchShippingAddress,
    createShippingAddress,
    fetchPurchasedTxns,
  },
  ...props
) => {
  const navigate = useNavigate();
  const { checkValidToken } = useAuth();
  const { currencyExchanges } = useApp();
  const { partner } = usePartner();

  const { data: userInfo } = useUserInfo();

  const asyncKnives = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { knives: null },
  });
  const asyncPurchasedTxns = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { purchasedTxns: null },
  });
  const asyncAddresses = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { addresses: null },
  });
  const asyncNewShipAddress = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { newShipAddress: null },
  });
  const {
    data: knivesData,
    run: runAsyncKnives,
    status: knivesStatus,
  } = asyncKnives;
  const {
    data: purchasedTxnsData,
    run: runAsyncPurchasedTxns,
    status: purchasedTxnsStatus,
  } = asyncPurchasedTxns;
  const {
    data: addressesData,
    run: runAsyncAddresses,
    status: addressesStatus,
  } = asyncAddresses;
  const {
    data: newShipAddressData,
    run: runAsyncNewShipAddress,
    status: newShipAddressStatus,
  } = asyncNewShipAddress;

  const [balancePoints, setBalancePoints] = useState();
  const [knifeInfo, setKnifeInfo] = useState();
  const [toShippingAddressId, setToShippingAddressId] = useState();
  const [shippingCost, setShippingCost] = useState(0);

  const getKnives = () => {
    checkValidToken().then(() => {
      runAsyncKnives(
        fetchKnives().then((data) => {
          const knivesData = data.reduce((acc, cur) => {
            const { status, startTime, endTime } = cur;
            const currentTime = new Date().getTime();
            if (
              status === KNIFE_STATUS.ACTIVE &&
              currentTime >= startTime &&
              currentTime <= endTime
            ) {
              const currencyExchange = currencyExchanges.find(
                (ce) =>
                  ce.sourceCurrencyId === partner.currencyId &&
                  ce.destCurrencyId === cur?.currencyId
              );
              acc = Object.assign(acc, {
                [cur._id]: Object.assign(cur, {
                  cost: currencyExchange?.sourceDebitMax,
                }),
              });
            }
            return acc;
          }, {});
          return { knives: knivesData };
        })
      );
    });
  };

  useEffect(() => {
    if (partner && currencyExchanges) {
      const { shipToHomeConfig } = partner?.configuration;
      if (!shipToHomeConfig?.isFreeShipping) {
        // DISABLE SHIPING COST
        // setShippingCost(shipToHomeConfig?.shippingCost || 0)
      }

      getKnives();
    }
  }, [partner, currencyExchanges]);

  useEffect(() => {
    if (userInfo?.data) {
      setBalancePoints(userInfo?.data?.partnerAccounts?.[0]?.accountBalance);

      checkValidToken().then(() => {
        runAsyncPurchasedTxns(
          fetchPurchasedTxns(userInfo?.data?._id).then((data) => ({
            purchasedTxns: data,
          }))
        );
      });
    }
  }, [userInfo]);

  const getShippingAddresses = (userId) => {
    return checkValidToken().then(() => {
      return runAsyncAddresses(
        fetchShippingAddress(userId).then((data) => ({ addresses: data }))
      );
    });
  };

  const createNewShippingAddresses = (params) => {
    return checkValidToken().then(() => {
      return runAsyncNewShipAddress(
        createShippingAddress(params).then((data) => ({ newShipAddress: data }))
      );
    });
  };

  const updateKnifeInfo = (knifeInfo = null) => setKnifeInfo(knifeInfo);

  const getKnifeInfo = (id = null) => {
    if (knifeInfo) {
      return knifeInfo;
    } else if (id) {
      const knifeInfo = knivesData?.knives?.[id];
      setKnifeInfo(knifeInfo);
      return knifeInfo;
    } else {
      navigate(getLandingPath());
      return null;
    }
  };

  const updateToShippingAddressId = (id = null) => setToShippingAddressId(id);

  if (knivesStatus !== ACTIONS.STATUS.RESOLVED) {
    return (
      <div className="text-center spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <KnivesContext.Provider
      value={{
        shippingCost,
        balancePoints,
        toShippingAddressId,
        getShippingAddresses,
        knifeInfo,
        getKnifeInfo,
        getKnives,
        updateKnifeInfo,
        createNewShippingAddresses,
        updateToShippingAddressId,
        addresses: { data: addressesData?.addresses, status: addressesStatus },
        newShipAddress: {
          data: newShipAddressData?.newShipAddress,
          status: newShipAddressStatus,
        },
        knives: { data: knivesData?.knives, status: knivesStatus },
        purchasedTxns: {
          data: purchasedTxnsData?.purchasedTxns,
          status: purchasedTxnsStatus,
        },
      }}
      {...props}
    >
      {children}
    </KnivesContext.Provider>
  );
};
export default KnivesContextState;
