//@ts-nocheck

import { Route, Routes } from 'react-router-dom';
import { Landing } from './routes/_index';
import { SurveyEnd } from './routes/_end';
import { Survey } from './routes/_survey';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSurveyResponses } from './api/useSurveyResponses';
import { AuthContext } from '@rewards/contexts';
import { useUserInfo } from '@rewards/data-provider';

export function SurveyModule() {
  const queryClient = useQueryClient();
  const { token } = AuthContext.useAuth();
  const { data: userInfoData } = useUserInfo(!!token);
  const user = userInfoData?.data;
  useEffect(() => {
    queryClient.prefetchQuery(
      useSurveyResponses.getFetchOptions({
        variables: {
          userId: user?._id,
        },
      })
    );
  }, [user]);

  return (
    <Routes>
      <Route index element={<Landing />}></Route>
      <Route path="end" element={<SurveyEnd />}></Route>
      <Route path="survey" component={<Survey />}></Route>
    </Routes>
  );
}

export default SurveyModule;
