import * as Divider from '@radix-ui/react-separator';
import { TermsAndCondition } from '../T&Cs';
import { FAQs } from '../FAQs';

export const InfoCampaign = ({ duration, className }) => (
  <section className={`${className} text-center`}>
    {duration && (
      <p style={{ fontSize: '0.5rem' }} className="m-0">
        Effective 10/06/24 - 12/24/24
      </p>
    )}
    <div className="flex items-center">
      <TermsAndCondition className={className} />
      <Divider.Root
        orientation="vertical"
        style={{
          height: '16px',
          width: '1px',
          background: 'white',
        }}
      />
      <FAQs className={className} />
    </div>
  </section>
);
