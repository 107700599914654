import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { DataProvider } from '@rewards/data-provider';

import { getDailySurprisesPath } from './util/paths';
import './App.scss';
import './styles.css';
import { DailySurpriseLanding } from './routes/_index';
import { Maintenance } from './routes/maintenance';

import { useModule } from './util/hooks/useModule';
import { FEATURES_FLAGS } from './util/constants';
import CampaignEnded from './components/CampaignEnded/CampaignEnded';

const DailyGame = ({ module }) => {
  if (
    module?.otherDetails?.campaignActive &&
    !module?.otherDetails?.deactivate
  ) {
    return <DailySurpriseLanding />;
  }

  if (module?.otherDetails?.deactivate) {
    return <Maintenance />;
  }

  return <Navigate to={`coming-soon`} />;
};

const App = () => {
  const module = useModule(FEATURES_FLAGS.ENABLE_DAILY_SURPRISES);
  return (
    <DataProvider>
      <Routes>
        <Route
          exact
          path={getDailySurprisesPath()}
          element={<DailyGame module={module} />}
        ></Route>
        <Route exact path={`coming-soon`} element={<CampaignEnded />}></Route>
      </Routes>
    </DataProvider>
  );
};

export default App;
