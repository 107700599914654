import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom'
// import { useMsal } from '@azure/msal-react';
import { AppContext, AuthContext, ACTIONS } from '@rewards/contexts';
import { useUserInfo, useMoneyTransferTxns } from '@rewards/data-provider';

import { CampaignContext } from '../../contexts';
import Icon from '../../assets/svg';
import { dateString } from '../../util/formatters';
import { ConfirmationShareModal } from '@rewards/daily-surprises';

import { EVENTS, EVENT_LOCATION } from '../../analytics/events';

import useResponsive from '../../util/hooks/useResponsive';
const { AppContext: AppContexts } = AppContext;
const { useAuth } = AuthContext;
import './DailySurpriseConfirmation.scss';

const ConfirmationDetail = ({ isDesktop, entriesBought, name }) => {
  return <></>;
};

const ConfirmationBody = ({ entriesBought, totalEntries, isDesktop, name }) => {
  return (
    <>
      <div className="mb-1 p-0 w-100">
        <div
          className={`mb-1 p-0 w-75 text-left header-text ${
            isDesktop ? 'mx-auto' : 'px-2'
          }`}
        >
          👋 Prize Awarded!
        </div>
      </div>
      <div className="confirm-details-text pt-1 px-2 text-center w-100">
        You Won ({entriesBought}) Entr{entriesBought > 1 ? 'ies' : 'y'} into the
      </div>
      <div className="confirm-details-text p-0 px-2 text-center w-100">
        {name}
      </div>
      <div
        className={`flex flex-row justify-content-between header-badge rounded mt-3 mb-5 ${
          isDesktop ? 'w-75 mx-auto' : 'mx-2'
        }`}
      >
        <div>Entr{entriesBought > 1 ? 'ies' : 'y'} Earned:</div>
        <div>
          {/* <Icon icon='bolt' width='15' height='21' /> */}
          <span className="header-points-text">
            ({entriesBought}) {entriesBought > 1 ? 'Entries' : 'Entry'}
          </span>
        </div>
      </div>
      <Row>
        <Col className="flex flex-column text-center ml-4 pl-4 pr-0">
          <span className="new-entry-text mb-4">
            <span>New </span>
            <span>Entr{entriesBought > 1 ? 'ies' : 'y'} </span>{' '}
          </span>
          <div className="img-bg">
            <span>{entriesBought}</span>
          </div>
        </Col>
        <Col className="flex flex-column text-center mr-4 pl-0 pr-4">
          <span className="total-entry-text mb-4">
            <span>Total </span>
            <span>Entr{totalEntries > 1 ? 'ies' : 'y'} </span>{' '}
          </span>
          <div className="img-bg-total">
            <span>{totalEntries}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="flex flex-column text-center">
          <div className="blue-text-confirm-modal">
            Try again tomorrow for another <br />
            chance to win!
          </div>
        </Col>
      </Row>
    </>
  );
};

const ConfirmationFooter = ({
  isDesktop,
  intermediateInfo,
  processTxn,
  sharePrice,
  txnSuccess,
}) => (
  <>
    {intermediateInfo.length > 0 && (
      <Col
        className={`footer-weekly-drawing my-3 rounded px-2 ${
          isDesktop ? 'w-75 mx-auto' : 'mx-0 '
        }`}
      >
        <div className="flex flex-row">
          <div>
            <Icon icon="calender-circle" width="29" height="29" />
          </div>
          <div className="flex flex-column ml-2">
            <div className="mb-1 confirm-modal-title">
              When the next weekly drawing is
            </div>
            <div className="title-text text-left">
              {dateString(intermediateInfo[0]?.selectionTime)}
            </div>
          </div>
        </div>
      </Col>
    )}

    <div className="sweepstake-redeem-options text-center w-100 m-0">
      <Button
        onClick={processTxn}
        // className={`experiences_home_button_custom`}
      >
        HOME
      </Button>

      <Button
        onClick={sharePrice}
        // className={`experiences_share_button_custom`}
      >
        SHARE
      </Button>
    </div>
  </>
);

const DailySurpriseConfirmation = (props) => {
  // const { instance } = useMsal();
  const { /*tokenStatus, unAuthToken,*/ checkValidToken } = useAuth();
  // const history = useNavigate()
  const { isDailySurprise, dailySurpriseEvent } = props;
  const [show, setShow] = useState(false);
  const [entriesBought, setEntrires] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [intermediateInfo, setIntermediateInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isDesktop } = useResponsive();
  const [showSocialShare, setShowSocialShare] = useState(false);
  const sharePrice = () => {
    track(EVENTS.DAILY_SURPRISES_SHARE_BUTTON_CLICKED, {
      location: EVENT_LOCATION.SWEEPSTAKES_PRIZE_MODAL_BOX,
      ui_version: import.meta.env.APP_VERSION,
    });
    setShowSocialShare(true);
  };

  const { data: userInfo, isFetching: isUserInfoFetching } = useUserInfo();
  const { data: user } = userInfo || {};
  const { status: campaignTxnStatus, mutate: handleMoneyTransferTxns } =
    useMoneyTransferTxns();

  const { track, landingPath } = useContext(AppContexts);
  const { checkUserSession } = useContext(AuthContext.AuthContext);
  const {
    resetValue,
    currencyExchange,
    userSweepStakeCampaign,
    // pendingInfo,
    advantPoints,
    // setTooltip,
    setTxnErrorToolTip,
    setTxnErrorTooltipMessage,
    activeCampaign,
    // isActiveCampaign,
    // isEntriesClosed,
    // entriesHoldData,
  } = useContext(CampaignContext);

  const campaignTransx = (params) => {
    return checkValidToken().then(() => {
      return handleMoneyTransferTxns(params);
    });
  };

  useEffect(() => {
    if (isDailySurprise) {
      setIsLoading(true);
      setShow(true);
      // console.log('is daily surprise ===> ', dailySurpriseEvent)
      // setAdvantPoints(dailySurpriseEvent.points)
    }
  }, [isDailySurprise]);

  useEffect(() => {
    if (isLoading) {
      checkUserSession().then(() => {
        // const { _id, partnerAccounts } = user

        const entries = dailySurpriseEvent.ammount;
        let totalEntries = 0;
        let account = {};
        if (Object.keys(userSweepStakeCampaign).length === 0) {
          account = { subAccountId: activeCampaign?._id };
        } else {
          account = { toAccountId: userSweepStakeCampaign?.accountId };
          totalEntries = userSweepStakeCampaign.accountBalance;
        }

        setEntrires(parseInt(entries));
        setTotalEntries(parseInt(entries) + parseInt(totalEntries));
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (
      campaignTxnStatus === ACTIONS.QUERY_STATUS.ERROR &&
      !isUserInfoFetching
    ) {
      user?.partnerAccounts[0]?.accountBalance < advantPoints &&
        setTxnErrorTooltipMessage(ACTIONS.TXN_ERROR.POINTS);
      setTxnErrorToolTip(true);
      setIsLoading(false);
    }
  }, [isUserInfoFetching, campaignTxnStatus]);

  const processTxn = () => {
    const { _id /*partnerAccounts*/ } = user;
    const entries = advantPoints / currencyExchange?.sourceDebitIncrements;
    let totalEntries = 0;
    let account = {};
    if (Object.keys(userSweepStakeCampaign).length === 0) {
      account = { subAccountId: activeCampaign?._id };
    } else {
      account = { toAccountId: userSweepStakeCampaign?.accountId };
      totalEntries = userSweepStakeCampaign.accountBalance;
    }

    const params = {
      fromAccount: {
        fromUserId: _id,
        // fromAccountId: partnerAccounts[0]?.accountId, //wallet id
        fromAccountValue: advantPoints,
        fromCurrencyId: props.voucherCode, // voucher code
        fromAccountType: 'DAILY_SURPRISES',
      },
      toAccount: {
        toUserId: _id,
        ...account,
        toAccountValue: entries,
        toAccountType: 'SWEEPSTAKE',
      },
    };
    setEntrires(parseInt(entries));
    setTotalEntries(parseInt(entries) + parseInt(totalEntries));

    campaignTransx(params).then(() => {
      track(EVENTS.SWEEPSTAKES_BOUGHT, {
        id: activeCampaign?._id,
        name: activeCampaign?.name,
        points_selected: advantPoints,
        is_daily_surprise: isDailySurprise,
        location: props?.handleCloseMainModal
          ? EVENT_LOCATION.SWEEPSTAKES_PRIZE_MODAL_BOX
          : EVENT_LOCATION.SWEEPSTAKES_LANDING_PAGE,
        ui_version: import.meta.env.APP_VERSION,
      });

      // social share
      setIsLoading(false);
      // setShowSocialShare(true)
    });
  };

  const handleClose = () => {
    track(EVENTS.DAILY_SURPRISES_CLOSE_BUTTON_CLICKED, {
      location: EVENT_LOCATION.SWEEPSTAKES_PRIZE_MODAL_BOX,
      ui_version: import.meta.env.APP_VERSION,
    });

    setShow(false);
    resetValue();
    props.redeemed();
  };

  const done = () => {
    handleClose();
  };

  // useEffect(() => {
  //   if (copiedTooltip) {
  //     const timer = setTimeout(() => {
  //       setCopiedTooltip(false)
  //       handleClose()
  //     }, 3000)
  //     return () => clearTimeout(timer)
  //   }
  // }, [copiedTooltip])

  useEffect(() => {
    if (show) {
      processTxn();
    }
  }, [show]);

  return (
    <ConfirmationShareModal
      show={show}
      isDesktop={isDesktop}
      handleClose={handleClose}
      detail={
        <ConfirmationDetail
          isDesktop={isDesktop}
          entriesBought={entriesBought}
          name={activeCampaign?.name}
        />
      }
      body={
        <ConfirmationBody
          entriesBought={entriesBought}
          totalEntries={totalEntries}
          isDesktop={isDesktop}
          name={activeCampaign?.name}
        />
      }
      footer={
        <ConfirmationFooter
          isDesktop={isDesktop}
          processTxn={done}
          sharePrice={sharePrice}
          txnSuccess={campaignTxnStatus}
          intermediateInfo={intermediateInfo}
        />
      }
      showSocialShare={showSocialShare}
    />
  );
};

export default DailySurpriseConfirmation;
