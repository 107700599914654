import React from 'react';

import App from './App';
import Icon from './assets/svg';

const register = () => {
  return {
    parent: 'earn',
    label: 'Utility Offers',
    havePath: true,
    path: 'https://www.greenmountainenergy.com/market32',
    navPathInternal: true,
    icon: <Icon icon="utility offers" width="130" height="130" />,
    content: function Content() {
      return <App />;
    },
  };
};

export default { register };
export { App as UtilityOffersModule };
