import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { WithErrorBoundary } from '@rewards/error-handler';
import { Provider as TooltipProvider } from '@radix-ui/react-tooltip';
import {
  AuthContext,
  AppContext,
  PartnerContext,
  track,
} from '@rewards/contexts';
import { DailySurprisesContextState } from '@rewards/daily-surprises';
import { DataProvider } from '@rewards/data-provider';

import {
  fetchRegistry,
  fetchMobileSideNav,
  fetchMobileSideNavSecure,
  fetchOfferCampaigns,
  fetchOfferCampaignsIsUserValid,
  fetchOfferCampaignsIssueRewards,
  fetchUserInfoViaCardNumber,
  fetchUserInfoViaEmail,
} from './services/componentRegistryService';
import { LoyaltyConnectContextState } from './contexts/LoyaltyConnectContext';
import config from './config';
import { getLandingPath } from './util/paths';
import { EVENTS } from './analytics/events';

import { Toast, UnAuthenticatedUserModal } from '@rewards/ui';

import './App.scss';
import './index.css';

// import { ToastProvider } from '../../../libs/ui/src/contexts/ToastContext';
import {
  Provider as ToastProvider,
  Viewport as ToastViewport,
} from '@radix-ui/react-toast';
import { AppRoutes } from './routes';

const App = ({ setErrorHandler, setUserDetails }) => {
  let location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <DataProvider>
      <AuthContext.AuthContextState
        location={location}
        envInfo={config.accountInfo}
        ssoMigrated={import.meta.env.SSO_MIGRATED}
      >
        <AppContext.AppContextState
          envInfo={config.accountInfo}
          landingPath={getLandingPath()}
          setErrorHandler={setErrorHandler}
        >
          <PartnerContext.PartnerContextState>
            <LoyaltyConnectContextState
              fetchMobileSideNav={fetchMobileSideNav}
              fetchMobileSideNavSecure={fetchMobileSideNavSecure}
              fetchRegistry={fetchRegistry}
              fetchOfferCampaigns={fetchOfferCampaigns}
              fetchOfferCampaignsIsUserValid={fetchOfferCampaignsIsUserValid}
              fetchOfferCampaignsIssueRewards={fetchOfferCampaignsIssueRewards}
              fetchUserInfoViaCardNumber={fetchUserInfoViaCardNumber}
              fetchUserInfoViaEmail={fetchUserInfoViaEmail}
              setUserDetails={setUserDetails}
            >
              <DailySurprisesContextState>
                <TooltipProvider>
                  <ToastProvider>
                    <AppRoutes />

                    <Toast.IntegratedToast />
                    <ToastViewport className="ToastViewport" />
                  </ToastProvider>
                </TooltipProvider>
              </DailySurprisesContextState>
            </LoyaltyConnectContextState>
          </PartnerContext.PartnerContextState>
        </AppContext.AppContextState>
        <UnAuthenticatedUserModal />
      </AuthContext.AuthContextState>
    </DataProvider>
  );
};

const AppWithErrorBoundary = (props) => {
  const [errorHandler, setErrorHandler] = useState();
  // console.log(props);
  return (
    <WithErrorBoundary
      appName={config.accountInfo.clientId}
      errorHandler={errorHandler}
      segment={{ track, EVENT: EVENTS.LOYALTY_CONNECT_UI_ERROR }}
    >
      <App {...props} setErrorHandler={setErrorHandler} />
    </WithErrorBoundary>
  );
};

export default AppWithErrorBoundary;
