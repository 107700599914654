import React, { useEffect, useContext, useState, useCallback } from 'react';
import { Container, Col, Row, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ACTIONS, AppContext } from '@rewards/contexts';
import { useUserInfo } from '@rewards/data-provider';

import BackNav from '../../../BackNav';
import KnifeConfirmation from '../../../KnifeConfirmation';

import { useResponsive, useKnives } from '../../../../util/hooks';
import { getKnifeInfoPath, getUserAddressPath } from '../../../../util/paths';
import { KNIFE_INFO } from '../../../../util/constants';
import { KnivesContext } from '../../../../contexts/KnivesContext';
import {
  validateEmail,
  debounce,
  validateMobileNumber,
} from '../../../../util/Optimizers';
import { EVENTS, PAGES, EVENT_LOCATION } from '../../../../analytics/events';

import './AddressForm.scss';

const FORM_FIELDS = {
  FULL_NAME: 'fullName',
  STREET: 'street',
  CITY: 'city',
  STATE: 'state',
  ZIP_CODE: 'zipCode',
  EMAIL: 'email',
  PHONE: 'phone',
};

const AddressForm = () => {
  const { source, track } = useContext(AppContext.AppContext);
  const {
    getShippingAddresses,
    createNewShippingAddresses,
    updateToShippingAddressId,
  } = useContext(KnivesContext);

  const { data: userInfo } = useUserInfo();
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  const { addresses, newShipAddress } = useKnives();

  const [addressState, setAddressState] = useState({
    [FORM_FIELDS.FULL_NAME]: '',
    [FORM_FIELDS.STREET]: '',
    [FORM_FIELDS.CITY]: '',
    [FORM_FIELDS.STATE]: '',
    [FORM_FIELDS.ZIP_CODE]: '',
    [FORM_FIELDS.EMAIL]: '',
    [FORM_FIELDS.PHONE]: '',
  });
  const [addressErrorState, setAddressErrorState] = useState({
    [FORM_FIELDS.FULL_NAME]: false,
    [FORM_FIELDS.STREET]: false,
    [FORM_FIELDS.CITY]: false,
    [FORM_FIELDS.STATE]: false,
    [FORM_FIELDS.ZIP_CODE]: false,
    [FORM_FIELDS.EMAIL]: false,
    [FORM_FIELDS.PHONE]: false,
  });
  const [shouldCallNext, setShouldCallNext] = useState(false);

  useEffect(() => {
    AddressForm.showBackBtn = true;
  }, []);

  useEffect(() => {
    if (addresses?.status === ACTIONS.STATUS.RESOLVED) {
      source(PAGES.VIEWED_SHIPPING_INFO_PAGE, {
        ui_version: import.meta.env.APP_VERSION,
      });
      AddressForm.LOCATION = EVENT_LOCATION.SHIPPING_INFO_PAGE;
      if (addresses?.data?.length) {
        AddressForm.landingPath = getUserAddressPath();
      } else {
        const knifeId = localStorage.getItem(KNIFE_INFO);
        knifeId && (AddressForm.landingPath = getKnifeInfoPath(knifeId));
      }
    } else if (addresses?.status === ACTIONS.STATUS.IDLE) {
      navigate(getUserAddressPath());
    }
  }, [addresses?.status]);

  const handleNextAddress = () => {
    track(EVENTS.SHIPPING_NEXT_BUTTON_CLICKED, {
      ...addressState,
      ui_version: import.meta.env.APP_VERSION,
    });
    let isCorrectAddress = true;
    Object.entries(addressState).forEach(([key, value]) => {
      if (!value?.length) {
        isCorrectAddress = false;
        setAddressErrorState((prevState) => ({ ...prevState, [key]: true }));
      }
    });

    if (isCorrectAddress) {
      createNewShippingAddresses({
        userId: userInfo?.data?._id,
        ...addressState,
      }).then(() => {
        setShouldCallNext(true);
        getShippingAddresses(userInfo?.data?._id);
      });
    }
  };

  const debouncedValidateFormField = useCallback(
    debounce((nextValue, name) => {
      let isValid = false;
      switch (name) {
        case FORM_FIELDS.EMAIL: {
          isValid = validateEmail(nextValue);
          break;
        }
        case FORM_FIELDS.PHONE: {
          isValid = validateMobileNumber(nextValue);
          break;
        }
        default: {
          isValid = nextValue?.length;
        }
      }
      setAddressErrorState((prevState) => ({ ...prevState, [name]: !isValid }));
      setAddressState((prevState) => ({ ...prevState, [name]: nextValue }));
    }, 0),
    [] // will be created only once initially
  );

  const handleChange = (event) => {
    const { value: nextValue, name } = event.target;
    debouncedValidateFormField(nextValue, name);
  };

  useEffect(() => {
    if (newShipAddress?.status === ACTIONS.STATUS.RESOLVED && shouldCallNext) {
      updateToShippingAddressId(newShipAddress?.data?._id);
    }
  }, [shouldCallNext, newShipAddress?.status]);

  return (
    <Container className={`font-face-owd text-center ${!isDesktop && 'py-3'}`}>
      <Col className="lc-knife-address-title my-4">
        Enter your contact information and shipping address
      </Col>
      {isDesktop && <Row className="border-bottom my-4" />}
      <Form onChange={handleChange} autoComplete="off">
        <Form.Row>
          <Col lg={6} className="p-0 px-1">
            <InputGroup hasValidation>
              <Form.Control
                placeholder="Full Name"
                autoComplete="fullName"
                name={FORM_FIELDS.FULL_NAME}
                className={`my-1 lc-knife-address-input ${
                  !isDesktop && 'border-0'
                }`}
                isInvalid={addressErrorState?.[FORM_FIELDS.FULL_NAME]}
              />
              <Form.Control.Feedback type="invalid" className="text-left mb-3">
                Full name is required field.
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
          <Col lg={6} className="p-0 px-1">
            <InputGroup hasValidation>
              <Form.Control
                autoComplete="Street"
                placeholder="Street"
                name={FORM_FIELDS.STREET}
                className={`my-1 lc-knife-address-input ${
                  !isDesktop && 'border-0'
                }`}
                isInvalid={addressErrorState?.[FORM_FIELDS.STREET]}
              />
              <Form.Control.Feedback type="invalid" className="text-left mb-3">
                Street is required field.
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col lg={6} className="p-0 px-1">
            <InputGroup hasValidation>
              <Form.Control
                autoComplete="City"
                isInvalid={addressErrorState?.[FORM_FIELDS.CITY]}
                placeholder="City"
                name={FORM_FIELDS.CITY}
                className={`my-1 lc-knife-address-input ${
                  !isDesktop && 'border-0'
                }`}
              />
              <Form.Control.Feedback type="invalid" className="text-left mb-3">
                City is required field.
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
          <Col lg={6} className="p-0 px-1">
            <InputGroup hasValidation>
              <Form.Control
                autoComplete="State"
                isInvalid={addressErrorState?.[FORM_FIELDS.STATE]}
                placeholder="State"
                name={FORM_FIELDS.STATE}
                className={`my-1 lc-knife-address-input ${
                  !isDesktop && 'border-0'
                }`}
              />
              <Form.Control.Feedback type="invalid" className="text-left mb-3">
                State is required field.
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col lg={6} className="p-0 px-1">
            <InputGroup hasValidation>
              <Form.Control
                autoComplete="zipCode"
                isInvalid={addressErrorState?.[FORM_FIELDS.ZIP_CODE]}
                placeholder="Zip Code"
                name={FORM_FIELDS.ZIP_CODE}
                type="number"
                className={`my-1 lc-knife-address-input ${
                  !isDesktop && 'border-0'
                }`}
              />
              <Form.Control.Feedback type="invalid" className="text-left mb-3">
                Zip code is required field.
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
          <Col lg={6} className="p-0 px-1">
            <InputGroup hasValidation>
              <Form.Control
                autoComplete="newmail"
                isInvalid={addressErrorState?.[FORM_FIELDS.EMAIL]}
                placeholder="E-mail"
                name={FORM_FIELDS.EMAIL}
                type="email"
                className={`my-1 lc-knife-address-input ${
                  !isDesktop && 'border-0'
                }`}
              />
              <Form.Control.Feedback type="invalid" className="text-left mb-3">
                {addressState?.[FORM_FIELDS.EMAIL]?.length
                  ? 'Please enter valid email.'
                  : 'Email is required field.'}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col lg={6} className="p-0 px-1" />
          <Col lg={6} className="p-0 px-1">
            <InputGroup hasValidation>
              <Form.Control
                autoComplete="newphone"
                isInvalid={addressErrorState?.[FORM_FIELDS.PHONE]}
                placeholder="Phone"
                type="tel"
                name={FORM_FIELDS.PHONE}
                className={`my-1 lc-knife-address-input ${
                  !isDesktop && 'border-0'
                }`}
              />
              <Form.Control.Feedback type="invalid" className="text-left mb-3">
                {addressState?.[FORM_FIELDS.PHONE]?.length
                  ? 'Please enter 10-digit mobile number'
                  : 'Phone is required field.'}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Form.Row>
      </Form>

      <Col className="lc-knife-address-info py-3">
        Price Chopper / Market 32 do not sell or disclose personal information
        collected by our site to third parties. Your information will be used
        for shipping purposes only.
      </Col>
      {!isDesktop && <Col className="py-5"></Col>}
      <Col
        lg={9}
        className={
          isDesktop
            ? 'p-0 mt-5 mx-auto'
            : 'lc-knife-address-bottom fixed-bottom bg-white p-3'
        }
      >
        <KnifeConfirmation
          handleNextAddress={handleNextAddress}
          shouldCallNext={shouldCallNext}
          isDisabled={newShipAddress?.status === ACTIONS.STATUS.PENDING}
        />
      </Col>
    </Container>
  );
};

export default BackNav(AddressForm);
