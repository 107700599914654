import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button, TransactionPopup, Modal } from '@rewards/ui';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { AppContext, AuthContext, ACTIONS } from '@rewards/contexts';
import { useUserInfo, useMoneyTransferTxns } from '@rewards/data-provider';

import { CampaignContext } from '../../contexts';
import Icon from '../../assets/svg';
import { dateString } from '../../util/formatters';
import { getRedirectingPath } from '../../util/paths';
import { EVENTS, EVENT_LOCATION, ClickedEvents } from '../../analytics/events';

import useResponsive from '../../util/hooks/useResponsive';
const { AppContext: AppContexts } = AppContext;
const { useAuth } = AuthContext;
import './ConfirmModal.scss';
import styleConst from '../../util/styleConsts';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

function GetTitle(props) {
  return (
    <div className="text-left">
      <Icon
        icon={props.faIcon}
        width={props?.width || '17'}
        height={props.height || '17'}
      />
      <span className="nav-item-text">{props.title}</span>
      <span className="float-right">
        <Icon
          icon="angle-right"
          width="7"
          height="13"
          className="float-right"
        />
      </span>
    </div>
  );
}

const ConfirmModal = (props) => {
  const { instance } = useMsal();
  const { tokenStatus, unAuthToken, checkValidToken } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: sweepstakeId } = useParams();
  // console.log('🚀 ~ ConfirmModal ~ sweepstakeId:', sweepstakeId);

  const [show, setShow] = useState(false);
  const [entriesBought, setEntrires] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [intermediateInfo, setIntermediateInfo] = useState([]);
  const [finalInfo, setFinalInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isDesktop } = useResponsive();

  const { data: userInfo, isFetching: isUserInfoFetching } = useUserInfo();
  const { data: user } = userInfo || {};
  const {
    status: campaignTxnStatus,
    data: transaction,
    isSuccess,
    isError,
    error: transactionError,
    mutate: handleMoneyTransferTxns,
  } = useMoneyTransferTxns();

  const { track, landingPath } = useContext(AppContexts);
  const { checkUserSession } = useContext(AuthContext.AuthContext);
  const {
    resetValue,
    advantPoints,
    currencyExchange,
    userSweepStakeCampaign,
    pendingInfo,
    setTooltip,
    setTxnErrorToolTip,
    setTxnErrorTooltipMessage,
    activeCampaign,
    isActiveCampaign,
    isEntriesClosed,
    entriesHoldData,
  } = useContext(CampaignContext);

  const campaignTransx = (params) => {
    return checkValidToken().then(() => {
      return handleMoneyTransferTxns(params, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['sweepstake-entries', sweepstakeId],
          });
        },
      });
    });
  };

  useEffect(() => {
    if (isLoading) {
      checkUserSession().then(() => {
        const { _id, partnerAccounts } = user;

        const entries = advantPoints / currencyExchange.sourceDebitIncrements;
        let totalEntries = 0;
        let account = {};
        if (Object.keys(userSweepStakeCampaign).length === 0) {
          account = { subAccountId: activeCampaign?._id };
        } else {
          account = { toAccountId: userSweepStakeCampaign?.accountId };
          totalEntries = userSweepStakeCampaign.accountBalance;
        }

        const params = {
          fromAccount: {
            fromUserId: _id,
            fromAccountId: partnerAccounts[0]?.accountId,
            fromAccountValue: advantPoints,
            fromCurrencyId: currencyExchange?.sourceCurrencyId,
            fromAccountType: 'PARTNER',
          },
          toAccount: {
            toUserId: _id,
            ...account,
            toAccountValue: entries,
            toAccountType: 'SWEEPSTAKE',
          },
        };
        setEntrires(entries);
        setTotalEntries(entries + totalEntries);

        campaignTransx(params).then(() => {
          track(EVENTS.SWEEPSTAKES_BOUGHT, {
            id: activeCampaign?._id,
            name: activeCampaign?.name,
            points_selected: advantPoints,
            location: props?.handleCloseMainModal
              ? EVENT_LOCATION.SWEEPSTAKES_PRIZE_MODAL_BOX
              : EVENT_LOCATION.SWEEPSTAKES_LANDING_PAGE,
            ui_version: import.meta.env.APP_VERSION,
          });
        });
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (
      campaignTxnStatus === ACTIONS.QUERY_STATUS.ERROR &&
      !isUserInfoFetching
    ) {
      user?.partnerAccounts[0]?.accountBalance < advantPoints &&
        setTxnErrorTooltipMessage(ACTIONS.TXN_ERROR.POINTS);
      setTxnErrorToolTip(true);
      setIsLoading(false);
    }
  }, [isUserInfoFetching, campaignTxnStatus]);

  useEffect(() => {
    if (campaignTxnStatus === ACTIONS.QUERY_STATUS.SUCCESS) {
      setShow(true);
      setIsLoading(false);
    }
  }, [campaignTxnStatus]);

  useEffect(() => {
    const [intermediate, final] = pendingInfo.reduce(
      (result, info) => {
        result[info.type === 'INTERMEDIATE' ? 0 : 1].push(info);
        return result;
      },
      [[], []]
    );

    const intermediateInfo = intermediate.sort(
      (a, b) => a.selectionTime - b.selectionTime
    );
    const finalInfo = final.sort((a, b) => a.selectionTime - b.selectionTime);
    setIntermediateInfo(intermediateInfo);
    setFinalInfo(finalInfo);
  }, [pendingInfo]);

  const handleClose = () => {
    track(EVENTS.SWEEPSTAKES_DONE_CLICKED, {
      id: activeCampaign?._id,
      name: activeCampaign?.name,
      location: props?.handleCloseMainModal
        ? EVENT_LOCATION.SWEEPSTAKES_PRIZE_MODAL_BOX
        : EVENT_LOCATION.SWEEPSTAKES_LANDING_PAGE,
      ui_version: import.meta.env.APP_VERSION,
    });
    setShow(false);
    resetValue();

    props.handleCloseMainModal && props.handleCloseMainModal();
    if (!isDesktop) {
      navigate(landingPath);
    }
  };
  const enterToSweepstake = async () => {
    track(EVENTS.SWEEPSTAKES_ENTER_NOW_CLICKED, {
      id: activeCampaign?._id,
      name: activeCampaign?.name,
      location: props?.handleCloseMainModal
        ? EVENT_LOCATION.SWEEPSTAKES_PRIZE_MODAL_BOX
        : EVENT_LOCATION.SWEEPSTAKES_LANDING_PAGE,
      points_selected: advantPoints,
      ui_version: import.meta.env.APP_VERSION,
    });
    if (advantPoints === 0) {
      props.handleCloseMainModal && (await props.handleCloseMainModal());
      const scrollTopEle = document.getElementById(
        isDesktop ? 'totalPoints' : 'scrollToPosition'
      );
      document.documentElement.scrollTop = scrollTopEle?.offsetTop;
      setTooltip(true);
    } else {
      setIsLoading(true);
    }
  };

  const handleSelectLink = (navItem = '') => {
    track(ClickedEvents(navItem), {
      location: EVENT_LOCATION.SWEEPSTAKES_CONFIRM_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    navigate(getRedirectingPath(navItem));
  };

  const handleLoginRegister = () => {
    track(EVENTS.LOG_IN_REGISTER_CLICKED, {
      location: EVENT_LOCATION.SWEEPSTAKES_CONFIRM_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    instance.config.auth.authority = instance.config.auth.loginAuthority;
    instance.loginRedirect({
      ...instance.config.auth.loginRequest,
      extraQueryParameters: {
        anonymousId: window?.analytics?.user?.()?.anonymousId?.(),
      },
    });
  };

  return (
    <>
      {tokenStatus === ACTIONS.STATUS.RESOLVED && (
        <Button
          // className='sweep-get-started-button pl-4 pr-4 h-100 border-top-0'
          disabled={
            isLoading || !isActiveCampaign || isEntriesClosed || entriesHoldData
          }
          onClick={!isLoading ? enterToSweepstake : null}
        >
          {isLoading ? 'Loading…' : 'Enter Now'}
        </Button>
      )}
      {unAuthToken?.status === ACTIONS.STATUS.RESOLVED && (
        <Button
          // className='sweep-get-started-button pl-4 pr-4 h-100 border-top-0'
          onClick={handleLoginRegister}
        >
          Log In / Register
        </Button>
      )}

      <TransactionPopup.Root
        isSuccess={isSuccess}
        isError={isError}
        transaction={transaction || transactionError}
        points={advantPoints}
        user={user}
        open={show}
        onClose={handleClose}
      >
        <TransactionPopup.Body className="py-0">
          <Col
            className={`p-0 justify-content-around my-3 ${
              isDesktop && 'w-75 mx-auto'
            }`}
          >
            <Row>
              <Col className="text-center entries-bought">
                <span>Entr{entriesBought > 1 ? 'ies' : 'y'}:</span>{' '}
                <span className="text-green">{entriesBought}</span>{' '}
                {/* <Icon icon='sweepstake' width='29' height='29' color={styleConst.appleGreen} /> */}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="confirmation-ad-box p-4 rounded text-center mt-4">
                  {activeCampaign?.name}
                </div>
              </Col>
            </Row>
          </Col>
        </TransactionPopup.Body>
        <TransactionPopup.Footer className="flex flex-column">
          <Modal.CloseButtonWrapper asChild>
            <Button onClick={handleClose}>Done</Button>
          </Modal.CloseButtonWrapper>
        </TransactionPopup.Footer>
      </TransactionPopup.Root>
    </>
  );
};

export default ConfirmModal;
