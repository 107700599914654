import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import CharitableCauses from './components/chartitableCauses/CharitableCauses';
import CharityList from './components/CharityList/CharityList';
import { getCharitiesPath, getCharityPath } from './util/paths.js';

// import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import './index.css';

import CommunityContextState from './contexts/CommunityContextState';

function App() {
  useEffect(() => {
    document.body.classList.add('community-app');
    return () => {
      document.body.classList.remove('community-app');
    };
  }, []);

  return (
    <CommunityContextState>
      <Routes>
        <Route exact path={getCharitiesPath()} element={<CharityList />} />
        <Route
          path={getCharityPath(':id')}
          exact
          element={<CharitableCauses />}
        />

        <Route path="*" render={() => <Navigate to={getCharitiesPath()} />} />
      </Routes>
    </CommunityContextState>
  );
}

export default App;
