import React, { useState, useContext, useEffect } from 'react';
import { Col, Modal, Table } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { AuthContext, AppContext, PartnerContext } from '@rewards/contexts';

import { CampaignContext } from '../../contexts';
import { EVENTS, EVENT_LOCATION, PAGES } from '../../analytics/events';
import { getSweepstakesPath } from '../../util/paths';
import { useCampaigns } from '../../util/hooks';
import {
  dateString,
  formatDate,
  getNewYorkDateString,
  numberWithCommas,
  numToWords,
} from '../../util/formatters';
import { WINNER_SELECTION_INFO_TYPE } from '../../util/constants';
import { useUserInfo } from '@rewards/data-provider';

import './SweepstakeOfficialRules.scss';
import { Link, useLocation } from 'react-router-dom';

const { usePartner } = PartnerContext;
const TermsAndConditons = () => {
  const [show, setShow] = useState(false);
  const [weeklyPrizeDateStrings, setWeeklyPrizeDateStrings] = useState();
  const [grandPrizeDrawings, setGrandPrizeDrawings] = useState([]);
  const [grandSum, setGrandSum] = useState(0);
  const { partner } = usePartner();
  const { envInfo } = useContext(AuthContext.AuthContext);
  const { track, source } = useContext(AppContext.AppContext);
  const { activeCampaign } = useContext(CampaignContext);

  const { officialDocInfo } = useCampaigns();

  const { data: userInfo } = useUserInfo();
  const { data: user } = userInfo || {};
  let { pathname } = useLocation();

  useEffect(() => {
    if (show) {
      source(PAGES.SWEEPSTAKES_OFFICIAL_RULE_MODAL, {
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [show]);

  useEffect(() => {
    if (officialDocInfo) {
      setWeeklyPrizeDateStrings(
        officialDocInfo.weeklyPrizesDates
          .slice(0, -1)
          .map((date) => dateString(date))
          .join(', ') +
          ' and ' +
          dateString(officialDocInfo.weeklyPrizesDates.slice(-1)?.[0])
      );

      officialDocInfo.minimumAgeActiveCampaign =
        activeCampaign?.details?.ageLimit ||
        officialDocInfo.minimumAgeActiveCampaign;
      officialDocInfo.minimumAgeInNumbersActiveCampaign = numToWords(
        officialDocInfo.minimumAgeActiveCampaign
      );
      officialDocInfo.winnerListSubmissionDate = dateString(
        activeCampaign?.details?.winnerListSubmissionTime ||
          officialDocInfo.winnerListSubmissionDate
      );
    }
  }, [officialDocInfo]);

  useEffect(() => {
    if (activeCampaign) {
      let grandSum = 0;
      const grandPrizes = [];
      activeCampaign?.drawings.map(
        ({ type, prizes, numberOfWinners, selectionTime }) => {
          if (type === WINNER_SELECTION_INFO_TYPE.GRAND) {
            grandPrizes.push({
              info: activeCampaign?.details?.officialRules,
              amount: (prizes?.[0]?.amount || 0).toFixed(2),
              numberOfWinners,
              selectionTime,
            });
          }
          grandSum += prizes?.[0].amount * numberOfWinners;
        }
      );

      setGrandPrizeDrawings(grandPrizes);
      setGrandSum(numberWithCommas(grandSum.toFixed(2)));
    }
  }, [activeCampaign]);

  const handleClose = () => {
    track(EVENTS.SWEEPSTAKES_CLOSE_CLICKED, {
      location: EVENT_LOCATION.SWEEPSTAKES_OFFICIAL_RULE_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    setShow(false);
  };

  const handleShow = () => {
    track(EVENTS.SWEEPSTAKES_OFFICIAL_RULES_CLICKED, {
      id: activeCampaign?._id,
      name: activeCampaign?.name,
      ui_version: import.meta.env.APP_VERSION,
    });
    setShow(true);
  };

  const handleSurveyMonkeyLink = () => {
    track(EVENTS.SWEEPSTAKES_SURVEY_MONKEY_LINK_CLICKED, {
      location: EVENT_LOCATION.SWEEPSTAKES_OFFICIAL_RULE_MODAL,
      id: activeCampaign?._id,
      name: activeCampaign?.name,
      ui_version: import.meta.env.APP_VERSION,
    });
  };

  return (
    <>
      <Button intent="link" onClick={handleShow}>
        Sweepstakes Official Rules
      </Button>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
        scrollable
        className="terms-modal text-justify"
      >
        <Modal.Header closeButton className="">
          <Col className="official-rules-title text-center">
            AdvantEdge Rewards{' '}
            {getNewYorkDateString(activeCampaign?.startTime, 'MM/dd/yy')}{' '}
            Sweepstakes - Official Rules
          </Col>
        </Modal.Header>
        <Modal.Body>
          <Col className="px-0 pb-2 font-weight-bold">
            NO PURCHASE OR PAYMENT IS NECESSARY TO ENTER OR WIN. A PURCHASE OR
            PAYMENT OF ANY KIND WILL NOT INCREASE YOUR CHANCES OF WINNING.
          </Col>
          <h4 className="terms-and-conditions-header-1"> 1. Eligibility</h4>
          <Col className="terms-and-conditions-subtext p-0">
            AdvantEdge Rewards Sweepstakes (the “Sweepstakes”) is open only to
            legal residents of Connecticut, Massachusetts, New Hampshire, New
            York, Pennsylvania and Vermont who are at least{' '}
            {officialDocInfo?.minimumAgeInNumbersActiveCampaign} (
            {officialDocInfo?.minimumAgeActiveCampaign}) years old at the time
            of entry. Void where prohibited. Proof of residency and age may be
            required. Void where prohibited. Employees of The Golub Corporation
            d/b/a Price Chopper / Market 32, TCC Retail Marketing, Inc., and
            their respective parents, affiliates, subsidiaries, distributors,
            sales representatives, and all other service agencies involved with
            the Sweepstakes and members of their immediate family (spouse,
            parents, siblings and children) and household members (whether
            related or not) of each such employee are not eligible to
            participate or win. In order to enter the Sweepstakes or receive a
            prize, you must fully comply with the Official Rules and, by
            entering, you represent and warrant that you agree to be bound by
            these Official Rules and the decisions of the Sponsor, whose
            decisions shall be binding and final in all respects relating to
            this Sweepstakes.
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1">
              {' '}
              2. Sponsor and Administrator
            </h4>
            <Col className="terms-and-conditions-subtext p-0">
              Sponsor: The Golub Corporation d/b/a Price Chopper / Market 32,
              461 Nott Street, Schenectady, NY 12308. Administrator: TCC Retail
              Marketing, Inc., 477 Madison Ave., 6th Floor, New York, NY 10022.
            </Col>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1"> 3. Timing</h4>
            <Col className="terms-and-conditions-subtext p-0">
              The Sweepstakes begins on{' '}
              {getNewYorkDateString(activeCampaign?.startTime, 'MMMM dd, yyyy')}{' '}
              at {getNewYorkDateString(activeCampaign?.startTime, `HH:mm a `)}
              {' Eastern Daylight Time (“EDT”) '}
              and ends on{' '}
              {getNewYorkDateString(
                activeCampaign?.endTime,
                'MMMM dd, yyyy'
              )}{' '}
              at {getNewYorkDateString(activeCampaign?.endTime, `HH:mm a z`)}{' '}
              (“Sweepstakes Entry Period”). Sponsor is the official timekeeper
              for this Sweepstakes. The Sweepstakes is governed by these
              Official Rules and is subject to all applicable federal, state and
              local laws.
            </Col>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1"> 4. How to Enter</h4>
            <div className="terms-and-conditions-subtext terms-and-conditions-how-to-enter">
              To enter you must be a Price Chopper / Market 32 AdvantEdge Card
              member. If you are not an AdvantEdge Card member you can become
              one for free at{' '}
              <a
                href="https://www.pricechopper.com/rewards/apply/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.pricechopper.com/rewards/apply/{' '}
              </a>
              . Once you are a Price Chopper / Market 32 AdvantEdge Card member,
              you can create a free online account to access and enter the
              sweepstakes at{' '}
              <a
                href={`${window.location.origin}`}
                target="_blank"
                rel="noreferrer"
              >{`${window.location.origin}`}</a>
              . To enter you will be required to redeem 100 AdvantEdge Rewards
              points. Once you redeem 100 Price Chopper / Market 32 Reward
              points you will receive one entry into the specific grand prize
              drawing. If you want to enter without redeeming Price Chopper /
              Market 32 Rewards points, go to{' '}
              <Link
                to={`${pathname}/free-entry`}
                onClick={handleSurveyMonkeyLink}
              >
                {`https://${window.location.hostname}${pathname}/free-entry`}
              </Link>{' '}
              and provide the required information. If you do not have an
              AdvantEdge Card you will be required to register for one at{' '}
              <a
                href="https://www.pricechopper.com/rewards/apply/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.pricechopper.com/rewards/apply/
              </a>
              . Registering is free. For each online form you complete you will
              receive one entry into the grand prize drawing.
            </div>
          </Col>

          <Col className="px-0 py-2">
            <b>LIMIT:</b>{' '}
            <span className="terms-and-conditions-subtext">
              You may enter{' '}
              {numToWords(partner?.freeEntriesLimitPerDay || 0).trim()} (
              {partner?.freeEntriesLimitPerDay}) times per day during the
              Sweepstakes Entry Period, regardless of method of entry per email
              address/person. This is a limit of number of grand prize entries
              per day for all grand prizes offered (cumulative). Multiple
              entrants are not permitted to share the same email address. Any
              attempt by any entrant to obtain more than the stated number of
              entries by using multiple/different email addresses, identities,
              registrations and logins, or any other methods will void that
              entrant’s entries and that entrant may be disqualified. Use of any
              robotic, automatic, programmed or similar entry method or the use
              of any means to obtain more than the stated number of Sweepstakes
              entries plays per day will void all entries/plays and result in
              disqualification. In the event of a dispute as to any
              registration, the authorized account holder of the email address
              used to register will be deemed to be the entrant. The “authorized
              account holder” is the natural person assigned an email address by
              an Internet access provider, online service provider or other
              organization responsible for assigning email addresses for the
              domain associated with the submitted address. Each potential
              winner may be required to show proof of being the authorized
              account holder.
            </span>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1">
              {' '}
              5. Winner Determination
            </h4>
            <span className="terms-and-conditions-grand-prize">
              Grand Prize Drawing
            </span>
            <br />
            <Col className="terms-and-conditions-subtext p-0 pb-2">
              On or about{' '}
              {getNewYorkDateString(
                activeCampaign?.drawings?.[0]?.selectionTime,
                'MMMM dd, yyyy'
              )}
              , the Administrator will select one (1) entry for each grand prize
              described below in a random drawing of all valid entries received.
              Limit of one grand prize per person for the Sweepstakes.
            </Col>

            {officialDocInfo?.isWeeklyWinners && (
              <>
                <span className="terms-and-conditions-grand-prize">
                  Weekly Prize Drawings
                </span>
                <br />
                <Col className="terms-and-conditions-subtext p-0">
                  On or about {weeklyPrizeDateStrings}, the Administrator will
                  select one (1) entry for each weekly prize described below in
                  a random drawing of all valid entries received. There will be
                  four winners per week. Since there are five weeks there will
                  be a total of twenty weekly winners. Limit of one weekly prize
                  per person for the Sweepstakes.
                </Col>
              </>
            )}
            <Col className="p-0 mt-2">
              The potential winner(s) will be contacted using the email address
              provided with the registration or on file with the Sponsor and may
              be awarded the prize subject to verification of eligibility and
              compliance with the terms of these Official Rules. The potential
              winners will be notified by email on or about two days after the
              drawing. Failure by a potential winner to respond to any contact
              within five (5) days of notification will result in
              disqualification, and Administrator will select an alternate
              potential winner in the same manner. Odds of winning a grand prize
              depend on the number of eligible entries received during the
              Giveaway Entry Period. Odds of winning a weekly prize depend on
              the number of eligible entries received up to the time of the
              drawing.
            </Col>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1">
              {' '}
              6. POTENTIAL WINNERS ARE SUBJECT TO VERIFICATION BY SPONSOR AND/OR
              ADMINISTRATOR. SPONSOR AND ADMINISTRATOR’S DECISIONS ARE FINAL AND
              BINDING IN ALL MATTERS RELATED TO THE ADMINISTRATION, OPERATION,
              SELECTION OF THE WINNERS AND OTHER MATTERS RELATED TO THE
              SWEEPSTAKES.
            </h4>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1">
              {' '}
              7. Verification of Potential Winners
            </h4>
            <span className="terms-and-conditions-subtext">
              Potential winner may be required to complete and return an
              Affidavit of Eligibility, Release of Liability and Publicity
              Release (where permitted by law) (collectively, the “Affidavit”)
              by the date specified by Sponsor, or an alternate potential winner
              may be selected. In the event (a) potential winner cannot be
              reached for whatever reason after a reasonable effort has been
              exerted, or the potential winner notification or Affidavit is
              returned as undeliverable, (b) potential winner declines or cannot
              accept, receive or use the prize for any reason, (c) of
              noncompliance with these Official Rules or any of the aforesaid
              time periods, (d) potential winner is found to be ineligible to
              enter the Giveaway or receive the prize, or (e) potential winner
              fails to fulfill the Affidavit-related obligations, the potential
              winner shall be disqualified from the Giveaway and an alternate
              potential winner may be selected in a random drawing, from among
              the other eligible entries received, as described in Section 5
              above. Sponsor reserves the right to modify the notification and
              Affidavit procedures in connection with the selection of alternate
              potential winner, if any.
            </span>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1"> 8. Prizes</h4>
            <Table responsive bordered hover className="text-left">
              <thead>
                <tr>
                  <th>
                    <u>Grand Prize</u>
                  </th>
                  <th>
                    <u>Quantity</u>
                  </th>
                  <th>
                    <u>Total Approximate Retail Value (ARV)</u>
                  </th>
                </tr>
              </thead>
              {grandPrizeDrawings?.map((grandPrize, index) => (
                <tbody key={index}>
                  <tr className="terms-and-conditions-grandprize-table-text">
                    <td>{grandPrize?.info}</td>
                    <td>{grandPrize?.numberOfWinners}</td>
                    <td>
                      $
                      {numberWithCommas(
                        parseFloat(
                          grandPrize?.amount * grandPrize?.numberOfWinners
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>

            <span className="terms-and-conditions-subtext">
              For all Prizes: No cash equivalent for any prize and no
              substitution will be made except as provided herein at the
              Sponsor’s sole discretion. Sponsor reserves the right to
              substitute a listed prize for one of equal or greater value for
              any reason. Winner is responsible for all taxes and fees
              associated with prize receipt and/or use. All federal, state, and
              local tax liabilities, as well as any other costs and expenses not
              specified herein as being awarded are the sole responsibility of
              the Winner. Winner may be required to complete and return an IRS
              W-9 form (i.e. Request for Taxpayer Identification Number and
              Certification). Prize will be awarded only if the potential prize
              winner fully complies with these Official Rules. Total approximate
              retail value of all prizes available to be won is ${grandSum}.
            </span>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1">
              {' '}
              9. Entry Conditions and Release
            </h4>
            <span className="terms-and-conditions-subtext">
              Each entrant agrees to: (a) comply with and be bound by these
              Official Rules and the decisions of Sponsor and Administrator
              which are binding and final in all matters relating to this
              Sweepstakes; (b) defend, indemnify, release and hold harmless the
              Sponsor, Administrator, and their respective parent, subsidiary,
              and affiliated companies, celebrities, and any other person and
              organization responsible for sponsoring, fulfilling,
              administering, advertising or promoting the Sweepstakes, and all
              of their respective past and present parents, affiliates,
              officers, directors, employees, agents and representatives
              (collectively, the “Released Parties”) from and against any and
              all claims, expenses, and liability, including but not limited to
              negligence and damages of any kind to persons and property,
              including but not limited to invasion of privacy (under
              appropriation, intrusion, public disclosure of private facts,
              false light in the public eye or other legal theory), defamation,
              slander, libel, violation of right of publicity, infringement of
              trademark, copyright or other intellectual property rights,
              property damage, or death or personal injury arising out of or
              relating to a participant’s entry, creation of an entry or
              submission of an entry, participation in the Sweepstakes,
              acceptance, possession, attendance at, defect in, delivery of,
              inability to use, use or misuse of prize (including any travel or
              activity related thereto) and/or the broadcast, exploitation or
              use of entry. Winners acknowledge that the prize is awarded as-is
              without warranty of any kind.
            </span>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1"> 10. Publicity</h4>
            <span className="terms-and-conditions-subtext">
              Except where prohibited or restricted by law, winner’s acceptance
              of prize constitutes the winner’s agreement and consent for
              Sponsor and any of its designees to use and/or publish winner’s
              full name, city and state/province/territory/country of residence,
              photographs or other likenesses, pictures, portraits, video,
              voice, testimonials, biographical information (in whole or in
              part), and/or statements made by winner regarding the Sweepstakes
              or Sponsor, worldwide and in perpetuity for advertising,
              publicity, trade and/or promotion on behalf of Sponsor, in any and
              all forms of media, now known or hereafter devised, including, but
              not limited to, print, TV, radio, electronic, cable, or World Wide
              Web, without further limitation, restriction, compensation,
              notice, review, or approval.
            </span>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1">
              {' '}
              11. General Conditions
            </h4>
            <span className="terms-and-conditions-subtext">
              Sponsor and its subsidiaries, affiliates, divisions, partners,
              representatives, agents, successors, assigns, employees, officers
              and directors shall not have any obligation or responsibility,
              including any responsibility to award any prize to entrants, with
              regard to: (a) entries that contain inaccurate information or do
              not comply with or violate the Official Rules; (b) entries, prize
              claims or notifications that are lost, late, incomplete,
              illegible, unintelligible, damaged or otherwise not received by
              the intended recipient, in whole or in part, due to computer,
              human or technical error of any kind; (c) entrants who have
              committed fraud or deception in entering or participating in the
              Sweepstakes or claiming the prize; (d) telephone, electronic,
              hardware, software, network, Internet or computer malfunctions,
              failures or difficulties; (e) any inability of the winner to
              accept the prize for any reason; (f) if a prize cannot be awarded
              due to delays or interruptions due to Acts of God, natural
              disasters, terrorism, weather or any other similar event beyond
              Sponsor’s reasonable control; or (g) any damages, injuries or
              losses of any kind caused by any prize or resulting from awarding,
              acceptance, possession, use, misuse, loss or misdirection of any
              prize or resulting from participating in this Sweepstakes or any
              promotion or prize related activities. Sponsor reserves the right,
              in its sole discretion, to disqualify any individual it finds to
              be (a) tampering with the entry process or the operation of the
              Sweepstakes, or with any Website promoting the Sweepstakes; (b)
              acting in violation of the Official Rules; or (c) entering or
              attempting to enter the Sweepstakes multiple times through the use
              of multiple email addresses. If Sponsor determines, in its sole
              discretion, that technical difficulties or unforeseen events
              compromise the integrity or viability of the Sweepstakes, Sponsor
              reserves the right to void the entries at issue, and/or terminate
              the relevant portion of the Sweepstakes, including the entire
              Sweepstakes, and/or modify the Sweepstakes and/or award the prize
              from all eligible entries received as of the termination date.
            </span>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1">
              {' '}
              12. Limitations of Liability
            </h4>
            <span className="terms-and-conditions-subtext">
              The Released Parties are not responsible for: (a) any incorrect or
              inaccurate information, whether caused by entrants, printing
              errors or by any of the equipment or programming associated with
              or utilized in the Sweepstakes; (b) technical failures of any
              kind, including, but not limited to malfunctions, interruptions,
              or disconnections in phone lines or network hardware or software;
              (c) unauthorized human intervention in any part of the entry
              process or the Sweepstakes; (d) technical or human error in the
              administration of the Sweepstakes or the processing of entries;
              (e) any injury or damage to persons or property which may be
              caused, directly or indirectly, in whole or in part, from
              entrant’s participation in the Sweepstakes or receipt or use or
              misuse of any prize; or (f) for lost, late, illegible,
              misdirected, inaccurate, incomplete, undelivered, damaged,
              delayed, entries. If for any reason an entry is confirmed to have
              been erroneously deleted, lost, or otherwise destroyed or
              corrupted, entrant’s sole remedy is another entry in the
              Sweepstakes. No more than the stated number of each prize will be
              awarded.
            </span>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1"> 13. Disputes </h4>
            <span className="terms-and-conditions-subtext">
              Entrant agrees that: (a) any and all disputes, claims and causes
              of action arising out of or connected with this Sweepstakes, or
              any prizes awarded, shall be resolved individually, without resort
              to any form of class action; (b) any and all disputes, claims and
              causes of action arising out of or connected with this
              Sweepstakes, or any prizes awarded, shall be resolved exclusively
              by the United States District Court or the appropriate New York
              State Court located in New York, New York; (c) any and all claims,
              judgments and awards shall be limited to actual out-of-pocket
              costs incurred, including costs associated with entering this
              Sweepstakes, but in no event attorneys’ fees; and (d) under no
              circumstances will entrant be permitted to obtain awards for, and
              entrant hereby waives all rights to claim punitive, incidental and
              consequential damages and any other damages, other than for actual
              out-of-pocket expenses, and any and all rights to have damages
              multiplied or otherwise increased. SOME JURISDICTIONS DO NOT ALLOW
              THE LIMITATIONS OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
              CONSEQUENTIAL DAMAGES, SO THE ABOVE MAY NOT APPLY TO YOU. All
              issues and questions concerning the construction, validity,
              interpretation and enforceability of these Official Rules, or the
              rights and obligations of the entrant and Sponsor in connection
              with the Sweepstakes, shall be governed by, and construed in
              accordance with, the laws of the State of New York, without giving
              effect to any choice of law or conflict of law rules (whether of
              the State of New York or any other jurisdiction), which would
              cause the application of the laws of any jurisdiction other than
              the State of New York.
            </span>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1">
              {' '}
              14. Entrant’s Personal Information
            </h4>
            <span className="terms-and-conditions-subtext">
              Information collected from entrants is subject to the Sponsor’s
              Privacy Policy, which is available at{' '}
              <a
                href="https://www.pricechopper.com/about-us/customer-service/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.pricechopper.com/about-us/customer-service/privacy-policy/
              </a>{' '}
              .
            </span>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1">
              {' '}
              15. Sweepstakes Results{' '}
            </h4>
            <span className="terms-and-conditions-subtext">
              For Sweepstakes results (of winners of grand prizes), send a
              written request to:
              <br />
            </span>
            <br />
            <div className="terms-and-conditions-subtext terms-and-conditions-request-send-to">
              {getNewYorkDateString(activeCampaign?.startTime, 'MM/dd/yy')}{' '}
              AdvantEdge Rewards Sweepstakes – Winners List
              <br />
              TCC Retail Marketing, Inc.
              <br />
              477 Madison Ave., 6th Floor
              <br />
              New York NY 10022
            </div>
            <br />
            <span className="terms-and-conditions-subtext">
              Requests for the Winner’s List must be received by{' '}
              {getNewYorkDateString(
                activeCampaign?.details?.winnerListSubmissionTime,
                'MMMM dd, yyyy'
              )}
              .
            </span>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1">
              {' '}
              16. Availability of the Official Rules
            </h4>
            <span className="terms-and-conditions-subtext">
              The Official Rules are available at address{' '}
              <a
                href={`${window.location.origin}${getSweepstakesPath()}`}
                target="_blank"
                rel="noreferrer"
              >{`${window.location.origin}${getSweepstakesPath()}`}</a>
              .
            </span>
          </Col>

          <Col className="px-0 py-2">
            <h4 className="terms-and-conditions-header-1">Abbreviated Rules</h4>
            <span className="terms-and-conditions-subtext">
              NO PURCHASE NECESSARY. The AdvantEdge Rewards Sweepstakes is open
              only to legal residents of the Connecticut, Massachusetts, New
              Hampshire, New York, Pennsylvania and Vermont who are at least{' '}
              {officialDocInfo?.minimumAgeInNumbersActiveCampaign} (
              {officialDocInfo?.minimumAgeActiveCampaign}) years old at the time
              of entry. Sweepstakes starts on{' '}
              {getNewYorkDateString(activeCampaign?.startTime, 'MMMM dd, yyyy')}{' '}
              and ends on{' '}
              {getNewYorkDateString(activeCampaign?.endTime, 'MMMM dd, yyyy')}{' '}
              at {getNewYorkDateString(activeCampaign?.endTime, 'hh:mm a z')}.
              For entry and Official Rules visit address{' '}
              <a
                href={`${window.location.origin}${getSweepstakesPath()}`}
                target="_blank"
                rel="noreferrer"
              >{`${window.location.origin}${getSweepstakesPath()}`}</a>
              . Void where prohibited. Sponsored by The Golub Corporation d/b/a
              Price Chopper / Market 32, 461 Nott Street, Schenectady, NY 12308.
            </span>
          </Col>
        </Modal.Body>
        <Modal.Footer className="">
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TermsAndConditons;
