import React, { useState, useEffect, useContext } from 'react';
import { Modal, Col, Card } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { fetchSweepstakesCampaigns } from '../../services/componentRegistryService';
import ModalCloseButton from '../ModalCloseButton';
import { CAMPAIGN_STATUS, FEATURES_FLAGS } from '../../util/constants';
import { useResponsive } from '../../util/hooks';
import useLocalStorage from '../../util/useLocalStorage';
import { getSweepstakePathId, getDailySurprisesPath } from '../../util/paths';
import { useNavigate } from 'react-router-dom';

import { WINNER_SELECTION_INFO_TYPE } from '../../util/constants';

import { useAsync, ACTIONS, AppContext, AuthContext } from '@rewards/contexts';

const { useAuth } = AuthContext;

import './PromoteSweepstake.scss';
import { EVENTS, PAGES } from '../../analytics/events';
import contentSelector from './contentSelector';
import { useSweepstakeCampaigns } from '@rewards/data-provider';

const PromoteSweepstake = () => {
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  const { token, unAuthToken, checkValidToken, featureFlags } = useAuth();
  const [show, setShow] = useState(false);
  const [darkGreyColor, setDarkGreyColor] = useState();
  const [iconStrokeColor, setIconStrokeColor] = useState();
  const [sweepstake, setSweepstake] = useState();
  const [isSweepstakeShown, setIsSweepstakeShown] = useLocalStorage(
    'isSweepstakeShown',
    false
  );
  const { data: sweepstakes } = useSweepstakeCampaigns(
    !!token || !!unAuthToken
  );
  const { track } = useContext(AppContext.AppContext);
  const [showModal, setShowModal] = useState(false);
  const [
    isDailySurpriseCampaignEndedFlag,
    setIsDailySurpriseCampaignEndedFlag,
  ] = useState(false);
  const { envInfo } = useContext(AuthContext.AuthContext);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleGotoSweepstake = () => {
    const event = !isDailySurpriseCampaignEndedFlag
      ? EVENTS.POPUP_DAILY_SURPRISE_CLICKED
      : EVENTS.POPUP_SWEEPSTAKES_CLICKED;

    track(event, {
      sweepstakeCampaign: !isDailySurpriseCampaignEndedFlag
        ? 'Daily SurPRIZE'
        : sweepstake?.name,
      ui_version: import.meta.env.APP_VERSION,
    });
    navigate(
      !isDailySurpriseCampaignEndedFlag
        ? getDailySurprisesPath()
        : getSweepstakePathId(sweepstake?._id)
    );
    handleClose();
  };

  const daysBetweenDates = (startDate, endDate) => {
    const diffInMs =
      new Date(endDate).valueOf() - new Date(startDate).valueOf();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return Math.round(diffInDays);
  };

  useEffect(() => {
    // const featureFlag = featureFlags?.find(ff =>
    //     ff.name === FEATURES_FLAGS.ENABLE_DAILY_SURPRISES

    // )
    //     console.log(featureFlag)
    // setIsDailySurpriseCampaignEndedFlag(
    //     !featureFlag?.otherDetails?.campaignActive
    // )
    if (sweepstakes) {
      contentSelector({
        featureFlags,
        handleShow,
        track,
        daysBetweenDates,
        checkValidToken,
        setSweepstake,
        activeSweepstake: sweepstakes?.active,
        setIsDailySurpriseCampaignEndedFlag,
      });
    }
  }, [featureFlags, sweepstakes]);

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        contentClassName={`rounded-3 sweepstake-promote-content`}
        dialogClassName={`rounded mt-5`}
      >
        <Modal.Header className="border-0 p-1">
          <ModalCloseButton handleClose={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <>
            {isDailySurpriseCampaignEndedFlag ? (
              <>
                <h3 className="h3 text-center sweepstake-promote-name sweepstake-promote-name-font-weight">
                  Enter for a chance to win!
                </h3>
                <h6 className="h6 text-center sweepstake-promote-name-font-weight">
                  {sweepstake?.name?.replace('\\n', ' ')}
                </h6>
                <center>
                  <Card.Img
                    src={sweepstake?.image}
                    alt={sweepstake?.name}
                    className="hover campaign-img"
                  />
                  <Button
                    onClick={handleGotoSweepstake}
                    width="full"
                    className="mx-0"
                    // variant={isDesktop ? 'link' : 'default'}
                    // className={`mt-4 mb-4 btn-sweepstake-promote p-3 btn btn-primary btn-block`}
                  >
                    Enter Now
                  </Button>
                </center>
              </>
            ) : (
              <>
                {/* <h3 className="text-center sweepstake-promote-name sweepstake-promote-name-font-weight">Play the Daily SurPRIZE&trade; for a chance to Win!</h3> */}
                <center>
                  <Card.Img
                    src={`https://cdn.spinwheel.io/images/daily-surprises/welcome_daily_surprise-01-${envInfo.NODE_ENV}.png`}
                    alt="Play the Daily SurPRIZE&trade; for a chance to Win!"
                    className="hover campaign-img"
                  />
                  <Button
                    onClick={handleGotoSweepstake}
                    width="full"
                    className="mx-0"
                    variant={isDesktop ? 'link' : 'default'}
                    //  className={`mt-4 mb-4 btn-sweepstake-promote p-3 btn btn-primary btn-block`}
                  >
                    Play Now
                  </Button>
                  {featureFlags?.find(
                    (ff) => ff.name === FEATURES_FLAGS.ENABLE_DAILY_SURPRISES
                  )?.otherDetails?.showEndDate && (
                    <>
                      <br />
                      <strong className="text-cobalt">
                        {
                          featureFlags?.find(
                            (ff) =>
                              ff.name === FEATURES_FLAGS.ENABLE_DAILY_SURPRISES
                          )?.otherDetails?.endText
                        }
                      </strong>
                      <br />
                    </>
                  )}
                </center>
              </>
            )}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PromoteSweepstake;
