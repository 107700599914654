import React, { useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '@rewards/contexts';

import Icon from '../../assets/svg';
import useResponsive from '../../util/hooks/useResponsive';
import { EVENTS } from '../../analytics/events';

import './BackNav.scss';

export default (WrappedComponent) => {
  const BackNav = (props) => {
    const history = useNavigate();
    const { track, landingPath } = useContext(AppContext.AppContext);

    const { isDesktop } = useResponsive();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const handleBackPropagation = () => {
      track(EVENTS.FUEL_BACK_CLICKED, { ui_version: process?.env.APP_VERSION });
      history.push(landingPath);
    };
    return (
      <>
        {isDesktop ? (
          <div className="flex justify-center">
            <WrappedComponent {...props} />
          </div>
        ) : (
          <>
            <Container fluid className="back-nav">
              <Row>
                <Col xs="1" sm="1" md="1" className="back-nav-heading">
                  <span onClick={handleBackPropagation} className="hover">
                    <Icon icon="angle-left" width="9" height="17" />
                  </span>
                </Col>
                <Col
                  xs="10"
                  sm="10"
                  md="10"
                  className="back-nav-heading text-center"
                >
                  <span className="">Fueld</span>
                </Col>
              </Row>
            </Container>
            <WrappedComponent {...props} />
          </>
        )}
      </>
    );
  };

  return BackNav;
};
