import { useMutation, useQueryClient } from '@tanstack/react-query';
import initiateMoneyTransferTxns, {
  useMoneyTransferTxnsV2,
} from '../services/useMoneyTransferTxns';

// import { ERROR_CODES } from '../utils/constants'

export default function useMoneyTransferTxns() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: initiateMoneyTransferTxns,
    mutationKey: ['moneyTransferTxns'],
    onSuccess: (response) => {
      queryClient.setQueryData('moneyTransferTxns', response?.data?.data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['user'],
      });
    },
  });
}

export function useMoneyTransferTxnSLP() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['moneyTransferTxns'],
    mutationFn: useMoneyTransferTxnsV2,
    onSuccess: (response) => {
      queryClient.setQueryData('moneyTransferTxns', response?.data?.data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['user'],
      });
    },
  });
}
