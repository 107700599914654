import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Badge, Card, Col, Row, Nav } from 'react-bootstrap';
import { Button, NewWaysTo } from '@rewards/ui';
import { AppContext } from '@rewards/contexts';
import '../../styes/donationSuccess.css';
import Icon from '../../assets/svg';
import { getRedirectingPath } from '../../util/paths';
import UserProfileContext from '../../contexts/UserProfileContext';
import styleConsts from '../../util/styleConsts';
import { EVENT_LOCATION, PAGES } from '../../analytics/events';

import './charitable.scss';
import { useResponsive } from '../../util/customHooks';

function GetTitle(props) {
  // TODO: Add once we have updated icons for Sweepstakes / SL / Products
  return (
    <div className="text-left">
      {/* <Icon
                icon={props.faIcon}
                width={props?.width || '17'}
                height={props.height || '17'}
            /> */}
      <span className="ml-3 nav-item-text">{props.title}</span>
      <span className="float-right">
        <Icon
          icon="angle-right"
          width="7"
          height="13"
          className="float-right"
        />
      </span>
    </div>
  );
}

export default function ConfirmDonation({
  show,
  handleClose,
  points,
  value,
  charityName,
}) {
  const navigate = useNavigate();
  const profile = useContext(UserProfileContext);
  const { track } = useContext(AppContext.AppContext);
  const { isDesktop } = useResponsive();

  const handleSelectLink = (navItem = '') => {
    track(`${PAGES.CHARITY_DETAILS} ${navItem} Clicked`, {
      location: EVENT_LOCATION.DONATE_CONFIRM_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    navigate(getRedirectingPath(navItem));
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      scrollable={true}
      centered
      className="rounded confirm-donation-modal"
      size={isDesktop ? 'lg' : null}
    >
      <Modal.Body className="p-0 text-center">
        <Card className="border-0 w-full">
          <Card.Body className="p-0">
            <div className={`modal-top p-4 ${!isDesktop && 'text-left'} `}>
              <div>
                👋{' '}
                <span className="font-weight-bold">
                  {' '}
                  Thank you, {profile?.name}!
                </span>
              </div>
              <div
                className={`sub-heading font-weight-normal mt-2 ${
                  !isDesktop && 'text-left'
                }`}
              >
                You have completed donating your points.
              </div>
              <div
                className={`sub-heading font-weight-normal ${
                  !isDesktop && 'text-left'
                } mb-2`}
              >
                {' '}
                Here are the details:
              </div>
              <Row
                className={`justify-content-between text-white font-weight-bold rounded green py-2 points-used ${
                  isDesktop ? 'w-4/5 mx-auto' : 'w-100 mx-0'
                }`}
              >
                <Col className="align-self-start text-left col-7">
                  Points redeemed:
                </Col>
                <Col className="text-right mr-3 flex align-items-center justify-content-end">
                  <Icon icon="fuel" width="12" height="16" />
                  {points}
                </Col>
              </Row>
              {/* TODO: Change to Black */}
              <div className="flex  my-donation align-items-center flex justify-content-center">
                {/* <Icon icon="slider-doller" height="38" width="35" /> */}
                <span className="font-weight-bold mx-2 text-big text-green-apple">
                  {value}
                </span>
                <span> {value > 1 ? ' dollars' : ' dollar'} donation</span>
              </div>

              <div
                className={`msg-box bg-green-apple text-white text-center py-4 px-3  ${
                  isDesktop ? 'w-4/5 mx-auto' : 'w-100'
                }`}
              >
                🙏 Price Chopper / Market 32 will donate to the{' '}
                <span>{charityName}</span> on your behalf.
              </div>
            </div>
            <div className="redeem-options text-center">
              <Col
                className={`heading py-0 text-center mt-0 ${
                  isDesktop ? 'w-4/5 mx-auto px-0' : 'px-3'
                }`}
              >
                Here are other ways to{' '}
                <Badge className="p-0"> redeem more </Badge> points.
              </Col>

              <NewWaysTo.Redeem />
              <Modal.Footer
                className={`justify-content-center py-0 confirm-donate-footer ${
                  isDesktop ? 'w-4/5 mx-auto px-0' : 'px-3'
                }`}
              >
                <Button
                  // className="m-0"
                  block
                  // style={{ backgroundColor: styleConsts.cobalt }}
                  onClick={() => handleClose()}
                >
                  {' '}
                  Done{' '}
                </Button>
              </Modal.Footer>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
}
