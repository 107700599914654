import React, { useEffect, useContext, useState } from 'react';
import { Modal, Col, ButtonGroup } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { queryClient } from '@rewards/data-provider';
import {
  AuthContext,
  AppContext,
  DailySurpriseContext,
} from '@rewards/contexts';

import { EVENT_LOCATION, EVENTS, PAGES } from '../../analytics/events';
import { useResponsive } from '../../util/hooks';
import { MAGAZINE } from '../../util/constants';

import ModalCloseBtn from '../ModalCloseButton';

import './MagazineSubscriptionSuccess.scss';
import { useMutationState } from '@tanstack/react-query';
const { useDailySurprise } = DailySurpriseContext;

const MagazineSubscriptionSuccess = ({ handleSubscribeSuccess, show }) => {
  const { envInfo } = useContext(AuthContext.AuthContext);
  const { source, track } = useContext(AppContext.AppContext);

  const { isDesktop } = useResponsive();

  const [voucherCode, setVoucherCode] = useState();
  const moneyTransferTxnsData = queryClient.getQueryData('moneyTransferTxns');

  const { dailySurpriseEvent } = useDailySurprise(
    DailySurpriseContext.DailySurpriseContext
  );

  useEffect(() => {
    return () => {
      queryClient.removeQueries('moneyTransferTxns');
    };
  }, []);

  useEffect(() => {
    if (show) {
      source(PAGES.VIEWED_MAGAZINE_SUCCESS_PAGE, {
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [show]);

  useEffect(() => {
    if (moneyTransferTxnsData?.txnStatus === MAGAZINE.COMPLETE) {
      setVoucherCode(moneyTransferTxnsData?.code?.code);
    }
  }, [moneyTransferTxnsData]);

  const handleSelectMagazine = () => {
    track(EVENTS.CLICKED_SELECT_MAGAZINE_BUTTON, {
      location: EVENT_LOCATION.MAGAZINE_SUCCESS_PAGE,
      ui_version: import.meta.env.APP_VERSION,
      is_daily_surprise: dailySurpriseEvent?.code || false,
    });
    handleSubscribeSuccess();
  };

  return (
    <>
      <Modal
        show={show && !dailySurpriseEvent.code}
        backdrop="static"
        keyboard={false}
        centered={true}
        className="ms-modal"
        dialogClassName={`rounded' ${
          isDesktop ? '' : 'ms-mobile-dialog mx-auto'
        }`}
        contentClassName="lc-magazines-modal-content"
      >
        <Modal.Header className="border-0 flex justify-center lc-magazines-subscription-success-header pt-12">
          Success!
          <ModalCloseBtn handleClose={handleSubscribeSuccess} />
        </Modal.Header>
        <Modal.Body className="text-left border-0 px-12">
          <Col className="bg-white !py-4 px-0 text-center lc-magazines-subscription-success-voucher-card">
            <Col className="pt-1 pb-3 font-medium">
              Here is your unique voucher code:
            </Col>
            <Col className="font-medium text-cobalt text-h3 ">
              {voucherCode}
            </Col>
            <Col className="text-center text-dark-grey !pt-4 !pb-2 font-medium text-xs ">
              Valued at one digital or print magazine subscription.
            </Col>
          </Col>
        </Modal.Body>
        <Modal.Footer className="border-0 mt-2 bg-white">
          <ButtonGroup className="d-flex justify-content-between w-100">
            <Button
              width="full"
              as="a"
              // className={`lc-magazines-subscription-select-magazine mr-1 rounded w-100 py-3`}
              onClick={handleSelectMagazine}
              href={`${envInfo?.magazinesPath}?v=${voucherCode}`}
              target="_blank"
            >
              SELECT MAGAZINE
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MagazineSubscriptionSuccess;
