import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { GiftCardsContextState } from './contexts/GiftCardsContext';
import { getShopInStorePath } from './util/paths';

import LandingPage from './components/LandingPage';

// import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import './styles.css';

const App = () => {
  return (
    <GiftCardsContextState>
      <Routes>
        <Route index element={<LandingPage />} />
        <Route path="*" render={() => <Navigate to={''} />} />
      </Routes>
    </GiftCardsContextState>
  );
};

export default App;
