import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Campaigns from './components/Campaigns';
import Campaign from './components/Campaign';
import BonusPoints from './components/BonusPoints';

import { CampaignsContextState } from './contexts/CampaignsContext';
import {
  fetchCampaigns,
  fetchCalculateExchange,
  fetchBonusPointsTxns,
} from './services/campaignsService';

import { getSweepstakesPath, getSweepstakePath } from './util/paths';

// // import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import { FreeSurvey } from './routes/free-entry';

const App = () => {
  console.log('SWEEPSTAKE MODULE');
  return (
    <CampaignsContextState
      fetchCalculateExchange={fetchCalculateExchange}
      fetchBonusPointsTxns={fetchBonusPointsTxns}
    >
      <Routes>
        <Route index element={<Campaigns />} />
        <Route path={`:id/free-entry`} element={<FreeSurvey />} />
        <Route path={':id'} element={<Campaign />} />
        <Route path="*" render={() => <Navigate to={''} />} />
      </Routes>
    </CampaignsContextState>
  );
};

export default App;
