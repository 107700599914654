import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { MagazinesContextState } from './contexts/MagazinesContext';
import {
  fetchEMagazines,
  fetchEMagazinesUnAuthorized,
} from './services/magazinesService';
import { getLandingPath, getMagazinesListPath } from './util/paths';

import Magazines from './components/Magazines';
import MagazinesList from './components/MagazinesList';

// import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import './styles.css';

const App = () => {
  return (
    <MagazinesContextState
      fetchEMagazines={fetchEMagazines}
      fetchEMagazinesUnAuthorized={fetchEMagazinesUnAuthorized}
    >
      <Routes>
        <Route index element={<Magazines />} />
        <Route path={':code'} component={<MagazinesList />} />
        <Route path="*" render={() => <Navigate to={''} />} />
      </Routes>
    </MagazinesContextState>
  );
};

export default App;
