import React, { useEffect, useContext, useState } from 'react';
import { Container, Nav, Card, Row, Col, Badge, Image } from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { AppContext } from '@rewards/contexts';
import { useUserInfo } from '@rewards/data-provider';

import { useCampaigns, useResponsive } from '../../util/hooks';
import { getSweepstakePath } from '../../util/paths';
import { PAGES, EVENTS, EVENT_LOCATION } from '../../analytics/events';

import BackNav from '../BackNav';
import { GrandPrizesCarousal, WinnersList } from '../PrizesModals';

import './Campaigns.scss';

const Campaigns = () => {
  const { landingPath, track, source } = useContext(AppContext.AppContext);
  const { activeCampaigns, completedCampaigns } = useCampaigns();
  const { isDesktop } = useResponsive();
  const queryString = new URL(document.location).searchParams;
  const reservationId = queryString.get('reservationId');

  useEffect(() => {
    Campaigns.landingPath = landingPath;
    Campaigns.track = () =>
      track(EVENTS.SWEEPSTAKES_BACK_CLICKED, {
        location: EVENT_LOCATION.MULTI_SWEEPSTAKES_LANDING_PAGE,
        ui_version: import.meta.env.APP_VERSION,
      });

    source(PAGES.MULTI_SWEEPSTAKES, {
      ui_version: import.meta.env.APP_VERSION,
    });
  }, []);

  const CampaignDetailsCard = ({ campaign, ...props }) => {
    const { track } = useContext(AppContext.AppContext);

    const { data: userInfo } = useUserInfo();
    const user = userInfo?.data;
    const navigate = useNavigate();
    const navigationType = useNavigationType();
    const location = useLocation();

    const [sweepstakeCampaignsAccount, setSweepstakeCampaignsAccount] =
      useState();

    useEffect(() => {
      if (user && campaign) {
        const sweepstakeCampaignsAccount = user.sweepstakeCampaigns.filter(
          ({ campaignId }) => campaignId === campaign._id
        );
        setSweepstakeCampaignsAccount(sweepstakeCampaignsAccount);
      }
    }, [user, campaign]);

    useEffect(() => {
      if (
        navigationType === 'POP' &&
        location?.pathname.includes('enter-sweepstakes')
      ) {
        history.push('/');
      } else if (activeCampaigns?.length === 1 && navigationType !== 'POP') {
        const sweepstakePath = getSweepstakePath(activeCampaigns?.[0]?._id);
        const url = reservationId
          ? `${sweepstakePath}?reservationId=${reservationId}`
          : sweepstakePath;
        navigate(url);
      }
    }, [activeCampaigns?.length, completedCampaigns?.length]);

    const handleClickCampaign = () => {
      track(EVENTS.SWEEPSTAKES_CLICKED, {
        id: campaign?._id,
        name: campaign?.name,
        ui_version: import.meta.env.APP_VERSION,
      });

      let url = getSweepstakePath(campaign?._id);

      if (reservationId) {
        url = `${url}?reservationId=${reservationId}`;
      }

      navigate(url);
    };

    const Entries = () => (
      <Badge className="rounded-pill tcc-sweeps-card-entries py-2 px-3 position-absolute ck-sweeps-not-first-entries">
        {sweepstakeCampaignsAccount?.[0]?.accountBalance || 0} entries
      </Badge>
    );

    return (
      <Card className="h-100 border-0 active-card campaing-border">
        {/* {props.isDesktop && <Card.Title className='py-2 ml-3 sweepstake-body-title'>
          {campaign?.name.replace('\\n', ' ')}
        </Card.Title>} */}
        <Card.Header
          className="p-0 border-0 active-card-header"
          onClick={handleClickCampaign}
        >
          <Card className="rounded-0 border-0">
            <div className="camapign-card-img-wrapper">
              <Card.Img
                src={campaign?.image}
                alt={campaign?.name}
                className="hover campaign-img"
              />
              {/* <Card.ImgOverlay className='text-center'>
                <Entries />
              </Card.ImgOverlay> */}
            </div>
          </Card>
        </Card.Header>

        <Card.Body>
          {
            <Card.Title className="mb-0 sweepstake-body-title">
              {campaign?.name.replace('\\n', ' ')}
            </Card.Title>
          }
          <Card.Text className="mb-0 sweepstake-body-desciption">
            Your current chances to win: 1 in{' '}
            {campaign?.numberOfParticipants > 200
              ? campaign?.numberOfParticipants
              : 200}
          </Card.Text>
        </Card.Body>
        <div className="flex w-full !px-4 mb-3">
          <Button width="full" onClick={handleClickCampaign}>
            Enter Here
          </Button>
        </div>
      </Card>
    );
  };

  // console.log(activeCampaigns);

  return (
    <Container className="campaigns-page text-center">
      <div className="m-auto">
        {isDesktop && (
          <div
            className={`my-3 desktop-campaigns-heading text-center ${
              isDesktop ? 'py-2' : 'p-0'
            }`}
          >
            AdvantEdge Rewards Sweepstakes
          </div>
        )}
        <Col className={`${isDesktop ? 'rounded' : 'p-0'}`}>
          {activeCampaigns?.length === 0 && (
            <Col className="no-campaigns">
              <Col className="my-3 no-campaigns-heading p-0">
                Come back soon to enter for more great sweepstakes prizes!
              </Col>
              <Col className="no-campaigns-image">
                <Image src="https://cdn.spinwheel.io/images/pricechopper/advantedge-card.png" />
              </Col>
            </Col>
          )}

          {activeCampaigns?.length > 0 && (
            <Col>
              <Col
                className={`my-3 campaigns-heading${
                  isDesktop ? '-desktop' : ''
                } p-0 text-center p-3`}
              >
                Use your points to enter for a chance to win
                <br />
                100 points = 1 entry
              </Col>
            </Col>
          )}
        </Col>

        {activeCampaigns?.length > 0 && (
          <>
            <div
              className={`active-campaings-heading text-left my-3 pl-1 ${
                isDesktop ? 'pt-3 pb-0' : 'p-0'
              }`}
            >
              Grand Prizes
            </div>
            <Nav className="mx-n2">
              <Row className="w-100 m-0">
                {activeCampaigns.map((campaign) => {
                  return (
                    <Col
                      xs={12}
                      md={isDesktop ? 4 : 12}
                      sm={12}
                      key={campaign?._id}
                      className="p-1 hover campaing-border"
                    >
                      <Nav.Item key={campaign._id} className="p-2 h-100">
                        <CampaignDetailsCard
                          campaign={campaign}
                          isDesktop={isDesktop}
                        />
                      </Nav.Item>
                    </Col>
                  );
                })}
              </Row>
            </Nav>
          </>
        )}
        {completedCampaigns?.length > 0 && (
          <>
            <div className="completed-camapigns-heading pt-4 text-left mb-3">
              Previous Rewards
            </div>
            <Nav className="mx-n2" variant="pills">
              <Row className="w-100 m-0">
                {completedCampaigns.map((campaign) => (
                  <Col
                    xs={12}
                    md={isDesktop ? 6 : 12}
                    sm={12}
                    key={campaign?._id}
                    className={`${isDesktop ? 'px-2 mb-3' : 'px-2'}`}
                  >
                    <Nav.Item
                      key={campaign?._id}
                      className={`my-2 rounded ${
                        isDesktop ? 'p-3 h-100 bg-white' : 'p-0'
                      }`}
                    >
                      <CampaignDetailsCard
                        campaign={campaign}
                        isDesktop={isDesktop}
                      />
                    </Nav.Item>
                  </Col>
                ))}
              </Row>
            </Nav>
          </>
        )}
      </div>
    </Container>
  );
};

export default BackNav(Campaigns);
