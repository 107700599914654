import React, { useEffect, useState, useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useAsync, ACTIONS, AuthContext } from '@rewards/contexts';

import ToolsForSchoolsContext from './ToolsForSchoolsContext';
import { sortArray } from '../util/optimizer';
import {
  getSignUpPath,
  getToolsForSchoolsPath,
  getComingSoonPath,
} from '../util/paths';

import { FEATURES_FLAGS } from '../util/constants';

const { useAuth } = AuthContext;
const ToolsForSchoolsContextState = (
  {
    children,
    fetchToolsForSchools,
    fetchSchoolForUser,
    fetchSchools,
    validateCardNumber,
  },
  ...props
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    token,
    checkValidToken,
    isSSOEnabled,
    isToolsForSchoolsInviteFriends,
    switchSignUpSso,
    featureFlags,
  } = useAuth();
  const ff = featureFlags?.find(
    (ff) => ff.name === FEATURES_FLAGS.ENABLE_TOOLS_FOR_SCHOOLS
  );
  const { instance } = useMsal();

  const { handleClose } = useContext(AuthContext.AuthContext);

  const [isSignUpFlow, setIsSignUpFlow] = useState(false);
  const [tfsComingSoonfeatureflag, setTfsComingSoonfeatureflag] = useState();

  const asyncToolsForSchoolsData = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { toolsForSchools: null },
  });
  const asyncSchoolForUserData = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { schoolForUser: null },
  });
  const asyncSchoolsData = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { schools: null },
  });
  const asyncCardData = useAsync({
    status: ACTIONS.STATUS.IDLE,
    data: { cardDetails: null },
  });

  const {
    data: toolsForSchoolsData,
    run: runAsyncToolsForSchools,
    status: toolsForSchoolsStatus,
  } = asyncToolsForSchoolsData;
  const {
    data: schoolForUserData,
    run: runAsyncSchoolForUser,
    status: schoolForUserStatus,
  } = asyncSchoolForUserData;
  const {
    data: schoolsData,
    run: runAsyncSchoolsData,
    status: schoolsStatus,
  } = asyncSchoolsData;
  const {
    data: cardData,
    run: runAsyncCard,
    status: cardStatus,
  } = asyncCardData;

  useEffect(() => {
    const pathName = location?.pathname;
    if (pathName === getSignUpPath()) {
      if (!switchSignUpSso) {
        handleClose();
      }
      setIsSignUpFlow(true);
      if (instance?.getAllAccounts()?.length) {
        navigate(getToolsForSchoolsPath());
        setIsSignUpFlow(false);
      }
    } else {
      setIsSignUpFlow(false);
    }
  }, [location, instance?.getAllAccounts()]);

  useEffect(() => {
    setTfsComingSoonfeatureflag(
      featureFlags?.find(
        (ff) =>
          ff.name === FEATURES_FLAGS.ENABLE_TOOLS_FOR_SCHOOLS && !ff.isActive
      )
    );
  }, [featureFlags]);

  useEffect(() => {
    const temp_t4s_ff = featureFlags?.find(
      (ff) =>
        ff.name === FEATURES_FLAGS.ENABLE_TOOLS_FOR_SCHOOLS && !ff.isActive
    );
    if (tfsComingSoonfeatureflag) {
      navigate(getComingSoonPath());
    }
    if (
      isSSOEnabled &&
      instance?.getAllAccounts()?.length === 0 &&
      !isToolsForSchoolsInviteFriends &&
      !temp_t4s_ff
    ) {
      if (tfsComingSoonfeatureflag) {
        navigate(getComingSoonPath());
      } else {
        navigate(getSignUpPath());
      }
    }
  }, [
    instance,
    isSSOEnabled,
    isToolsForSchoolsInviteFriends,
    tfsComingSoonfeatureflag,
    location,
  ]);

  const fetchSchoolsData = ({ schoolName: name, zipCode }) => {
    return checkValidToken().then(() => {
      return runAsyncSchoolsData(
        fetchSchools({
          name,
          zipCode,
        }).then((data) => {
          const sortedSchools = sortArray('name', data);
          return {
            schools: sortedSchools,
          };
        })
      );
    });
  };

  const getToolsForSchools = (toolsForSchoolsId) => {
    if (toolsForSchoolsStatus === ACTIONS.STATUS.IDLE) {
      checkValidToken().then(() => {
        return runAsyncToolsForSchools(
          fetchToolsForSchools(toolsForSchoolsId).then((data) => ({
            toolsForSchools: data,
          }))
        );
      });
    }
  };

  const getSchoolForUser = () => {
    checkValidToken().then(() => {
      runAsyncSchoolForUser(
        fetchSchoolForUser().then((data) => ({ schoolForUser: data }))
      ).then(() => {
        const schoolName = '';
        const zipCode = '';
        fetchSchoolsData({ schoolName, zipCode });
      });
    });
  };

  const validateCardNumberForUser = ({ cardNumber }) => {
    return runAsyncCard(
      validateCardNumber({ cardNumber }).then((data) => {
        return { cardDetails: data };
      })
    );
  };

  useEffect(() => {
    if (token && !isSignUpFlow) {
      getSchoolForUser();
    }
  }, [token, isSignUpFlow]);

  useEffect(() => {
    if (schoolForUserData?.schoolForUser && !isSignUpFlow) {
      getToolsForSchools(schoolForUserData?.schoolForUser?.toolsForSchoolsId);
    }
  }, [schoolForUserData?.schoolForUser, isSignUpFlow]);

  console.log(
    '🚀 ~ TFS ~ COndition 1 schoolForUserData:',
    schoolForUserStatus !== ACTIONS.STATUS.RESOLVED &&
      !!schoolForUserData?.schoolForUser
  );
  console.log(
    '🚀 ~ TFS ~ COndition 2 schoolForUserData:',
    schoolForUserStatus !== ACTIONS.STATUS.RESOLVED &&
      toolsForSchoolsStatus !== ACTIONS.STATUS.RESOLVED &&
      location?.pathname.includes(getSignUpPath()),
    location.pathname,
    getSignUpPath()
  );
  console.log(
    '🚀 ~ TFS ~ COndition 3 schoolForUserData:',
    !tfsComingSoonfeatureflag
  );

  console.log(
    'CONDITION 2.0',
    schoolForUserStatus !== ACTIONS.STATUS.RESOLVED &&
      toolsForSchoolsStatus !== ACTIONS.STATUS.RESOLVED &&
      location?.pathname.includes(getSignUpPath()) &&
      !tfsComingSoonfeatureflag
  );

  console.log(
    'FINAL CONDITION ',
    (schoolForUserStatus !== ACTIONS.STATUS.RESOLVED &&
      !!schoolForUserData?.schoolForUser) ||
      (schoolForUserStatus !== ACTIONS.STATUS.RESOLVED &&
        toolsForSchoolsStatus !== ACTIONS.STATUS.RESOLVED &&
        !location?.pathname.includes(getSignUpPath()) &&
        !tfsComingSoonfeatureflag)
  );

  if (
    (schoolForUserStatus !== ACTIONS.STATUS.RESOLVED &&
      !!schoolForUserData?.schoolForUser) ||
    (schoolForUserStatus !== ACTIONS.STATUS.RESOLVED &&
      toolsForSchoolsStatus !== ACTIONS.STATUS.RESOLVED &&
      !location?.pathname.includes(getSignUpPath()) &&
      !tfsComingSoonfeatureflag)
  ) {
    return (
      <div className="text-center spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <ToolsForSchoolsContext.Provider
      value={{
        toolsForSchoolsData,
        schoolForUserData,
        schoolsData,
        schoolsStatus,
        cardData: { data: cardData, status: cardStatus },
        schoolForUserStatus,
        fetchSchoolsData,
        getSchoolForUser,
        getToolsForSchools,
        validateCardNumberForUser,
        isSignUpFlow,
        featureFlag: ff,
      }}
      {...props}
    >
      {children}
    </ToolsForSchoolsContext.Provider>
  );
};

ToolsForSchoolsContextState.displayName = 'ToolsForSchoolsContextState';

export default ToolsForSchoolsContextState;
