import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Container,
  Spinner,
  Col,
  Card,
  Overlay,
  Tooltip,
} from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useParams, useNavigate } from 'react-router-dom';
import { AppContext } from '@rewards/contexts';

import BackNav from '../BackNav';

import { getLandingPath, getUserAddressPath } from '../../util/paths';
import { useKnives, useResponsive } from '../../util/hooks';
import Icon from '../../assets/svg';
import { KnivesContext } from '../../contexts/KnivesContext';
import { viewedEvent, locationEvent, EVENTS } from '../../analytics/events';

import './KnifeInfo.scss';
const KnifeInfo = () => {
  const { source, track } = useContext(AppContext.AppContext);
  const { getKnifeInfo, shippingCost } = useContext(KnivesContext);

  const params = useParams();
  const navigate = useNavigate();
  const { balancePoints, knifeInfo } = useKnives();
  const { isDesktop } = useResponsive();

  const [showTooltip, setTooltip] = useState(false);

  const target = useRef(null);

  useEffect(() => {
    KnifeInfo.landingPath = getLandingPath();
    KnifeInfo.showBackBtn = true;
  }, []);

  useEffect(() => {
    if (params) {
      const knife = getKnifeInfo(params?.id);
      if (!knife) {
        return navigate(getLandingPath());
      }
    }
  }, [params?.id]);

  const handleCancel = () => {
    track(EVENTS.SHIP_TO_HOME_CANCEL_CLICKED, {
      location: locationEvent(knifeInfo?.name),
      id: knifeInfo?._id,
      name: knifeInfo?.name,
      balance_points: balancePoints,
      cost: knifeInfo?.cost + shippingCost,
      ui_version: import.meta.env.APP_VERSION,
    });
    navigate(getLandingPath());
  };

  const handleNext = () => {
    track(EVENTS.SHIP_TO_HOME_NEXT_CLICKED, {
      location: locationEvent(knifeInfo?.name),
      id: knifeInfo?._id,
      name: knifeInfo?.name,
      balance_points: balancePoints,
      cost: knifeInfo?.cost + shippingCost,
      ui_version: import.meta.env.APP_VERSION,
    });
    if (balancePoints < knifeInfo?.cost + shippingCost) {
      const scrollTopEle = document.getElementById('scrollToPosition');
      scrollTopEle.scrollIntoView();
      setTooltip(true);
    } else {
      navigate(getUserAddressPath());
    }
  };

  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => {
        setTooltip(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showTooltip]);

  useEffect(() => {
    if (knifeInfo) {
      source(
        viewedEvent(knifeInfo?.name, {
          id: knifeInfo?._id,
          name: knifeInfo?.name,
          ui_version: import.meta.env.APP_VERSION,
        })
      );
      KnifeInfo.LOCATION = locationEvent(knifeInfo?.name);
    }
  }, [knifeInfo]);

  if (!knifeInfo) {
    return (
      <div className="text-center spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container className={`text-center my-3 py-3 ${isDesktop && 'px-5'}`}>
      <Col className="lc-knife-title">{knifeInfo?.productDetails?.name}</Col>
      <Card
        className={`border-0 ${
          isDesktop ? 'lc-knife-desk-card' : 'lc-knife-card '
        }`}
      >
        <Card.Img
          variant="top"
          src={knifeInfo?.image}
          className="lc-knife-card-img"
        />
        <Card.Body>
          <Card.Text className="lc-knife-info">
            {knifeInfo?.productDetails?.description}
          </Card.Text>
        </Card.Body>
        <Card.Footer
          className="py-3 border-0 lc-knife-card-footer flex flex-row justify-content-center rounded-2"
          id="scrollToPosition"
        >
          <Icon icon="balance-bolt" width="32" height="32" color="#ffffff" />
          <div className="lc-knife-cost my-auto ml-2" ref={target}>
            {knifeInfo?.cost + shippingCost}
          </div>
        </Card.Footer>
        <Overlay
          target={target.current}
          show={showTooltip}
          placement="top"
          delay={{ show: 250, hide: 400 }}
        >
          {(props) => (
            <Tooltip className="lc-knife-points-tooltip" {...props}>
              Your AdvantEdge Rewards points are less than the required total
              for this product.
            </Tooltip>
          )}
        </Overlay>
      </Card>
      {!isDesktop && <Col className="py-5"></Col>}
      <Col
        className={`flex flex-row justify-content-between ${
          isDesktop
            ? 'p-0 mt-5'
            : 'lc-knife-bottom-btns fixed-bottom bg-white p-3'
        }`}
      >
        <Col className="p-0 mr-1">
          <Button
            // className='lc-knife-cancel w-100 h-100 p-3'
            variant="default"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Col>
        <Col className="p-0 ml-1">
          <Button
            // className='lc-knife-next w-100 h-100 p-3'
            variant="default"
            onClick={handleNext}
          >
            Confirm
          </Button>
        </Col>
      </Col>
    </Container>
  );
};

export default BackNav(KnifeInfo);
