import React, { useEffect, useState } from 'react';
import { Route, Navigate, BrowserRouter, Routes } from 'react-router-dom';
import { AuthContext } from '@rewards/contexts';

import { CommunitySolarContextState } from './contexts/CommunitySolarContext';
import { getCommunitySolarPath } from './util/paths';
import { FEATURES_FLAGS } from './util/constants';
import ComingSoon from './components/ComingSoon';
import LandingPage from './components/LandingPage';

// import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';

const { useAuth } = AuthContext;

const App = () => {
  const { featureFlags } = useAuth();
  const [activateCommingSoonPage, setActivateComingSoonPage] = useState(false);

  useEffect(() => {
    if (
      featureFlags.find(
        (ff) =>
          ff.name === FEATURES_FLAGS.ENABLE_COMING_SOON_UTILITY_OFFERS &&
          !ff.isActive
      )
    ) {
      setActivateComingSoonPage(true);
    }
  }, [featureFlags]);

  return (
    <CommunitySolarContextState>
      {activateCommingSoonPage ? (
        <ComingSoon />
      ) : (
        <Routes>
          <Route path={getCommunitySolarPath()} component={LandingPage} exact />
          <Route
            path="*"
            render={() => <Navigate to={getCommunitySolarPath()} />}
          />
        </Routes>
      )}
    </CommunitySolarContextState>
  );
};

export default App;
