import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '@rewards/contexts';
import { Col, Card, Row } from 'react-bootstrap';
import { Button, ModuleLayout, BackNav } from '@rewards/ui';
import { useResponsive } from '../../util/hooks';

import './CampaignEnded.scss';
import { getLandingPath, getLandingRedeemPath } from '../../util/paths';
import { EVENTS } from '../../analytics/events';
import { useModule } from '../../util/hooks/useModule';
import { FEATURES_FLAGS } from '../../util/constants';

// @TODO: Migrate to it's own Route File
const CampaignEnded = ({}) => {
  const { isDesktop } = useResponsive();
  const navigate = useNavigate();
  const module = useModule(FEATURES_FLAGS.ENABLE_DAILY_SURPRISES);
  const isComingSoon = module?.otherDetails?.commingSoon;
  const { landingPath, track } = useContext(AppContext.AppContext);

  const goToLandingPage = (landingPage) => {
    if (landingPage === 'earn') {
      track(EVENTS.DAILY_SURPRISES_EARN_CLICKED, {
        ui_version: import.meta.env.APP_VERSION,
      });
      navigate(getLandingPath());
    } else {
      track(EVENTS.DAILY_SURPRISES_REDEEM_CLICKED, {
        ui_version: import.meta.env.APP_VERSION,
      });
      navigate(getLandingRedeemPath());
    }
  };

  useEffect(() => {
    CampaignEnded.landingPath = landingPath;
  }, [landingPath]);

  // useEffect(() => {
  //   navigate.listen(() => {
  //     if (navigate.action === 'POP') {
  //       navigate.replace(landingPath);
  //     }
  //   });
  // }, [navigate]);
  return (
    <BackNav module="Daily SurPRIZE &trade;">
      <ModuleLayout>
        <Card className="rounded-2 borderless ">
          {!isComingSoon && (
            <Col
              className={`text-center ${
                isDesktop
                  ? 'campaign-ended-header pt-2 mt-2 mb-4'
                  : 'campaign-ended-header-mobile pt-2 mt-2 mb-3'
              }`}
            >
              Our recent Daily SurPRIZE&trade; game has ended. Come back again
              soon for a new game for more chances to win great prizes!
            </Col>
          )}
          <Card.Body className="px-0 flex flex-row">
            <Col className="p-0 flex justify-center">
              <img
                className="w-1/2"
                src={`https://cdn.spinwheel.io/images/daily-surprises/${
                  !isComingSoon ? 'thanks' : 'coming_soon'
                }.png`}
                alt={`${
                  isComingSoon
                    ? 'Coming soon on February 26th'
                    : 'Our recent Daily SurPRIZE&trade; game has ended. Come back again soon for a new game for more chances to win great prizes!'
                }`}
              />
            </Col>
          </Card.Body>
          <Row
            className={`mx-5 pt-2 flex justify-center ${
              isDesktop ? 'mt-3' : 'mt-2'
            }`}
          >
            <Col
              className={`text-center campaign-ended-body-text ${
                isDesktop ? 'mt-4 mb-4' : ''
              }`}
              md={isDesktop ? 6 : 12}
            >
              Earn more points through gift card purchases, student loan
              refinancing, and more!
            </Col>
            {!isDesktop && (
              <Col
                className="text-center mt-4 mb-4 pb-2 campaign-ended-body-text"
                md={12}
              >
                <Button
                  onClick={() => goToLandingPage('earn')}
                  // className='campaign-ended-button'
                  width="w75"
                >
                  Earn
                </Button>
              </Col>
            )}
            <Col
              className={`text-center campaign-ended-body-text ${
                isDesktop ? 'mt-4 mb-4' : ''
              }`}
              md={isDesktop ? 6 : 12}
            >
              Check out great ways to redeem points like entering sweepstakes,
              paying down student loans, and more!
            </Col>
            {!isDesktop && (
              <Col
                className="text-center mt-4 mb-4 campaign-ended-body-text"
                md={12}
              >
                <Button
                  onClick={() => goToLandingPage('redeem')}
                  // className='campaign-ended-button'
                  width="w75"
                >
                  Redeem
                </Button>
              </Col>
            )}
          </Row>
          {isDesktop && (
            <Row className="mx-5">
              <Col
                className="px-0 flex justify-center mt-1 mb-4 campaign-ended-body-text"
                md={6}
              >
                <Button
                  onClick={() => goToLandingPage('earn')}
                  // className='campaign-ended-button'
                  width="w75"
                >
                  Earn
                </Button>
              </Col>
              <Col
                className="px-0 flex justify-center mt-1 mb-4 campaign-ended-body-text"
                md={6}
              >
                <Button
                  onClick={() => goToLandingPage('redeem')}
                  //  className='campaign-ended-button'
                  width="w75"
                >
                  Redeem
                </Button>
              </Col>
            </Row>
          )}
        </Card>
      </ModuleLayout>
    </BackNav>
  );
};

export default CampaignEnded;
