import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Modal,
  Card,
  Col,
  ButtonGroup,
  Overlay,
  Tooltip,
} from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useNavigate } from 'react-router-dom';
import { AppContext, AuthContext, ACTIONS } from '@rewards/contexts';
import { useUserInfo, useMoneyTransferTxns } from '@rewards/data-provider';

import ModalCloseBtn from '../../../ModalCloseButton';
import Icon from '../../../../assets/svg';

import { useResponsive, useKnives } from '../../../../util/hooks';
import { KNIVES } from '../../../../util/constants';
import { getAddressListPath, getLandingPath } from '../../../../util/paths';
import { KnivesContext } from '../../../../contexts/KnivesContext';
import { KNIFE_TXNS_STATUS, KNIFE_INFO } from '../../../../util/constants';
import { PAGES, EVENTS, EVENT_LOCATION } from '../../../../analytics/events';

import './KnifeConfirmModal.scss';

const { useAuth } = AuthContext;
const KnifeConfirmModal = ({
  isDisabled = false,
  shouldCallNext,
  handleNextAddress,
  handleSuccessModal,
}) => {
  const { checkValidToken } = useAuth();
  const { source, track } = useContext(AppContext.AppContext);
  const {
    updateToShippingAddressId,
    getKnives,
    toShippingAddressId,
    shippingCost,
  } = useContext(KnivesContext);

  const [show, setShow] = useState(false);
  const [isTxnsSuccess, setIsTxnsSuccess] = useState(false);
  const [showTxnErrorTooltip, setTxnErrorTooltip] = useState(false);
  const [txnErrorTooltipMessage, setTxnErrorTooltipMessage] = useState(
    ACTIONS.TXN_ERROR.INTERNAL
  );
  const target = useRef(null);

  const { isDesktop } = useResponsive();
  const { knifeInfo } = useKnives();
  const navigate = useNavigate();
  const { data: userInfo, isFetching: isUserInfoFetching } = useUserInfo();
  const {
    mutate: handleMoneyTransferTxns,
    data: knifeTxns,
    status: knifeTxnStatus,
  } = useMoneyTransferTxns();

  const transferMoneyTxns = async () => {
    const { _id, partnerAccounts } = userInfo?.data;

    const params = {
      fromAccount: {
        fromUserId: _id,
        fromAccountId: partnerAccounts[0]?.accountId,
        fromAccountValue: knifeInfo?.cost + shippingCost,
        fromCurrencyId: knifeInfo?.currencyId,
        fromAccountType: KNIVES.PARTNER,
      },
      toAccount: {
        toUserId: _id,
        subAccountId: knifeInfo?._id,
        toAccountValue: 1,
        toAccountType: KNIVES.SHIP_TO_HOME,
        toShippingAddressId,
      },
    };
    return checkValidToken().then(() => {
      return handleMoneyTransferTxns(params);
    });
  };

  const handleClose = () => {
    if (knifeTxnStatus === ACTIONS.QUERY_STATUS.LOADING) {
      return;
    }

    updateToShippingAddressId();
    navigate(getAddressListPath());
    setShow(false);
  };

  const handleCancel = () => {
    track(EVENTS.SHIP_TO_HOME_CANCEL_CLICKED, {
      location: EVENT_LOCATION.KNIFE_CONFIRMATION_MODAL,
      ui_version: import.meta.env.APP_VERSION,
    });
    handleClose();
  };

  const handleNext = () => {
    handleNextAddress?.();
    shouldCallNext && setShow(true);
  };

  const handleConfirm = () => {
    track(EVENTS.SHIP_TO_HOME_CONFIRM_CLICKED, {
      location: EVENT_LOCATION.KNIFE_CONFIRMATION_MODAL,
      id: knifeInfo?._id,
      cost: knifeInfo?.cost + shippingCost,
      name: knifeInfo?.name,
      ui_version: import.meta.env.APP_VERSION,
    });

    transferMoneyTxns().then(() => {
      setIsTxnsSuccess(true);
    });
  };

  useEffect(() => {
    if (knifeTxnStatus === ACTIONS.QUERY_STATUS.ERROR && !isUserInfoFetching) {
      const scrollTopEle = document.getElementById('scrollToPosition');
      scrollTopEle.scrollIntoView();
      userInfo?.data?.partnerAccounts?.[0]?.accountBalance <
        knifeInfo?.cost + shippingCost &&
        setTxnErrorTooltipMessage(ACTIONS.TXN_ERROR.POINTS);
      setTxnErrorTooltip(true);
    }
  }, [isUserInfoFetching, knifeTxnStatus]);

  useEffect(() => {
    if (shouldCallNext) {
      setShow(true);
    }
  }, [shouldCallNext]);

  useEffect(() => {
    if (show) {
      source(PAGES.VIEWED_KNIFE_CONFIRMATION_MODAL, {
        ui_version: import.meta.env.APP_VERSION,
      });
    }
  }, [show]);

  useEffect(() => {
    if (showTxnErrorTooltip) {
      const timer = setTimeout(() => {
        setTxnErrorTooltip(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showTxnErrorTooltip]);

  useEffect(() => {
    if (knifeTxnStatus === ACTIONS.QUERY_STATUS.SUCCESS && isTxnsSuccess) {
      if (knifeTxns?.data?.data?.txnStatus === KNIFE_TXNS_STATUS.COMPLETE) {
        handleSuccessModal();
        updateToShippingAddressId();
        setShow(false);
        localStorage.removeItem(KNIFE_INFO);
      } else if (
        knifeTxns?.data?.data?.txnStatus === KNIFE_TXNS_STATUS.OUT_OF_STOCK
      ) {
        getKnives();
        updateToShippingAddressId();
        localStorage.removeItem(KNIFE_INFO);
        navigate(getLandingPath());
      }
    }
  }, [knifeTxnStatus, isTxnsSuccess]);
  return (
    <>
      <Button
        // className='lc-knife-confirm-next p-3'
        block
        disabled={isDisabled}
        onClick={handleNext}
      >
        Next
      </Button>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered={true}
        className="ms-modal"
        dialogClassName={`rounded' ${
          isDesktop ? '' : 'ms-mobile-dialog mx-auto'
        }`}
        contentClassName="text-center"
      >
        <Modal.Header className="border-0 justify-content-center pt-5 lc-knife-confirm-header">
          Confirmation
          <ModalCloseBtn handleClose={handleClose} />
        </Modal.Header>
        <Modal.Body className="border-0 pt-5">
          <Card className="border-0">
            <Card.Header className="lc-knife-confirm-knife-info border-0 bg-white">
              Do you want to use {knifeInfo?.cost + shippingCost} Rewards points
              to purchase the {knifeInfo?.name}?
            </Card.Header>
            <Card.Img
              variant="top"
              src={knifeInfo?.image}
              alt={knifeInfo?.name}
            />
            <Card.Footer
              className="py-3 mb-3 border-0 lc-knife-card-footer flex flex-row justify-content-center rounded-2"
              id="scrollToPosition"
            >
              <Icon
                icon="balance-bolt"
                width="32"
                height="32"
                color="#ffffff"
              />
              <div className="lc-knife-cost my-auto ml-2" ref={target}>
                {knifeInfo?.cost + shippingCost}
              </div>
            </Card.Footer>
            <Overlay
              target={target.current}
              show={showTxnErrorTooltip}
              placement="top"
              delay={{ show: 250, hide: 400 }}
            >
              {(props) => (
                <Tooltip className="lc-knife-points-tooltip" {...props}>
                  {txnErrorTooltipMessage}
                </Tooltip>
              )}
            </Overlay>
          </Card>
          <Col className="lc-knife-confirm-info">
            By clicking submit, you agree to purchase a MasterChef knife using
            your Rewards points.
          </Col>
        </Modal.Body>
        <Modal.Footer className="border-0 bg-white">
          <ButtonGroup className="flex justify-content-between w-100">
            <Button
              // className='lc-knife-confirm-cancel mr-1 rounded w-50 py-3 border-0'
              onClick={handleCancel}
              disabled={
                knifeTxnStatus === ACTIONS.QUERY_STATUS.LOADING ||
                isUserInfoFetching
              }
            >
              Cancel
            </Button>

            <Button
              // className='lc-knife-confirm-btn w-50 ml-1 py-3 rounded border-0'
              onClick={handleConfirm}
              disabled={
                knifeTxnStatus === ACTIONS.QUERY_STATUS.LOADING ||
                isUserInfoFetching
              }
            >
              Confirm
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default KnifeConfirmModal;
