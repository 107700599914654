import React, { useEffect, useContext, useState } from 'react';
import {
  Container,
  Col,
  Card,
  Nav,
  Badge,
  Row,
  Spinner,
} from 'react-bootstrap';
import { Button } from '@rewards/ui';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { AppContext, AuthContext, ACTIONS } from '@rewards/contexts';

import BackNav from '../BackNav';

import Icon from '../../assets/svg';
import { useResponsive, useKnives } from '../../util/hooks';
import { KNIFE_INFO } from '../../util/constants';
import styleConsts from '../../util/styleConsts';
import { getKnifeInfoPath } from '../../util/paths';
import { dateString } from '../../util/formatters';
import {
  PAGES,
  EVENT_LOCATION,
  clickedEvent,
  EVENTS,
  findEvents,
} from '../../analytics/events';
import { KnivesContext } from '../../contexts/KnivesContext';

import './LandingPage.scss';

const { useAuth } = AuthContext;
const LandingPage = () => {
  const { landingPath, source, track } = useContext(AppContext.AppContext);
  const { updateKnifeInfo, shippingCost } = useContext(KnivesContext);

  const { instance } = useMsal();
  const { unAuthToken } = useAuth();
  const { isDesktop } = useResponsive();
  const { knives, balancePoints, purchasedTxns } = useKnives();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    source(PAGES.VIEWED_SHIP_TO_HOME, {
      ui_version: import.meta.env.APP_VERSION,
    });
    LandingPage.landingPath = landingPath;
    LandingPage.LOCATION = EVENT_LOCATION.SHIP_TO_HOME_LANDING_PAGE;
    LandingPage.showBackBtn = false;
  }, []);

  const handleCardClick = (knife) => {
    if (
      !knife?.numberOfProductsAvailable ||
      unAuthToken?.status === ACTIONS.STATUS.RESOLVED
    ) {
      return;
    }

    track(findEvents(clickedEvent(knife?.name)), {
      location: EVENT_LOCATION.SHIP_TO_HOME_LANDING_PAGE,
      id: knife?._id,
      name: knife?.name,
      cost: knife?.cost + shippingCost,
      ui_version: import.meta.env.APP_VERSION,
    });
    updateKnifeInfo(knife);
    localStorage.setItem(KNIFE_INFO, knife?._id);
    navigate(getKnifeInfoPath(knife._id));
  };

  const handleLoginRegister = () => {
    setIsLoading(true);
    track(EVENTS.LOG_IN_REGISTER_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });

    instance.config.auth.authority = instance.config.auth.loginAuthority;
    instance.loginRedirect({
      ...instance.config.auth.loginRequest,
      extraQueryParameters: {
        anonymousId: window?.analytics?.user?.()?.anonymousId?.(),
      },
    });
  };

  const FooterInfo = () => (
    <Col className="lcp-footer my-2 mx-auto text-center" lg={9}>
      Points applied include the product + shipping.
    </Col>
  );

  return (
    <Container className="my-3 text-center">
      <Col lg={7} className="mx-auto p-0">
        {unAuthToken?.status === ACTIONS.STATUS.RESOLVED ? (
          <Col className="lc-knives-un-auth-title">
            Link your AdvantEdge Card to start redeeming your points for
            MasterChef knives!
          </Col>
        ) : (
          <Col
            className={
              isDesktop ? 'lc-knives-deskp-title my-3' : 'lc-knives-title my-3'
            }
          >
            Redeem your Rewards points for MasterChef knives!
          </Col>
        )}
        <Card className="border-0 my-3">
          <Card.Img
            className="border-0"
            variant="top"
            src={`https://cdn.spinwheel.io/images/pricechopper/ship-to-home/products-banner-2022-${
              isDesktop ? 'desktop' : 'mobile'
            }.png`}
          />
        </Card>
        <Col className="lc-knives-info">Ship to your home through 12/24</Col>
        <Col className="lc-knives-sub-info">
          Limited time only, while supplies last!
        </Col>

        <Nav className={`mx-auto my-3 ${isDesktop && 'lc-knives-nav'}`}>
          <Row className="w-100 m-0">
            {Object.values(knives?.data)?.map((knife, index) => (
              <Col
                xs={12}
                md={12}
                sm={12}
                key={index}
                className={`postion-relative hover ${
                  !knife?.numberOfProductsAvailable
                    ? isDesktop
                      ? 'pt-4 py-1'
                      : 'lc-knives-badge-mobile'
                    : 'pt-4 py-1'
                }`}
              >
                {!knife?.numberOfProductsAvailable && (
                  <Badge className="lc-knives-sold-out-badge rounded">
                    Sold out online! Check your local store for stock.
                  </Badge>
                )}
                <Card
                  className={`h-100 border-0 text-left rounded-2 flex flex-row lc-knivescard-border ${
                    !knife.numberOfProductsAvailable
                      ? 'lc-knives-sold-out-card-background'
                      : ''
                  }`}
                  onClick={() => handleCardClick(knife)}
                >
                  <Card.Header
                    className={`border-0 my-auto rounded-2 w-25 bg-white ${
                      !isDesktop && 'p-1'
                    }`}
                  >
                    <Card.Img src={knife?.image} alt={knife.name} />
                  </Card.Header>
                  <Card.Body className="p-0 my-auto">
                    <Card.Title className="p-0 lc-knives-card-title">
                      {knife.name}
                    </Card.Title>
                    <Card.Text className="p-0 lc-knivescard-info">
                      {knife?.productDetails?.tag}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer
                    className={`border-0 my-auto rounded-2 pl-0 pr-3 w-25 bg-white `}
                  >
                    <Badge
                      pill
                      className={`lc-knivescard-badge flex-row float-right p-0 pr-2 ${
                        balancePoints >= knife.cost + shippingCost
                          ? 'active bg-white'
                          : 'bg-light'
                      }`}
                    >
                      <Icon
                        icon="masterchef"
                        width="40"
                        height="40"
                        // className='mr-2'
                        color={
                          balancePoints >= knife.cost + shippingCost
                            ? styleConsts.california
                            : styleConsts.lightGrey
                        }
                        stroke={
                          balancePoints >= knife.cost + shippingCost
                            ? styleConsts.white
                            : styleConsts.lightGrey2
                        }
                      />
                      {knife.cost + shippingCost}
                    </Badge>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </Nav>
        <Row>
          <Col md={12}>
            <FooterInfo />
          </Col>
        </Row>
        {!!purchasedTxns?.data?.length && (
          <>
            <Col className="lc-knives-puchased-info mb-2">
              Previous Purchases
            </Col>
            {purchasedTxns?.data?.map((purchasedData, index) => (
              <div className={`my-1 ${isDesktop && 'px-5 mx-n2 '}`} key={index}>
                <div className="lc-knives-purchased-knife flex flex-row justify-content-between">
                  <div className="lc-knife-purchased-name">
                    {purchasedData?.name}
                  </div>
                  <div className="lc-knife-purchased-date">
                    {dateString(purchasedData?.purchasedTime)}
                  </div>
                </div>
                <div className="lc-knife-purchased-num text-left">
                  Confirmation #{purchasedData?.confirmationNumber}
                </div>
              </div>
            ))}
          </>
        )}
      </Col>

      {unAuthToken?.status === ACTIONS.STATUS.RESOLVED && (
        <>
          {!isDesktop && <Col className="py-5"></Col>}
          <Col
            lg={7}
            className={
              isDesktop
                ? 'p-0 mt-5 mx-auto'
                : 'lc-add-bottom fixed-bottom bg-white p-3'
            }
          >
            <Button block onClick={handleLoginRegister}>
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="secondary"
                  className="align-middle mr-2"
                />
              )}
              Log In / Register
            </Button>
          </Col>
        </>
      )}
    </Container>
  );
};

export default BackNav(LandingPage);
