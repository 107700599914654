import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Overlay,
  Image,
} from 'react-bootstrap';
import { useUserInfo } from '@rewards/data-provider';
import { useNavigate } from 'react-router-dom';
import {
  AuthContext,
  DailySurpriseContext,
  AppContext,
} from '@rewards/contexts';
import { useMsal } from '@azure/msal-react';

import Icon from '../../assets/svg';
import { getProfilePath } from '../../util/paths';
import { useResponsive } from '../../util/hooks';

const { useAuth } = AuthContext;

import './BalancePoints.scss';
import styles from '../../util/styleConsts';
import { EVENTS } from '../../analytics/events';

const { useDailySurprise } = DailySurpriseContext;

export default function BalancePoints() {
  const { track } = useContext(AppContext.AppContext);
  const { data: userInfo, refetch } = useUserInfo() || {};
  const user = userInfo?.data;
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  const { isSSOEnabled, isMigrated } = useAuth();
  const { instance, accounts } = useMsal();
  const [userName, setUserName] = useState('');
  const { refreshBalancePoint } = useDailySurprise(
    DailySurpriseContext.DailySurpriseContext
  );
  const { envInfo } = useContext(AuthContext.AuthContext);

  const [balancePoints, setbalancePoints] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);

  const handleClickBalanceBolt = () => {
    track(EVENTS.LOYALTY_CONNECT_MY_REWARDS_PROFILE_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    navigate(getProfilePath());
  };

  useEffect(() => {
    if (refreshBalancePoint?.status) {
      refetch();
      setShowTooltip(true);
      let tooltipTimer = setTimeout(() => setShowTooltip(false), 5 * 1000);

      return () => {
        clearTimeout(tooltipTimer);
      };
    }
  }, [refreshBalancePoint]);

  useEffect(() => {
    if (user) {
      setbalancePoints(user?.partnerAccounts?.[0]?.accountBalance);
    }
  }, [user]);

  const handleDropDown = () => {
    track(EVENTS.LOYALTY_CONNECT_DROP_DOWN_MENU_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
  };

  const handleProfile = () => {
    track(EVENTS.LOYALTY_CONNECT_MY_REWARDS_PROFILE_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    navigate('/profile');
  };

  const handleShopOnline = () => {
    track(EVENTS.LOYALTY_CONNECT_MY_REWARDS_SHOP_ONLINE_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    window.open('https://shop.pricechopper.com/', '_blank');
  };

  const handleLogout = () => {
    track(EVENTS.LOG_OUT_CLICKED, {
      ui_version: import.meta.env.APP_VERSION,
    });
    const index = accounts.findIndex(
      ({ idTokenClaims }) =>
        idTokenClaims?.acr === envInfo?.policies?.signUpPolicy
    );
    instance.logoutRedirect({
      account: accounts?.[index],
    });
  };

  useEffect(() => {
    if (isSSOEnabled && accounts?.length) {
      const currentAccount = accounts?.[0];
      const {
        idTokenClaims: { given_name, family_name },
      } = currentAccount;
      if (isMigrated) {
        setUserName(user?.partnerAccounts?.[0]?.profile?.name);
      } else {
        setUserName(`${given_name}`);
      }
    }
  }, [accounts, isSSOEnabled]);

  const renderBalance = () => {
    return (
      <>
        {/* <span className='balance font-weight-normal'>{(isDesktop) ? "" : userName}</span> */}
        <span className="balance-bolt" onClick={handleClickBalanceBolt}>
          <Icon
            className="inline"
            icon="balance-bolt"
            width="33"
            height="33"
            color={isDesktop ? styles.green : styles.white}
          />
        </span>
        <span
          className={`${
            isDesktop ? 'text-green-apple' : 'text-white'
          } balance-points`}
          ref={target}
        >
          {balancePoints}
        </span>
        <Overlay show={showTooltip} placement="bottom" target={target.current}>
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              style={{
                ...props.style,
              }}
              className="tooltip-custom"
            >
              <span className="tooltiptext">
                {refreshBalancePoint?.pointsEarned} points have been added to
                your account!
              </span>
            </div>
          )}
        </Overlay>
      </>
    );
  };

  return (
    <div className="ml-auto text-right float-right text-white font-weight-normal">
      <Row>
        <Col>
          {isDesktop ? (
            renderBalance()
          ) : (
            <DropdownButton
              menuAlign="right"
              title={renderBalance()}
              variant="default"
              className="balance-points"
              onClick={handleDropDown}
            >
              <Dropdown.Item
                className="l-desk-logout-item"
                onClick={handleProfile}
              >
                <Icon icon="user-outline" width="39" height="34" />
                <span className="pl-4 ml-2">My Profile</span>
              </Dropdown.Item>
              <Dropdown.Item
                className="l-desk-logout-item"
                onClick={handleShopOnline}
              >
                <Icon icon="shop profile" width="39" height="34" />
                <span className="pl-4 ml-2">Shop Online</span>
              </Dropdown.Item>
              <Dropdown.Item
                className="l-desk-logout-item"
                onClick={handleLogout}
              >
                <Icon icon="logout" width="39" height="34" />
                <span className="pl-4 ml-2">Logout</span>
              </Dropdown.Item>
            </DropdownButton>
          )}
        </Col>
      </Row>
    </div>
  );
}
