import React, { useEffect, useContext, useState } from 'react';
import { Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { ACTIONS } from '@rewards/contexts';

import { getLandingPath } from '../../util/paths';
import { useLoyaltyConnect } from '../../util/hooks';
import { LoyaltyConnectContext } from '../../contexts/LoyaltyConnectContext';

const SignUp = () => {
  const { getUserInfoByCardNumber, getUserInfoByEmail } = useContext(
    LoyaltyConnectContext
  );

  const { instance } = useMsal();
  const navigate = useNavigate();
  const { userInfoByCardNumber, userInfoByEmail } = useLoyaltyConnect();

  const [cardNumber, setCardNumber] = useState();
  const [email, setEmail] = useState();
  const [show, setShow] = useState(false);
  const [alertStatus, setAlertStatus] = useState('');

  useEffect(() => {
    if (instance?.getAllAccounts()?.length) {
      navigate(getLandingPath());
    } else {
      const searchParams = new URLSearchParams(location.search);
      const cardNumber = searchParams.get('cardNumber');
      const email = searchParams.get('email');
      if (cardNumber) {
        setCardNumber(cardNumber);
      }
      if (email) {
        setEmail(email);
      }
      if (!cardNumber?.length && !email?.length) {
        setAlertStatus('No user info provided');
        setShow(true);
      }
    }
  }, [instance?.getAllAccounts(), navigate]);

  useEffect(() => {
    if (cardNumber) {
      getUserInfoByCardNumber({ cardNumber });
    }
  }, [cardNumber]);

  useEffect(() => {
    if (email && !cardNumber) {
      getUserInfoByEmail({ email });
    }
  }, [email, cardNumber]);

  useEffect(() => {
    if (
      userInfoByCardNumber?.status === ACTIONS.STATUS.RESOLVED ||
      userInfoByEmail?.status === ACTIONS.STATUS.RESOLVED
    ) {
      const {
        data: { results },
      } =
        userInfoByCardNumber?.status === ACTIONS.STATUS.RESOLVED
          ? userInfoByCardNumber
          : userInfoByEmail;
      if (!!results?.length) {
        const {
          firstName: givenName,
          lastName: surname,
          loyaltyCards,
        } = results?.[0];
        const extension_AdvantEdgeCard = loyaltyCards?.[0]?.cardNumber ?? '';
        const emailAddress = results?.[0]?.emailAddress ?? '';
        instance.config.auth.authority = instance.config.auth.signUpAuthority;
        if (cardNumber !== extension_AdvantEdgeCard || email !== emailAddress) {
          setAlertStatus('EmailId & Cardnumber combination does not match.');
          setShow(true);
        } else {
          instance.loginRedirect({
            ...instance.config.auth.loginRequest,
            extraQueryParameters: {
              email: emailAddress,
              ...(extension_AdvantEdgeCard && { extension_AdvantEdgeCard }),
              givenName,
              surname,
              anonymousId: window?.analytics?.user?.()?.anonymousId?.(),
            },
          });
          navigate(getLandingPath());
        }
      } else {
        setAlertStatus(
          userInfoByCardNumber?.data?.returnDescription ||
            userInfoByEmail?.data?.returnDescription
        );
        setShow(true);
      }
    }
  }, [userInfoByCardNumber, userInfoByEmail]);

  const handleClose = () => {
    setShow(false);
    navigate(getLandingPath());
  };

  if (
    !!instance?.getAllAccounts()?.length ||
    userInfoByCardNumber?.status === ACTIONS.STATUS.PENDING
  )
    return (
      <div id="spinner-icon">
        <div className="text-center spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  return (
    <Alert variant="danger" onClose={handleClose} dismissible show={show}>
      {alertStatus}
    </Alert>
  );
};

export default SignUp;
