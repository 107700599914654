import { Route, Routes } from 'react-router-dom';
import { formatPath } from '../util/formatters';
import { useLoyaltyConnect } from '../util/hooks';
import { getProfilePath, getSignUpPath } from '../util/paths';
import { Layout } from '../layouts/Layout';
import LandingPage from '../components/LandingPage';
import SignUp from '../components/SignUp';
import UserProfile from '../components/UserProfile';
import { SweepstakeApp } from '@rewards/sweepstakes';
import { ToolsForSchoolsModule } from '@rewards/tools-for-schools';
import { EMagazineApp } from '@rewards/emagazines';
import { CharityApp } from '@rewards/charity';
import { ShopInStoreModule } from '@rewards/shop-in-store';
import { SurveyModule } from '@rewards/survey';
import { UtilityOffersModule } from '@rewards/utility-offers';
import { ProductsModule } from '@rewards/products';
import { DailySurpriseModule } from '@rewards/daily-surprises';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<LandingPage />} />
        <Route path="earn/*">
          <Route index element={<LandingPage earn />} />
          <Route path="question-of-the-month/*" element={<SurveyModule />} />
          <Route path="utility-offers" element={<UtilityOffersModule />} />
          <Route path="shop-in-store/*" element={<ShopInStoreModule />} />
          <Route path="daily-surprise/*" element={<DailySurpriseModule />} />
        </Route>
        <Route path={getSignUpPath()} exact element={<SignUp />} />
        <Route path={getProfilePath()} exact element={<UserProfile />} />
        {/* Redeem Routes /> */}
        <Route path="enter-sweepstakes/*" element={<SweepstakeApp />} />
        <Route path="tools-for-schools/*" element={<ToolsForSchoolsModule />} />
        <Route path="magazines/*" element={<EMagazineApp />} />
        <Route path="donate/*" element={<CharityApp />} />
        <Route path="in-store-products" element={<ProductsModule />} />
        {/* <Route path="tools-for-schools/*" /> */}
        {/* <Route path="tools-for-schools/*" /> */}

        {/* Earn Routes */}

        <Route path="*" render={() => <Navigate to={''} />} />
      </Route>
    </Routes>
  );
};
